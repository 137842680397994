<div
    [class]="'qrCodePopover bfPopover bfPopover--' + options.placement"
    [ngClass]="{ 'commentsPopover--mobile': isMobile }"
    [ngStyle]="{ left: position.left + 'px', top: (position.top + 3) + 'px' }">
    <div class="bfPopover__arrow"></div>
    <div class="bfPopover__content">
        <div class="qrCodePopover__code" [innerHTML]="svgTag"></div>
        <div class="qrCodePopover__text">Scan this to preview on your mobile device.</div>
    </div>
</div>
