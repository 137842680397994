<div
    class="bfTagInput"
    #tagInput
    [ngClass]="{ 'bfTagInput--disabled' : isDisabled , 'bfTagInput--focus' : isFocused}">
    <div
        class="bfTagInput__tag"
        [ngClass]="{'bfTagInput__tag--erasing': isErasing && last}"
        *ngFor="let tag of tags; let last = last; let i = index;">
        <span class="tagInput__tagText" *ngIf="tag.length <= 42">{{ tag }}</span>
        <span class="bfTagInput__tagText" *ngIf="tag.length > 42"
            >{{ tag.substring(0, 42) }}...</span
        >
        <i class="bf-icon bf-icon-close bfTagInput__tagRemove" (click)="removeTag(i)"></i>
    </div>

    <input
        class="bfTagInput__input"
        #tagInputTextBox
        spellcheck="false"
        type="text"
        [placeholder]="config.placeholder || 'Add a tag...'"
        [disabled]="isDisabled"
        (input)="isErasing = false"
        (keyup.enter)="addTag(tagInputTextBox.value);"
        (keydown.backspace)="erase(tagInputTextBox.value)" />
</div>
