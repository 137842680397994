<div
    #translationSidebar
    class="translationSidebar"
    *ngIf="textService.currentTranslation">
    <div class="translationSidebar__header">
        <ui-flag size="tiny" [culture]="textService.currentTranslation.culture"></ui-flag>
        <span bfTooltip="{{ textService.currentTranslation.name }}"
            >{{ textService.currentTranslation.name | ellipsisMiddle: 20}}</span
        >
    </div>
    <div
        class="translationSidebar__noItems"
        *ngIf="(texts && !texts.length) && (images && !images.length)">
        No translatable texts or images.
    </div>
    <div
        class="translationSidebar__panel"
        [ngClass]="{'translationSidebar__panel--collapsed' : textsCollapsed }"
        *ngIf="texts && texts.length">
        <h3 class="translationSidebar__panelHeader" (click)="toggleTextsCollapse()">
            <i class="bf-icon bf-icon-caret-down translationSidebar__panelCaret"></i>
            <span class="translationSidebar__panelName">Texts</span>
        </h3>
        <div class="translationSidebar__panelItems">
            <translationTextInput
                #translationTextInput
                [translation]="text"
                [isOriginal]="landingPage.originalTranslation === textService.currentTranslation"
                [index]="i+1"
                *ngFor="let text of texts; let i = index" />
        </div>
    </div>

    <div
        class="translationSidebar__panel"
        [ngClass]="{'translationSidebar__panel--collapsed' : imagesCollapsed }"
        *ngIf="images && images.length">
        <h3 class="translationSidebar__panelHeader" (click)="toggleImagesCollapse()">
            <i class="bf-icon bf-icon-caret-down translationSidebar__panelCaret"></i>
            <span class="translationSidebar__panelName">Images</span>
        </h3>
        <div class="translationSidebar__panelItems">
            <translationImageInput
                #translationImageInput
                [translation]="image"
                [index]="i+1"
                *ngFor="let image of images; let i = index" />
        </div>
    </div>
</div>
