import { EventEmitter, NgZone } from '@angular/core';
import { ColorService } from 'shared/components/colorPicker/color.service';

// TODO fix
var Scribe = require('../../../libs/scribe/scribe.js');
var scribePluginLinkPromptCommand = require('../../../libs/scribe/plugins/extensions/scribePluginLinkPromptCommand/main.js');
var scribePluginIntelligentUnlinkCommand = require('../../../libs/scribe/plugins/extensions/scribePluginIntelligentUnlinkCommand/main.js');

// import * as Scribe from 'scribe';
// import * as scribePluginLinkPromptCommand from 'scribePluginLinkPromptCommand';
// import * as scribePluginIntelligentUnlinkCommand from 'scribePluginIntelligentUnlinkCommand';

// import * as scribePluginLinkPromptCommand from 'scribePluginLinkPromptCommand';
// import * as scribePluginIntelligentUnlinkCommand from 'scribePluginIntelligentUnlinkCommand';

// TODO remove

//declare var System: any;

//System.import('./../../../libs/scribe/scribe.js')
//    .then(MyModule => console.log(MyModule.value));

/*var Scribe = undefined;
var scribePluginLinkPromptCommand = undefined;
var scribePluginIntelligentUnlinkCommand = undefined;
*/

export const ScribeCommand: any = {
	Bold: 'bold',
	Italic: 'italic',
	Underline: 'underline',
	StrikeThrough: 'strikeThrough',
	AlignLeft: 'justifyLeft',
	AlignCenter: 'justifyCenter',
	AlignRight: 'justifyRight',
	AlignJustify: 'justifyFull',
	Color: 'foreColor',
	BackgroundColor: 'hiliteColor',
	CleanUp: 'cleanup',
	RemoveFormat: 'removeFormat',
	Link: 'linkPrompt',
	CreateLink: 'linkPrompt',
	Unlink: 'unlink',
	OrderedList: 'insertOrderedList',
	List: 'insertUnorderedList',
	Indent: 'indent',
	Outdent: 'outdent',
	Blockquote: 'blockquote',
	Superscript: 'superscript',
	Subscript: 'subscript',
	Code: 'code',
	Undo: 'undo',
	Redo: 'redo',
	Cleanup: 'cleanup',
};

export class ScribeWrapper {
	private colorService: ColorService;
	//private ngZone: NgZone;
	private scribeInstance: any;
	private onTextChange: any;
	private onMouseDown: any;
	private onBlur: any;
	private onFocus: any;
	private onClick: any;
	private onSelect: any;
	//public element:HTMLElement;
	public change: EventEmitter<string> = new EventEmitter<string>();
	public focus: EventEmitter<ScribeWrapper> =
		new EventEmitter<ScribeWrapper>();
	public blur: EventEmitter<ScribeWrapper> =
		new EventEmitter<ScribeWrapper>();
	public mouseDown: EventEmitter<ScribeWrapper> =
		new EventEmitter<ScribeWrapper>();
	public selectionChange: EventEmitter<ScribeWrapper> =
		new EventEmitter<ScribeWrapper>();

	//States
	public isBlockElement: boolean = false;
	public hasFocus: boolean = false;
	public bold: boolean = false;
	public italic: boolean = false;
	public underline: boolean = false;
	public strikeThrough: boolean = false;
	public superscript: boolean = false;
	public subscript: boolean = false;
	public color: string = '#000';
	public alignLeft: Boolean = false;
	public alignCenter: Boolean = false;
	public alignRight: Boolean = false;
	public alignJustify: Boolean = false;
	public link: boolean = false;
	public unlink: boolean = false;
	public orderedList: boolean = false;
	public list: boolean = false;
	public indent: boolean = false;
	public outdent: boolean = false;
	public blockquote: boolean = false;
	public code: boolean = false;

	//public key:string; //Used to store key if needed

	//Keyboad shortcuts map
	private commandsToKeyboardShortcutsMap: any = {
		bold: (event) => {
			return event.metaKey && event.keyCode === 66;
		}, // b
		italic: (event) => {
			return event.metaKey && event.keyCode === 73;
		}, // i
		strikeThrough: (event) => {
			return event.altKey && event.shiftKey && event.keyCode === 83;
		}, // s
		removeFormat: (event) => {
			return event.altKey && event.shiftKey && event.keyCode === 65;
		}, // a
		linkPrompt: (event) => {
			return event.metaKey && !event.shiftKey && event.keyCode === 75;
		}, // k
		unlink: (event) => {
			return event.metaKey && event.shiftKey && event.keyCode === 75;
		}, // k,
		insertUnorderedList: (event) => {
			return event.altKey && event.shiftKey && event.keyCode === 66;
		}, // b
		insertOrderedList: (event) => {
			return event.altKey && event.shiftKey && event.keyCode === 78;
		}, // n
		blockquote: (event) => {
			return event.altKey && event.shiftKey && event.keyCode === 87;
		}, // w
		code: (event) => {
			return event.metaKey && event.shiftKey && event.keyCode === 76;
		}, // l
		h2: (event) => {
			return this.ctrlKey(event) && event.keyCode === 50;
		}, // 2
	};

	constructor(
		private ngZone: NgZone,
		public textInput: HTMLDivElement,
		private mouseDownToEnable = false,
		public allowBlockElements = false,
		private content?: string,
	) {
		this.colorService = new ColorService();
		this.scribeInstance = new Scribe(textInput, {
			allowBlockElements: this.allowBlockElements,
		});

		if (this.mouseDownToEnable) {
			this.disable();
		}

		//Fill with content
		if (content) {
			this.setContent(content, true);
		}

		this.scribeInstance.use(scribePluginLinkPromptCommand());
		this.scribeInstance.use(scribePluginIntelligentUnlinkCommand());

		this.textInput.addEventListener(
			'focus',
			(this.onFocus = () => {
				this.ngZone.run(() => {
					this.hasFocus = true;
					this.focus.emit(this);
				});
			}),
		);

		this.textInput.addEventListener(
			'blur',
			(this.onBlur = (event: Event) => {
				if (
					(event.target as HTMLElement).className ===
					'translationTextInput__htmlButton'
				) {
					event.preventDefault();
					return;
				}
				this.ngZone.run(() => {
					this.hasFocus = false;
					this.blur.emit(this);
				});
			}),
		);

		this.textInput.addEventListener(
			'click',
			(this.onClick = (event: MouseEvent) => {
				const target = event.target as HTMLElement;
				const boundingRect = target.getBoundingClientRect();

				// The number indicator is 8px inward
				if (
					event.clientX < boundingRect.left + 8 &&
					event.clientY < boundingRect.top + 8
				) {
					this.textInput.focus();
				}
			}),
		);

		this.textInput.addEventListener(
			'mousedown',
			(this.onMouseDown = () => {
				this.ngZone.run(() => {
					if (this.mouseDownToEnable) {
						this.enable();
						this.textInput.focus();
					}
					this.mouseDown.emit(this);
				});
			}),
		);

		//Selection change
		this.onSelect = (event) => {
			this.ngZone.run(() => {
				this.updateStates();
				this.selectionChange.emit(this);
			});
		};

		this.textInput.addEventListener('mouseup', this.onSelect);

		this.textInput.addEventListener('keyup', this.onSelect);

		this.scribeInstance.on(
			'content-changed',
			(this.onTextChange = () => {
				this.ngZone.run(() => {
					this.change.emit(this.getText());
				});
			}),
		);
	}

	public setContent(value: string, preventEvent: boolean = false) {
		//Only update if needed to avoid loss of focus, duplicate events etc
		if (value !== this.getContent()) {
			this.scribeInstance.setHTML(value, preventEvent);
		}
	}

	public getContent(): string {
		return this.scribeInstance.getHTML();
	}

	public getText(): string {
		return this.scribeInstance.getTextContent();
	}

	public toggleCommand(commandName: string, value?: any) {
		this.textInput.focus();

		let command = this.scribeInstance.getCommand(commandName);

		if (command) {
			command.execute(value);
		}
		this.updateStates();
	}

	public isActive(commandName: string): any {
		let command = this.scribeInstance.getCommand(commandName);

		if (command) {
			return command.queryState();
		}

		return false;
	}

	public getSelectedLink(): any {
		return new this.scribeInstance.api.Selection().getContaining(
			function (node) {
				return node.nodeName === 'A';
			},
		);
	}

	public queryValue(commandName: string): any {
		let command = this.scribeInstance.getCommand(commandName);

		if (command) {
			return command.queryValue();
		}

		return undefined;
	}

	public enable() {
		this.textInput.contentEditable = 'true';
	}
	public disable() {
		this.textInput.contentEditable = 'false';
	}

	private updateStates() {
		this.bold = this.isActive(ScribeCommand.Bold);
		this.italic = this.isActive(ScribeCommand.Italic);
		this.underline = this.isActive(ScribeCommand.Underline);
		this.strikeThrough = this.isActive(ScribeCommand.StrikeThrough);
		this.superscript = this.isActive(ScribeCommand.Superscript);
		this.subscript = this.isActive(ScribeCommand.Subscript);
		this.color = this.queryValue(ScribeCommand.Color);
		this.alignLeft = this.isActive(ScribeCommand.AlignLeft);
		this.alignCenter = this.isActive(ScribeCommand.AlignCenter);
		this.alignRight = this.isActive(ScribeCommand.AlignRight);
		this.alignJustify = this.isActive(ScribeCommand.AlignJustify);
		this.link = this.isActive(ScribeCommand.Link);
		this.unlink = this.isActive(ScribeCommand.UnLink);
		this.orderedList = this.isActive(ScribeCommand.OrderedList);
		this.list = this.isActive(ScribeCommand.List);
		this.indent = this.isActive(ScribeCommand.Indent);
		this.outdent = this.isActive(ScribeCommand.Outdent);
		this.blockquote = this.isActive(ScribeCommand.Blockquote);
		this.code = this.isActive(ScribeCommand.Code);
	}

	private ctrlKey(event: KeyboardEvent): boolean {
		return event.metaKey || event.ctrlKey;
	}

	//Clear selected text
	public clearSelection() {
		if (this.getDocument().selection) {
			this.getDocument().selection.empty();
		} else if (window.getSelection) {
			this.getWindow().getSelection().removeAllRanges();
		}
	}

	public clearFormat() {
		this.setContent(this.getText());
	}

	public getCalculatedStyle(propName) {
		let window = this.getWindow();
		let document = this.getDocument();
		let el = this.scribeInstance.el;
		return el.currentStyle
			? el.currentStyle[propName]
			: window.getComputedStyle
				? window.getComputedStyle(el, null).getPropertyValue(propName)
				: null;
	}

	//Get window scribe is contained within
	public getWindow(): any {
		let doc = this.getDocument();
		return doc.defaultView || doc.parentWindow || window;
	}

	//Get closest document
	public getDocument(): any {
		return this.scribeInstance.el.ownerDocument || document;
		/* (working?)
        var rootDoc = this.scribeInstance.el.ownerDocument;
        var nodeHelpers = this.scribeInstance.node;

        // find the parent document or document fragment
        if( rootDoc.compareDocumentPosition(this.scribeInstance.el) & Node.DOCUMENT_POSITION_DISCONNECTED ) {
            var currentElement = this.scribeInstance.el.parentNode;
            while(currentElement && nodeHelpers.isFragment(currentElement)) {
                currentElement = currentElement.parentNode;
            }

            // if we found a document fragment and it has a getSelection method, set it to the root doc
            if (currentElement && currentElement.getSelection) {
                rootDoc = currentElement;
            }
        }*/
	}

	public destroy() {
		if (this.textInput) {
			if (this.onSelect) {
				this.textInput.removeEventListener('mouseup', this.onSelect);
				this.textInput.removeEventListener('keyup', this.onSelect);
			}
			if (this.onFocus)
				this.textInput.removeEventListener('focus', this.onFocus);
			if (this.onBlur)
				this.textInput.removeEventListener('blur', this.onBlur);
			if (this.onMouseDown)
				this.textInput.removeEventListener(
					'mousedown',
					this.onMouseDown,
				);
			if (this.onClick)
				this.textInput.removeEventListener('click', this.onClick);
		}
		this.scribeInstance.off('content-changed', this.onTextChange);
		this.scribeInstance.destroy();
	}
}
