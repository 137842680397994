import { Injectable } from '@angular/core';
import {
	IUIDialogConfig,
	UIConfirmDialogConfig,
	UIConfirmDialogResult,
	UIConfirmDialogService,
	UIDialogRef,
	UIDialogService,
} from '@bannerflow/ui';

import { PromptDialogComponent } from '../components/prompt-dialog/prompt-dialog.component';

export enum ModalType {
	clearTranslatableTextConfig = 'clearTranslatableTextConfig',
	cancelFileTranslationConfig = 'cancelFileTranslationConfig',
	deleteFileConfig = 'deleteFileConfig',
	completeTranslationConfig = 'completeTranslationConfig',
	cancelTranslation = 'cancelTranslationConfig',
	resendTranslation = 'resendTranslationConfig',
	deleteTranslation = 'deleteTranslationConfig',
	addTranslation = 'addTranslationConfig',
	makeTranslationOriginal = 'makeTranslationOriginalConfig',
}
@Injectable({
	providedIn: 'root',
})
export class LPBConfirmDialogService {
	private clearTranslatableTextConfig: UIConfirmDialogConfig = {
		headerText: 'Clear translatable text',
		text: 'If you clear this text from being translatable, you will loose all translations of it.',
		confirmText: 'CLEAR',
		discardText: 'CANCEL',
		theme: 'default',
		width: '600px',
	};

	private cancelFileTranslationConfig: UIConfirmDialogConfig = {
		headerText: 'Cancel translation of this file',
		text: 'By performing this action all translations of this file will be lost!',
		confirmText: 'YES',
		discardText: 'NO',
		theme: 'default',
		width: '600px',
	};

	private deleteFileConfig: UIConfirmDialogConfig = {
		headerText: 'Delete file',
		text: 'Are you sure you want to delete file. Please note, that you need to save to make the changes take effect.',
		confirmText: 'YES',
		discardText: 'NO',
		theme: 'default',
		width: '600px',
	};

	private completeTranslationConfig: UIConfirmDialogConfig = {
		headerText: 'Complete translation',
		text: "Happy with your work? Confirm it's complete, and then the landing page can launch internationally! Nice job.",
		confirmText: 'DELETE',
		discardText: 'CANCEL',
		theme: 'default',
		width: '600px',
	};

	private cancelTranslationConfig: UIConfirmDialogConfig = {
		headerText: 'Cancel translation',
		text: 'Do you want to cancel this translation request?',
		confirmText: 'YES',
		discardText: 'NO',
		theme: 'default',
		width: '600px',
	};

	private resendTranslationConfig: UIConfirmDialogConfig = {
		headerText: 'Send translation request',
		text: 'Do you want to send this translation request to your translator again?',
		confirmText: 'YES',
		discardText: 'NO',
		theme: 'default',
		width: '600px',
	};

	private deleteTranslationConfig: UIConfirmDialogConfig = {
		headerText: 'Delete translation',
		text: 'Are you sure you want to delete this translation?',
		confirmText: 'DELETE',
		discardText: 'NO',
		theme: 'default',
		width: '600px',
	};

	private addTranslationConfig: UIConfirmDialogConfig = {
		headerText: 'Add new translator',
		text: 'Do you want to add a new translator for this version?',
		confirmText: 'YES',
		discardText: 'NO',
		theme: 'default',
		width: '600px',
	};

	private makeTranslationOriginalConfig: UIConfirmDialogConfig = {
		headerText: 'Make this Translation Original?',
		text: '`Are you sure you want to make this the original version? The original version will act as the default version and is the reference for all other versions.',
		confirmText: 'YES',
		discardText: 'NO',
		theme: 'default',
		width: '600px',
	};

	constructor(
		private confirmDialogService: UIConfirmDialogService,
		private dialogService: UIDialogService,
	) {}

	public async showDialog(prompt: ModalType): Promise<UIConfirmDialogResult> {
		return this.confirmDialogService.confirm(this[prompt]);
	}

	public async showDialogWithMessages(
		messages: string[],
		prompt: ModalType,
	): Promise<UIConfirmDialogResult> {
		const staticConfig: IUIDialogConfig = this[prompt];

		const configWithMessages: IUIDialogConfig = {
			...staticConfig,
			data: { messages },
		};

		// Create & open a new dialog
		const dialogRef: UIDialogRef = this.dialogService.openComponent(
			PromptDialogComponent,
			configWithMessages,
		);

		// Wait for view to initialize
		await dialogRef.afterViewInit;

		// Wait for user to confirm or not
		const confirm: Promise<UIConfirmDialogResult> =
			await dialogRef.subComponentRef.instance.initiate();

		// Close after confirmation
		dialogRef.close();

		// Return the result
		return confirm;
	}
}
