<ng-container>
    <div class="centered-text">{{ messages?.[0] || config?.text || '' }}</div>

    <ui-dialog-buttons>
        <ui-button
            *ngIf="!!config?.discardText"
            (click)="cancel()"
            [text]="config?.discardText || 'cancel'">
        </ui-button>

        <ui-button
            (click)="confirm()"
            type="primary"
            [text]="config?.confirmText || 'ok'">
        </ui-button>
    </ui-dialog-buttons>
</ng-container>
