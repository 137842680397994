<div
    class="textPopOver bfPopover bfPopover--{{ options.placement }}"
    [ngStyle]="{ left: position.left + 'px', top: position.top + 'px' }">
    <div class="textPopOver__content">
        <div class="textPopOver__nameInput">
            <ui-flag
                size="tiny"
                [culture]="originalTranslation.culture"
                [bfTooltip]="originalTranslation.name + ' | Original language'"></ui-flag
            >&nbsp;
            <span
                [(bfInlineEdit)]="textName"
                bfInlineEditPlaceholder="untitled text"></span>
        </div>
        <textarea
            #textarea
            class="textPopOver__input"
            spellcheck="false"
            autocomplete="false"
            [(ngModel)]="textValue"
            (keydown.enter)="apply($event)"
            (keydown.escape)="close()"></textarea>
        <div class="textPopOver__clear" (click)="clearTranslation($event)">
            <i class="bf-icon bf-icon-remove"></i>
            Clear
        </div>
        <div class="textPopOver__helptext">Press enter to set text</div>
    </div>
</div>
