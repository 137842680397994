import { Injectable, ElementRef } from '@angular/core';

export class BFPositionService {
	private get window(): any {
		return window;
	}

	private get document(): any {
		return window.document;
	}

	private getStyle(nativeEl: any, cssProp: string): any {
		// IE
		if (nativeEl.currentStyle) {
			return nativeEl.currentStyle[cssProp];
		}

		if (this.window.getComputedStyle) {
			return this.window.getComputedStyle(nativeEl)[cssProp];
		}
		// finally try and get inline style
		return nativeEl.style[cssProp];
	}

	/**
	 * Checks if a given element is statically positioned
	 * @param nativeEl - raw DOM element
	 */
	private isStaticPositioned(nativeEl: any): any {
		return (this.getStyle(nativeEl, 'position') || 'static') === 'static';
	}

	/**
	 * Checks if a given element is fixed or absolute positioned
	 * @param nativeEl - raw DOM element
	 */
	private isFixedOrAbsolutePositioned(nativeEl: any): any {
		return (
			this.getStyle(nativeEl, 'position') === 'absolute' ||
			this.getStyle(nativeEl, 'position') === 'fixed'
		);
	}

	/**
	 * returns the closest, fixed or absolute positioned scrollable parent of a given element
	 * @param nativeEl
	 */
	public getFirstScrollableParent(nativeEl: any) {
		let offsetParent = nativeEl.offsetParent || this.document;

		while (
			offsetParent &&
			offsetParent !== this.document &&
			!(
				this.isFixedOrAbsolutePositioned(offsetParent) &&
				window.getComputedStyle(offsetParent).overflow != 'visible' &&
				window.getComputedStyle(offsetParent).overflow != 'hidden'
			)
		) {
			offsetParent = offsetParent.offsetParent;
		}
		return offsetParent || this.document;
	}

	/**
	 * returns the closest, non-statically positioned parentOffset of a given element
	 * @param nativeEl
	 */
	public parentOffsetEl(nativeEl: any) {
		let offsetParent = nativeEl.offsetParent || this.document;
		while (
			offsetParent &&
			offsetParent !== this.document &&
			this.isStaticPositioned(offsetParent)
		) {
			offsetParent = offsetParent.offsetParent;
		}
		return offsetParent || this.document;
	}

	/**
	 * Provides read-only equivalent of jQuery's position function:
	 * http://api.jquery.com/position/
	 */
	public position(nativeEl: any): {
		width: number;
		height: number;
		top: number;
		left: number;
	} {
		let elBCR = this.offset(nativeEl);
		let offsetParentBCR = { top: 0, left: 0 };
		let offsetParentEl = this.parentOffsetEl(nativeEl);
		if (offsetParentEl !== this.document) {
			offsetParentBCR = this.offset(offsetParentEl);
			offsetParentBCR.top +=
				offsetParentEl.clientTop - offsetParentEl.scrollTop;
			offsetParentBCR.left +=
				offsetParentEl.clientLeft - offsetParentEl.scrollLeft;
		}

		let boundingClientRect = nativeEl.getBoundingClientRect();
		return {
			width: boundingClientRect.width || nativeEl.offsetWidth,
			height: boundingClientRect.height || nativeEl.offsetHeight,
			top: elBCR.top - offsetParentBCR.top,
			left: elBCR.left - offsetParentBCR.left,
		};
	}

	/**
	 * Provides read-only equivalent of jQuery's offset function:
	 * http://api.jquery.com/offset/
	 */
	public offset(nativeEl: any): {
		width: number;
		height: number;
		top: number;
		left: number;
	} {
		let boundingClientRect = nativeEl.getBoundingClientRect();
		return {
			width: boundingClientRect.width || nativeEl.offsetWidth,
			height: boundingClientRect.height || nativeEl.offsetHeight,
			top:
				boundingClientRect.top +
				(this.window.pageYOffset ||
					this.document.documentElement.scrollTop),
			left:
				boundingClientRect.left +
				(this.window.pageXOffset ||
					this.document.documentElement.scrollLeft),
		};
	}

	public checkIfPositionFits(
		hostEl: any,
		targetEl: any,
		positionStr: any,
		offset?: any,
	) {
		var position = this.positionElements(
			hostEl,
			targetEl,
			positionStr,
			true,
			offset,
		);
		var padding = 20;
		let targetElWidth = targetEl.offsetWidth;
		let targetElHeight = targetEl.offsetHeight;

		if (positionStr === 'right') {
			if (
				position.left + targetElWidth + padding >
				this.document.documentElement.offsetWidth
			) {
				return false;
			}
		} else if (positionStr === 'left') {
			if (position.left - padding < 0) {
				return false;
			}
		} else if (positionStr === 'bottom') {
			if (
				position.top + targetElHeight + padding >
				this.document.documentElement.offsetHeight
			) {
				return false;
			}
		} else if (positionStr === 'top') {
			if (position.top - padding < 0) {
				return false;
			}
		}

		return true;
	}

	public smartPosition(
		hostElementOrPosition: any,
		targetEl: any,
		positionStr: any,
		appendToBody: any,
		offset?: any,
	): { top: number; left: number } {
		if (
			!this.checkIfPositionFits(
				hostElementOrPosition,
				targetEl,
				positionStr,
			)
		) {
			if (positionStr === 'right') positionStr = 'left';
			else if (positionStr === 'left') positionStr = 'right';
			else if (positionStr === 'top') positionStr = 'bottom';
			else if (positionStr === 'bottom') positionStr = 'top';
		}

		return this.positionElements(
			hostElementOrPosition,
			targetEl,
			positionStr,
			appendToBody,
			offset,
		);
	}

	/**
	 * Provides coordinates for the targetEl in relation to hostEl
	 */
	public positionElements(
		hostElementOrPosition: any,
		targetEl: any,
		positionStr: any,
		appendToBody: any,
		offset?: any,
	): { top: number; left: number } {
		let positionStrParts = positionStr.split('-');
		let pos0 = positionStrParts[0];
		let pos1 = positionStrParts[1] || 'center';
		let hostElPos: any;
		let targetElWidth = targetEl.offsetWidth;
		let targetElHeight = targetEl.offsetHeight;

		//If a position is passed instead of an object
		if (
			hostElementOrPosition.left !== undefined &&
			hostElementOrPosition.top !== undefined
		) {
			hostElPos = hostElementOrPosition;
			hostElPos.width = hostElementOrPosition.width || 0;
			hostElPos.height = hostElementOrPosition.height || 0;
		}
		//Object passed, calculate position
		else {
			hostElPos = appendToBody
				? this.offset(hostElementOrPosition)
				: this.position(hostElementOrPosition);
		}

		//Make sure offset is set
		if (!offset) {
			offset = {
				left: 0,
				top: 0,
			};
		}

		let shiftWidth = {
			center: function () {
				return Math.round(
					hostElPos.left + hostElPos.width / 2 - targetElWidth / 2,
				);
			},
			left: function () {
				return Math.round(hostElPos.left - offset.left);
			},
			right: function () {
				return Math.round(
					hostElPos.left + hostElPos.width + offset.left,
				);
			},
		};

		let shiftHeight = {
			center: function (): number {
				return Math.round(
					hostElPos.top + hostElPos.height / 2 - targetElHeight / 2,
				);
			},
			top: function (): number {
				return Math.round(hostElPos.top - offset.top);
			},
			bottom: function (): number {
				return Math.round(
					hostElPos.top + hostElPos.height + offset.top,
				);
			},
		};

		let targetElPos: { top: number; left: number };

		switch (pos0) {
			case 'cover':
				targetElPos = {
					left: hostElPos.left,
					top: hostElPos.top,
				};
				break;
			case 'right':
				targetElPos = {
					top: shiftHeight[pos1](),
					left: shiftWidth[pos0](),
				};
				break;
			case 'left':
				targetElPos = {
					top: shiftHeight[pos1](),
					left: hostElPos.left - targetElWidth,
				};
				break;
			case 'bottom':
				targetElPos = {
					top: shiftHeight[pos0](),
					left: shiftWidth[pos1](),
				};
				break;
			default:
				targetElPos = {
					top: hostElPos.top - targetElHeight,
					left: shiftWidth[pos1](),
				};
				break;
		}

		return targetElPos;
	}
}
