define('lodash-amd/modern/internal/baseToString', [], function () {
	/**
	 * Converts `value` to a string if it is not one. An empty string is returned
	 * for `null` or `undefined` values.
	 *
	 * @private
	 * @param {*} value The value to process.
	 * @returns {string} Returns the string.
	 */
	function baseToString(value) {
		if (typeof value == 'string') {
			return value;
		}
		return value == null ? '' : value + '';
	}

	return baseToString;
});

define('lodash-amd/modern/internal/escapeHtmlChar', [], function () {
	/** Used to map characters to HTML entities. */
	var htmlEscapes = {
		'&': '&amp;',
		'<': '&lt;',
		'>': '&gt;',
		'"': '&quot;',
		"'": '&#39;',
		'`': '&#96;',
	};

	/**
	 * Used by `_.escape` to convert characters to HTML entities.
	 *
	 * @private
	 * @param {string} chr The matched character to escape.
	 * @returns {string} Returns the escaped character.
	 */
	function escapeHtmlChar(chr) {
		return htmlEscapes[chr];
	}

	return escapeHtmlChar;
});

define('lodash-amd/modern/string/escape', [
	'../internal/baseToString',
	'../internal/escapeHtmlChar',
], function (baseToString, escapeHtmlChar) {
	/** Used to match HTML entities and HTML characters. */
	var reUnescapedHtml = /[&<>"'`]/g,
		reHasUnescapedHtml = RegExp(reUnescapedHtml.source);

	/**
	 * Converts the characters "&", "<", ">", '"', "'", and "\`", in `string` to
	 * their corresponding HTML entities.
	 *
	 * **Note:** No other characters are escaped. To escape additional characters
	 * use a third-party library like [_he_](https://mths.be/he).
	 *
	 * Though the ">" character is escaped for symmetry, characters like
	 * ">" and "/" don't require escaping in HTML and have no special meaning
	 * unless they're part of a tag or unquoted attribute value.
	 * See [Mathias Bynens's article](https://mathiasbynens.be/notes/ambiguous-ampersands)
	 * (under "semi-related fun fact") for more details.
	 *
	 * Backticks are escaped because in Internet Explorer < 9, they can break out
	 * of attribute values or HTML comments. See [#102](https://html5sec.org/#102),
	 * [#108](https://html5sec.org/#108), and [#133](https://html5sec.org/#133) of
	 * the [HTML5 Security Cheatsheet](https://html5sec.org/) for more details.
	 *
	 * When working with HTML you should always quote attribute values to reduce
	 * XSS vectors. See [Ryan Grove's article](http://wonko.com/post/html-escaping)
	 * for more details.
	 *
	 * @static
	 * @memberOf _
	 * @category String
	 * @param {string} [string=''] The string to escape.
	 * @returns {string} Returns the escaped string.
	 * @example
	 *
	 * _.escape('fred, barney, & pebbles');
	 * // => 'fred, barney, &amp; pebbles'
	 */
	function escape(string) {
		// Reset `lastIndex` because in IE < 9 `String#replace` does not.
		string = baseToString(string);
		return string && reHasUnescapedHtml.test(string)
			? string.replace(reUnescapedHtml, escapeHtmlChar)
			: string;
	}

	return escape;
});

define(['lodash-amd/modern/string/escape'], function (escape) {
	'use strict';

	return function () {
		return function (scribe) {
			scribe.registerPlainTextFormatter(escape);
		};
	};
});
