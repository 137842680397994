import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';

import 'zone.js';
import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';

import '../node_modules/codemirror/addon/mode/overlay';
import '../node_modules/codemirror/lib/codemirror';
import '../node_modules/codemirror/mode/javascript/javascript';
import '../node_modules/codemirror/mode/xml/xml';
import '../node_modules/codemirror/mode/htmlmixed/htmlmixed';
import '../node_modules/codemirror/addon/search/search';
import '../node_modules/codemirror/addon/search/searchcursor';
import '../node_modules/codemirror/addon/search/jump-to-line';

if (environment.NAME === 'production') {
	enableProdMode();
}

const bootstrap = () => bootstrapApplication(AppComponent, appConfig);

bootstrap();
