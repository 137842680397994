import { Component, ElementRef, OnDestroy, Renderer2 } from '@angular/core';
import {
	BFPositionService,
	BFPopoverComponent,
} from '../../../../libs/material';
import { DomSanitizer } from '@angular/platform-browser';
import qrcode from 'qrcode-generator';
import { NgClass, NgStyle } from '@angular/common';

@Component({
	selector: 'qrCodePopover',
	styleUrls: ['./qrCodePopover.component.scss'],
	templateUrl: './qrCodePopover.component.html',
	standalone: true,
	imports: [NgClass, NgStyle],
})
export class QRCodePopoverComponent
	extends BFPopoverComponent
	implements OnDestroy
{
	public svgTag: any;
	public isMobile: boolean = true;
	constructor(
		elementRef: ElementRef,
		positionService: BFPositionService,
		Renderer2: Renderer2,
		private domSanitizer: DomSanitizer,
	) {
		super(elementRef, positionService, Renderer2);
	}

	public show(
		element: Element,
		placement: string,
		url: string,
	): Promise<boolean> {
		this.svgTag = this.renderQRCode(url);

		return super.open(element, placement);
	}

	public renderQRCode(url: string): any {
		const qrCode = qrcode(10, 'M');
		qrCode.addData(url, 'Byte');
		qrCode.make();

		const svgTag = qrCode.createSvgTag();
		return this.domSanitizer.bypassSecurityTrustHtml(svgTag);
	}

	ngOnDestroy() {
		super.ngOnDestroy();
	}
}
