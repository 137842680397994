import { Injectable } from '@angular/core';
import { BFMaterial } from '../../bfMaterial';
import { BFComponentContainer } from '../../containers/bfComponent.container';
import { BFNotificationComponent } from './bfNotification.component';

@Injectable({ providedIn: 'root' })
export class BFNotificationService {
	private notificationShowing: boolean = false;
	private notificationComponent: any;

	constructor(
		private componentContainer: BFComponentContainer,
		private bfMaterial: BFMaterial,
	) {}

	/**
	 * DEPRECATED
	 * @param  {string} text
	 * @param  {string='info'} type info|success|error|warning
	 * @param  {string='bottom'} placement bottom|top
	 * @param  {number=5000} delay A timer in ms to autoclose the notification. Pass 0 to make it remain until closed manually
	 */
	public show(
		text: string | string[],
		type: BFNotificationType = 'info',
		placement: BFNotificationPlacement = 'bottom',
		delay?: number,
		icon?: string,
	): void {
		if (this.notificationShowing) {
			this.close();
		}

		this.notificationShowing = true;

		// Set default value if not error
		if (type !== 'error' && !delay) {
			delay = 5000;
		}

		this.notificationComponent = this.componentContainer.attach(
			BFNotificationComponent,
			this.bfMaterial.rootViewContainerRef,
		);
		this.notificationComponent.instance
			.initiate(text, type, placement, delay, icon)
			.then(() => {
				this.close();
			});
	}

	/**
	 * New way to show, have some refactoring left but still better
	 * @param config
	 */
	public open(
		text: string | string[],
		config: IBFNotificationConfig = {},
	): void {
		this.show(
			text,
			config.type,
			config.placement,
			config.autoCloseDelay,
			config.icon,
		);
	}

	public close(): void {
		this.notificationShowing = false;
		this.componentContainer.detach(this.notificationComponent);
	}
}

export interface IBFNotificationConfig {
	type?: BFNotificationType;
	icon?: string;
	autoCloseDelay?: number;
	placement?: BFNotificationPlacement;
	hideCloseIcon?: boolean;
}

export type BFNotificationType = 'info' | 'warning' | 'error' | 'success';
export type BFNotificationPlacement = 'top' | 'bottom';
