import {
	Component,
	ViewContainerRef,
	SecurityContext,
	ElementRef,
	ViewChild,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BFMaterial, BFComponentContainer } from '../../../libs/material/index';
import { QRCodePopoverComponent } from './components/qrCodePopover.component';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { BFTooltipDirective } from '../../../libs/material/components/tooltip/bfTooltip.directive';
import { ArtboardComponent } from '../../design/views/design/components/designEditor/artboard/artboard.component';
import { EllipsisMiddlePipe } from '../../shared/pipes/ellipsisMiddle.pipe';
import { BFEllipsisPipe } from '../../../libs/material/pipes/ellipsis/bfEllipsis.pipe';
import { LandingPageModel } from 'shared/models/landingPage.model';
import { PickSizeComponent } from 'views/main/components/header/pickSize.component';
import { Cnames, PublishService } from 'shared/services/publish.service';
import { UserService } from 'shared/services/user.service';
import { LandingPageService } from 'shared/services/landingPage.service';
import { DesignService } from 'design/design.service';
import { TextService } from 'shared/services/text.service';
import { LocalizationService } from 'shared/services/localization.service';
import { TranslationModel } from 'shared/models/translation.model';
import { PublishState } from 'shared/enums/publishState.enum';
import { UIModule } from '@bannerflow/ui';
import { MenuItemModel } from 'shared/models/menuItem.model';

interface IRouteParams {
	accountSlug: string;
	brandId: string;
	landingPageId: string;
	translationId: string;
}

@Component({
	selector: 'showcaseView',
	templateUrl: 'showcase.view.component.html',
	styleUrls: ['showcase.view.component.scss'],
	standalone: true,
	imports: [
		CommonModule,
		BFTooltipDirective,
		ArtboardComponent,
		EllipsisMiddlePipe,
		BFEllipsisPipe,
		UIModule,
	],
})
export class ShowcaseView extends PickSizeComponent {
	public landingPage: LandingPageModel;
	public translationId: string;
	public cnames: Cnames;
	public publicUrl: string;
	public showInfo: boolean = false;
	public translationMenuItems = new Array<MenuItemModel>();

	@ViewChild('infoButton', { static: true }) public infoButton: ElementRef;
	@ViewChild('info', { static: true }) public info: ElementRef;
	@ViewChild('header', { static: true }) public header: ElementRef;
	@ViewChild('body', { static: true }) public body: ElementRef;

	constructor(
		private readonly bfMaterial: BFMaterial,
		private readonly activatedRoute: ActivatedRoute,
		private readonly userService: UserService,
		private readonly viewContainerRef: ViewContainerRef,
		private readonly landingPageService: LandingPageService,
		public readonly designService: DesignService,
		private readonly router: Router,
		public readonly textService: TextService,
		private readonly publishService: PublishService,
		private readonly sanitizer: DomSanitizer,
		private readonly componentContainer: BFComponentContainer,
	) {
		super(designService, textService);

		let initialized: boolean = false;
		this.activatedRoute.params.subscribe(async (params: IRouteParams) => {
			if (!initialized) {
				this.userService.init(params.accountSlug, params.brandId, true);
				this.landingPageService.init(params.landingPageId, null, true);
				this.textService.init(params.translationId);
				this.landingPage = await this.landingPageService.get();
				initialized = true;
			} else {
				this.setCurrentVersion(params.translationId, this.landingPage);
			}
			this.trySetPublicUrl();
			this.translationId = params.translationId;
			this.getTranslationMenuItems();
		});

		this.bfMaterial.init(viewContainerRef);

		this.publishService.getCnames().then((cnames) => {
			this.cnames = cnames;
			if (this.textService.currentTranslation) {
				this.trySetPublicUrl();
			}
		});
		this.toggleInfo = this.toggleInfo.bind(this);
		this.textService.currentTranslationChange.subscribe(
			this.trySetPublicUrl.bind(this),
		);
	}

	public toggleInfo(
		event: MouseEvent,
		clickIsFromButton: boolean = false,
	): void {
		// Don't handle clicks when 'showInfo' is on. Because we already registered an event listener on the document.
		if (this.showInfo && clickIsFromButton) {
			return;
		}
		if (
			this.info &&
			isDescendantOf(this.info.nativeElement, event.target as Node)
		) {
			return;
		}

		this.showInfo = !this.showInfo;

		setTimeout(() => {
			if (this.showInfo) {
				document.addEventListener('click', this.toggleInfo);
			} else {
				document.removeEventListener('click', this.toggleInfo);
			}
		}, 0);
	}

	public edit(): void {
		if (!this.landingPage && !this.translationId) {
			return;
		}

		window.open(
			`/editor/${this.landingPage.accountSlug}/${this.landingPage.brandId}/${this.landingPage.id}/${this.translationId}/design`,
			'_blank',
		);
	}

	public hideTopBar(): void {
		this.header.nativeElement.classList.add(
			'showcaseHeader__header--hidden',
		);
		this.body.nativeElement.classList.add('showcaseBody--hidden');
	}

	public toggleQRCode(event: MouseEvent): void {
		const qrPopover = this.componentContainer.attach(
			QRCodePopoverComponent,
		);
		qrPopover.instance
			.show(event.currentTarget as any, 'bottom', window.location.href)
			.then(() => {
				this.componentContainer.detach(qrPopover);
			});
	}

	private trySetPublicUrl() {
		if (
			this.landingPage &&
			this.cnames &&
			this.textService.currentTranslation
		) {
			const translation = this.textService.currentTranslation;
			this.publicUrl =
				this.publishService.getCname(
					translation.localizationId,
					this.landingPage.accountSlug,
					this.landingPage.brandId,
					this.cnames,
				) +
				translation.publishSlug +
				'/index.html';
		}
	}

	private setCurrentVersion(
		translationId: string,
		landingPage: LandingPageModel,
	) {
		const allTranslations = [
			landingPage.originalTranslation,
			...(landingPage.translations || []),
		];
		const translation = allTranslations.find((t) => t.id === translationId);
		this.textService.setCurrentTranslation(translation);
	}

	private getTranslationMenuItem(
		translation: TranslationModel,
	): MenuItemModel {
		let className: string = '';
		switch (translation.publishState) {
			case PublishState.Published:
				className = 'showcaseHeader__status--published';
				break;
			case PublishState.PublishedWithPendingUpdates:
				className = 'showcaseHeader__status--changes';
				break;
			default:
				className = '';
		}
		const menuItem = new MenuItemModel(
			this.sanitizer.sanitize(SecurityContext.HTML, translation.name),
		);
		menuItem.onSelect = () => {
			this.router.navigate([`../${translation.id}`], {
				relativeTo: this.activatedRoute,
			});
		};
		menuItem.status = this.sanitizer.sanitize(
			SecurityContext.HTML,
			this.getStatusClassName(translation),
		);
		menuItem.flag = translation.culture;

		return menuItem;
	}

	public getStatusClassName(translation: TranslationModel): string {
		let className: string = '';
		switch (translation.publishState) {
			case PublishState.Published:
				className = 'showcaseHeader__status--published';
				break;
			case PublishState.PublishedWithPendingUpdates:
				className = 'showcaseHeader__status--changes';
				break;
			default:
				className = 'showcaseHeader__status';
		}
		return className;
	}

	private getTranslationMenuItems(): void {
		this.translationMenuItems = [];
		this.landingPage.translations.forEach(
			(translation: TranslationModel) => {
				const item = this.getTranslationMenuItem(translation);
				this.translationMenuItems.push(item);
			},
		);

		this.translationMenuItems.sort((a: MenuItemModel, b: MenuItemModel) => {
			return a.label.toUpperCase() > b.label.toUpperCase() ? 1 : -1;
		});

		const originalTranslationMenuItem = this.getTranslationMenuItem(
			this.landingPage.originalTranslation,
		);
		this.translationMenuItems.unshift(originalTranslationMenuItem);
	}

	public handleDropdownSelect(menuItem: MenuItemModel): void {
		if (menuItem.onSelect) {
			menuItem.onSelect();
		}
	}
}

function isDescendantOf(parent: Node, child: Node) {
	let node = child.parentNode;
	while (node != null) {
		if (node === parent) {
			return true;
		}
		node = node.parentNode;
	}
	return false;
}
