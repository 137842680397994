define(function () {
	'use strict';

	return function () {
		return function (scribe) {
			var outdentCommand = new scribe.api.Command('outdent');

			outdentCommand.queryEnabled = function () {
				/**
				 * FIXME: If the paragraphs option is set to true, then when the
				 * list is unapplied, ensure that we enter a P element.
				 * Currently we just disable the command when the selection is inside of
				 * a list.
				 */
				var selection = new scribe.api.Selection();
				var listElement = selection.getContaining(function (element) {
					return (
						element.nodeName === 'UL' || element.nodeName === 'OL'
					);
				});

				// FIXME: define block element rule here?
				return (
					scribe.api.Command.prototype.queryEnabled.call(this) &&
					scribe.allowsBlockElements() &&
					!listElement
				);
			};

			scribe.commands.outdent = outdentCommand;
		};
	};
});
