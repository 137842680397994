import { Injectable, EventEmitter } from '@angular/core';
import { LandingPageService } from './landingPage.service';
import { ApiService } from './api.service';
import { UtilsService } from './utils.service';
import { HttpClient } from '@angular/common/http';
import { TextModel } from 'shared/models/text.model';
import { ResourceTranslationModel } from 'shared/models/resourceTranslation.model';
import { TranslationModel } from 'shared/models/translation.model';
import { LandingPageModel } from 'shared/models/landingPage.model';
import { FileModel } from 'shared/models/file.model';
import { State } from 'shared/enums/state.enum';
import { FolderModel } from 'shared/models/folder.model';

@Injectable({ providedIn: 'root' })
export class TextService {
	public textChange: EventEmitter<TextModel> = new EventEmitter<TextModel>();
	public blockElementChange: EventEmitter<TextModel> =
		new EventEmitter<TextModel>();
	public resourceChange: EventEmitter<ResourceTranslationModel> =
		new EventEmitter<ResourceTranslationModel>();
	public currentTranslationChange: EventEmitter<TranslationModel> =
		new EventEmitter<TranslationModel>();
	public currentTranslation: TranslationModel;
	private landingPage: LandingPageModel;
	private translationTextCache: { [hash: string]: Promise<any> } = {};

	constructor(
		private landingPageService: LandingPageService,
		private http: HttpClient,
		private apiService: ApiService,
	) {}

	public init(translationId?: string) {
		this.landingPageService.get().then((landingPage: LandingPageModel) => {
			this.landingPage = landingPage;
			this.selectTranslation(
				translationId
					? this.landingPage.translations.find(
							(t) => t.id === translationId,
						) || this.landingPage.originalTranslation
					: this.landingPage.originalTranslation,
			);
		});
	}

	public setCurrentTranslation(translation: TranslationModel): Promise<void> {
		return new Promise<void>((resolve: Function) => {
			if (translation.texts == null && translation.id) {
				let url = `get?landingPageId=${this.landingPage.id}&translationIds=${translation.id}`;
				const hash = this.landingPage.id + translation.id;
				let cache = this.translationTextCache[hash];
				if (cache) {
					return cache.then(resolve as any);
				}
				this.translationTextCache[hash] = this.apiService
					.get('translation', url)
					.then((response) => {
						let texts: TextModel[] = [];
						if (response[0] && response[0].texts) {
							response[0].texts.forEach((t) => {
								let newTextModel = new TextModel();
								texts.push(newTextModel.deserialize(t));
							});
						}

						translation.texts = texts;
						this.selectTranslation(translation);

						setTimeout(() => {
							this.translationTextCache[hash] = undefined;
						}, 5000);

						resolve();
					});
			} else {
				this.selectTranslation(translation);
				resolve();
			}
		});
	}

	public refreshTexts(file: FileModel, translation?: TranslationModel): void {
		const currentTranslation = translation || this.currentTranslation;
		const texts: TextModel[] = [];
		if (!currentTranslation.texts) {
			currentTranslation.texts = texts;
		}
		currentTranslation.texts.forEach((text) => {
			if (!text.originalResourceIds) text.originalResourceIds = [];

			// find out if the text and file are associated
			var fileIndex = -1;
			for (let i = 0; i < text.originalResourceIds.length; i++) {
				if (text.originalResourceIds[i] === file.id) {
					fileIndex = i;
					i = text.originalResourceIds.length;
				}
			}

			let keyIsInFile = file.stringContent.indexOf(text.key) >= 0;
			if (keyIsInFile && fileIndex < 0) {
				// the translation is in the file but not in the list of files,
				// so it's added
				text.originalResourceIds.push(file.id);
			} else if (!keyIsInFile && fileIndex >= 0) {
				// the transtation is not in the file but are in the list of files,
				// so it's removed
				text.originalResourceIds.splice(fileIndex, 1);
			}

			// if the text still appears in any file, keep it
			if (text.originalResourceIds.length !== 0) {
				texts.push(text);
			}
		});

		currentTranslation.texts = texts;
	}

	private selectTranslation(translation: TranslationModel): void {
		this.currentTranslation = translation;
		//Dispatch translation change event
		this.currentTranslationChange.emit(translation);
	}

	public getTextByKey(
		key: string,
		translation?: TranslationModel,
	): TextModel {
		return (translation || this.currentTranslation).texts.find(
			(t) => t.key === key,
		);
	}

	public getOriginalTextByKey(key: string): TextModel {
		var text = this.landingPage.originalTranslation.texts.find(
			(value: TextModel) => {
				return value.key === key;
			},
		);

		return text;
	}

	public addText(value: string, translation?: TranslationModel): TextModel {
		var text = new TextModel();
		text.key = UtilsService.generateGUID();
		text.value = value;

		(translation || this.currentTranslation).texts.push(text);

		//Emit textChange event
		this.textChange.emit(text);

		this.landingPageService.toggleDirty(true);

		return text;
	}

	public addTranslatableFile(file: FileModel): ResourceTranslationModel {
		let resourceTranslation = this.fileToResourceTranslation(file);
		resourceTranslation.originalResourceId = file.id;
		return this.addResource(resourceTranslation);
	}

	private removeResourceTranslations(originalResourceId: string) {
		if (this.landingPage.originalTranslation.resourceTranslations) {
			this.landingPage.originalTranslation.resourceTranslations =
				this.landingPage.originalTranslation.resourceTranslations.filter(
					(rt) => {
						return rt.originalResourceId !== originalResourceId;
					},
				);
		}

		if (this.landingPage.translations) {
			this.landingPage.translations.forEach(
				(translation: TranslationModel) => {
					if (translation.resourceTranslations) {
						translation.resourceTranslations =
							translation.resourceTranslations.filter((rt) => {
								return (
									rt.originalResourceId !== originalResourceId
								);
							});
					}
				},
			);
		}
	}

	public removeTranslatableFile(file: FileModel): ResourceTranslationModel {
		let resource = this.getOriginalResource(file.id);
		this.landingPageService.toggleDirty(true, false);

		this.removeResourceTranslations(file.id);

		if (resource) {
			resource.state = State.Deleted;

			return resource;
		}

		return undefined;
	}

	public addResource(
		resourceTranslation: ResourceTranslationModel,
	): ResourceTranslationModel {
		let existingResource = this.getOriginalResource(
			resourceTranslation.originalResourceId,
		);
		this.landingPageService.toggleDirty(true, false);

		if (existingResource) {
			existingResource.state = State.New;
			return existingResource;
		}

		this.landingPage.originalTranslation.resourceTranslations.push(
			resourceTranslation,
		);
		return resourceTranslation;
	}

	public addFileToCurrentTranslation(
		file: FileModel,
		originalResourceId: string,
	): ResourceTranslationModel {
		const resource = this.fileToResourceTranslation(file);
		resource.originalResourceId = originalResourceId;
		return this.addResourceToCurrentTranslation(resource);
	}

	public addResourceToCurrentTranslation(
		resource: ResourceTranslationModel,
	): ResourceTranslationModel {
		this.landingPageService.toggleDirty(true);
		let oldResource = this.getTranslatedResource(
			resource.originalResourceId,
		);
		if (oldResource) {
			oldResource.resourceId = resource.resourceId;
			oldResource.url = resource.url;
			oldResource.state = State.Modified;
			this.resourceChange.emit(oldResource);
			return oldResource;
		} else {
			resource.state = State.New;
			this.currentTranslation.resourceTranslations.push(resource);
			this.resourceChange.emit(resource);
		}
		return resource;
	}

	public async updateResourceInAllTranslations(
		newFile: FileModel,
		originalResourceId: string,
		isReplacingNewOne: boolean,
	) {
		this.landingPageService.toggleDirty(true);
		const landingPage = await this.landingPageService.get();
		if (landingPage.originalTranslation.resourceTranslations) {
			const originalResourceTranslation =
				landingPage.originalTranslation.resourceTranslations.find(
					(rt) => {
						return rt.originalResourceId === originalResourceId;
					},
				);
			if (isReplacingNewOne) {
				originalResourceTranslation.originalResourceId = newFile.id;
			}
			originalResourceTranslation.resourceId = newFile.id;
			originalResourceTranslation.url = newFile.url;
		}
		for (const t of landingPage.translations) {
			if (!t.resourceTranslations) {
				continue;
			}
			const originalResourceTranslation = t.resourceTranslations.find(
				(rt) => {
					return rt.originalResourceId === originalResourceId;
				},
			);
			if (!originalResourceTranslation) {
				continue;
			}
			if (isReplacingNewOne) {
				originalResourceTranslation.originalResourceId = newFile.id;
			}
			originalResourceTranslation.resourceId = newFile.id;
			originalResourceTranslation.url = newFile.url;
		}
		// Don't fire any resource change events because we don't have the correct file reference.
	}

	public setTextValue(
		key: any,
		value: string,
		translation?: TranslationModel,
		isHtml: boolean = false,
	): TextModel {
		var text = this.getTextByKey(key, translation);

		//Set value if value has changed
		if (text && text.value !== value) {
			text.value = value.replace(
				/(\n|\r)/gi,
				isHtml == false ? '<br>' : '',
			);

			//Emit textChange event
			this.textChange.emit(text);
		} else if (!text) {
			text = this.addText(value);
			text.key = key;
		}

		this.landingPageService.toggleDirty(true, false);

		return text;
	}

	//Return the original translation (TODO: USE IN FILE BROWSER)
	public getOriginalResource(originalId: string): ResourceTranslationModel {
		return this.landingPage.originalTranslation.resourceTranslations.find(
			(resource: ResourceTranslationModel) => {
				return resource.originalResourceId === originalId;
			},
		);
	}

	//TASK FOR THE MIND: Should this return anything on original translation?
	public getTranslatedResource(originalId: string): ResourceTranslationModel {
		return this.currentTranslation.resourceTranslations.find(
			(resource: ResourceTranslationModel) => {
				return resource.originalResourceId === originalId;
			},
		);
	}

	public getCurrentResource(originalId: string): ResourceTranslationModel {
		return (
			this.getTranslatedResource(originalId) ||
			this.getOriginalResource(originalId)
		);
	}

	public getTranslatableImages(): Array<FileModel> {
		let images: Array<FileModel> = new Array<FileModel>();

		let searchFolder = (folder: FolderModel) => {
			for (let file of folder.files) {
				if (file.translatable) {
					images.push(file);
				}
			}
			for (let subFolder of folder.folders) {
				searchFolder(subFolder);
			}
		};
		searchFolder(this.landingPage.rootFolder);

		return images;
	}

	public isOriginalTranslation(): boolean {
		return this.currentTranslation === this.landingPage.originalTranslation;
	}

	public setAllowBlockElement(key: string, allow: boolean) {
		let text = this.getOriginalTextByKey(key); //Always on original
		if (text && text.isBlockElement !== allow) {
			text.isBlockElement = allow;
			this.blockElementChange.emit(text);
		}
	}

	//Convert a file model to a resource
	private fileToResourceTranslation(
		file: FileModel,
	): ResourceTranslationModel {
		let resourceTranslation = new ResourceTranslationModel();
		resourceTranslation.resourceId = file.id;
		resourceTranslation.url = file.url;
		resourceTranslation.name = file.name;
		resourceTranslation.state = State.New;
		return resourceTranslation;
	}
}
