<div
    class="translationImageInput translationImageInput--card"
    [ngClass]="{'translationImageInput--active': active}">
    <!--NAME-->
    <div class="translationImageInput__imageName">
        <div class="translationImageInput__imageNumber">{{ index }}</div>
        <span class="translationImageInput__imageNameInput">
            {{translation.originalResource.name}}
        </span>
    </div>

    <!--PREVIEW-->
    <div #preview class="translationImageInput__preview">
        <div class="translationImageInput__replace" (click)="uploadFiles()">
            <div class="translationImageInput__replaceInner">
                <i
                    class="bf-icon bf-icon-3x bf-icon-upload"
                    style="padding-bottom: 1rem;"></i>
                <br />
                <span>Replace</span>
            </div>
        </div>
        <div
            class="translationImageInput__image"
            [ngStyle]="{ backgroundImage: 'url(' + url + ')'}"></div>
    </div>
</div>
