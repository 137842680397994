<div class="bfDialog" (click)="closeByClickOutside($event)">
    <div class="bfDialog__content">
        <div (click)="close()" class="bfDialog__close">
            <i class="bf-icon bf-icon-close-big"></i>
        </div>
        <div class="bfDialog__contentInner">
            <ng-content></ng-content>
        </div>
    </div>
</div>
