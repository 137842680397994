import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';

import { BFServerResponse } from '../../../libs/material';
import { UserService } from './user.service';
import { environment } from '../../../environments/environment';

@Injectable({
	providedIn: 'root',
})
export class ApiService {
	constructor(
		private readonly userService: UserService,
		private readonly http: HttpClient,
	) {}

	public async post<T>(
		controller: string,
		action: string,
		data: any,
	): Promise<T> {
		const url = await this.getUserUrl(controller, action);
		try {
			const response = await firstValueFrom(
				this.http.post<BFServerResponse<T>>(url, data),
			);
			return response.data;
		} catch (error) {
			return this.handleError(error);
		}
	}

	public async get<T>(
		controller: string,
		action: string,
		isStatic: boolean = false,
	): Promise<T> {
		if (isStatic) {
			return this.makeGetRequest<T>(
				`${environment.API_URL}/api/${controller}/${action}`,
			);
		} else {
			const url_1 = await this.getUserUrl(controller, action);
			return await this.makeGetRequest<T>(url_1);
		}
	}

	public async patch<T>(
		controller: string,
		action: string,
		data?: any,
	): Promise<T> {
		const url = await this.getUserUrl(controller, action);
		try {
			const response = await firstValueFrom(
				this.http.patch<BFServerResponse<T>>(url, data),
			);
			return response.data;
		} catch (error) {
			return this.handleError(error);
		}
	}

	private async getUserUrl(
		controller: string,
		action: string,
	): Promise<string> {
		const user = await this.userService.get();
		return `${environment.API_URL}/api/${controller}/${user.accountSlug}/${user.brandId}/${action}`;
	}

	private async makeGetRequest<T>(url: string): Promise<T> {
		try {
			const response = await firstValueFrom(
				this.http.get<BFServerResponse<T>>(url),
			);
			return response.data;
		} catch (error) {
			return this.handleError(error);
		}
	}

	private handleError(error: HttpErrorResponse): never {
		let errorMessage = 'Unknown error';
		if (error.error instanceof ErrorEvent) {
			errorMessage = `Error: ${error.error.message}`;
		} else {
			errorMessage =
				error.error?.Message ||
				`Error Code: ${error.status}\nMessage: ${error.message}`;
		}
		console.error(errorMessage);
		throw new Error(errorMessage);
	}
}
