import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BFMaterial } from './bfMaterial';
import { BFNotificationService } from './services/notification/bfNotification.service';
import { BFNotificationComponent } from './services/notification/bfNotification.component';

// Components
import {
	BFDatePickerButton,
	BFDatePickerComponent,
	BFDialogComponent,
	BFPopoverComponent,
	BFTooltipComponent,
	BFTooltipDirective,
	BFTagInputComponent,
	BFSearchComponent,
} from './components/index';

// Directives
import { BFInlineEditDirective } from './directives/index';

// Containers
import {
	BFComponentContainer,
	BFDialogContainer,
	BFOverlayContainer,
	BFTooltipContainer,
} from './containers/index';

// Services
import { BFPositionService, BFUtilsService } from './services/index';

// Pipes
import {
	BFAgoPipe,
	BFBytesPipe,
	BFCapitalizePipe,
	BFEllipsisPipe,
	BFMapPropertyPipe,
	BFOrderByPipe,
	BFSecondsPipe,
	BFToSentencePipe,
} from './pipes/index';
import { FormsModule } from '@angular/forms';

@NgModule({
	imports: [
		FormsModule,
		CommonModule,
		// Components
		BFDatePickerButton,
		BFDatePickerComponent,
		BFDialogComponent,
		BFNotificationComponent,
		BFNotificationComponent,
		BFPopoverComponent,
		BFNotificationComponent,
		BFNotificationComponent,
		BFDatePickerComponent,
		BFDatePickerButton,
		BFTagInputComponent,
		BFTooltipComponent,
		BFSearchComponent,
		// Directives
		BFTooltipDirective,
		BFInlineEditDirective,
		BFInlineEditDirective,
		BFInlineEditDirective,
		BFInlineEditDirective,
		// Pipes
		BFAgoPipe,
		BFBytesPipe,
		BFCapitalizePipe,
		BFEllipsisPipe,
		BFMapPropertyPipe,
		BFOrderByPipe,
		BFSecondsPipe,
		BFToSentencePipe,
	],
	exports: [
		// Components
		BFDatePickerButton,
		BFDatePickerComponent,
		BFDialogComponent,
		BFNotificationComponent,
		BFPopoverComponent,
		BFNotificationComponent,
		BFDatePickerComponent,
		BFDatePickerButton,
		BFTagInputComponent,
		BFTooltipComponent,
		BFSearchComponent,
		// Directives
		BFInlineEditDirective,
		BFTooltipDirective,
		// Pipes
		BFAgoPipe,
		BFBytesPipe,
		BFCapitalizePipe,
		BFEllipsisPipe,
		BFMapPropertyPipe,
		BFOrderByPipe,
		BFSecondsPipe,
		BFToSentencePipe,
	],
	providers: [
		BFComponentContainer,
		BFDialogContainer,
		BFMaterial,
		BFNotificationService,
		BFOverlayContainer,
		BFPositionService,
		BFTooltipContainer,
		BFUtilsService,
		// Pipes
		BFAgoPipe,
		BFBytesPipe,
		BFCapitalizePipe,
		BFEllipsisPipe,
		BFMapPropertyPipe,
		BFOrderByPipe,
		BFSecondsPipe,
		BFToSentencePipe,
		BFNotificationComponent,
		BFPopoverComponent,
		BFTooltipComponent,
	],
})
export class BFMaterialModule {}
