import {
	Component,
	Input,
	Output,
	EventEmitter,
	OnDestroy,
	ViewChild,
	ElementRef,
} from '@angular/core';
import { BFPositionService } from '../../services/index';

@Component({
	selector: 'bfTooltipComponent',
	template: `<div
		#tooltip
		[hidden]="!text"
		class="bfTooltip bfTooltip--{{ placement }} bfTooltip--{{
			theme
		}}Theme bfTooltip--{{ animation }}Animation
                    bfTooltip--{{ arrow }}Arrow bfTooltip--{{ align }}Align"
		[style.left]="position.left + 'px'"
		[style.top]="position.top + 'px'"
		[style.maxWidth]="maxWidth"
		[style.margin]="margin"
	>
		{{ text }}
	</div>`,
	standalone: true,
	styleUrls: ['./bfTooltip.component.scss'],
})
export class BFTooltipComponent implements OnDestroy {
	public text: string = '';
	public placement: string = 'top';
	public theme: string;
	public animation: string;
	public arrow: string;
	public align: string;
	public position: any = { left: 0, top: 0 };
	public maxWidth: string;
	public margin: string;

	private delay: number = 350;
	private timer: any;
	private type: string;

	@ViewChild('tooltip', { static: true }) tooltip: ElementRef;
	constructor(private positionService: BFPositionService) {}

	ngOnDestroy() {
		clearTimeout(this.timer);
	}

	public initiate(
		hostElement: ElementRef,
		text: string,
		placement: string,
		delay: number = 350,
		theme: string = 'default',
		animation: string = '',
		type: string = 'hover',
		showArrow: boolean = true,
		maxWidth: string = '',
		margin: string = '',
		align: string = '',
	): void {
		this.text = text;
		this.placement = placement;
		this.delay = delay;
		this.theme = theme;
		this.animation = animation;
		this.type = type;
		this.maxWidth = maxWidth;
		this.align = align;
		this.margin = margin;

		// We need to position the tooltip outside the viewport, because the default position might (0, 0)
		// "cover" an element.
		this.position = { left: -1000000, top: -1000000 };

		this.tooltip.nativeElement?.classList.remove('bfTooltip--show');

		if (showArrow) {
			switch (this.placement) {
				case 'left':
					this.arrow = 'right';
					break;
				case 'right':
					this.arrow = 'left';
					break;
				case 'bottom':
					this.arrow = 'top';
					break;
				default:
					this.arrow = 'bottom';
					break;
			}
		}

		this.timer = setTimeout(() => {
			this.position = this.positionService.positionElements(
				hostElement.nativeElement,
				this.tooltip.nativeElement,
				this.placement,
				true,
			);

			// Check only left and top overflow and use an another placement if overflowed.
			if (this.position.left < 0 && this.placement === 'left') {
				this.initiate(
					hostElement,
					text,
					'right',
					delay,
					theme,
					animation,
					type,
					showArrow,
					maxWidth,
					margin,
					align,
				);
				return;
			} else if (this.position.top < 0 && this.placement === 'top') {
				this.initiate(
					hostElement,
					text,
					'bottom',
					delay,
					theme,
					animation,
					type,
					showArrow,
					maxWidth,
					margin,
					align,
				);
				return;
			}

			this.tooltip.nativeElement.classList.add('bfTooltip--show');
		}, this.delay);
	}

	public hide(): void {
		this.tooltip.nativeElement.classList.add('bfTooltip--hide');
	}
}
