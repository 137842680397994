import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
	name: 'bfAgo',
	standalone: true,
})
export class BFAgoPipe implements PipeTransform {
	/**
	 * Takes a string and returns the moment.fromNow version of that string
	 * @param date
	 */
	public transform(date: moment.MomentInput): string {
		return moment(date).fromNow();
	}
}
