import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'bfBytes',
	standalone: true,
})
export class BFBytesPipe implements PipeTransform {
	transform(bytes: any, args?: any) {
		if (isNaN(parseFloat(bytes)) || !isFinite(bytes)) {
			return 'N/A';
		}

		if (bytes <= 0) {
			return '0 kB';
		}

		const k: any = 1000;
		const sizes: string[] = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB'];
		const i: number = Math.floor(Math.log(bytes) / Math.log(k));
		const precision: number = bytes < k ? 0 : 1;
		return (bytes / Math.pow(k, i)).toFixed(precision) + ' ' + sizes[i];
	}
}
