// libs
import { Component, Input } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { BFTooltipDirective } from '../../../../../libs/material/components/tooltip/bfTooltip.directive';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { NgClass } from '@angular/common';
import { UserService } from 'shared/services/user.service';
import { LandingPageService } from 'shared/services/landingPage.service';
import { TextService } from 'shared/services/text.service';
import { HotkeysService } from 'shared/services/hotkeys.service';
import { UserModel } from 'shared/models/user.model';
import { LandingPageModel } from 'shared/models/landingPage.model';
import { UIModule } from '@bannerflow/ui';

@Component({
	selector: 'footer',
	styleUrls: ['footer.component.scss'],
	templateUrl: 'footer.component.html',
	standalone: true,
	imports: [
		BFTooltipDirective,
		RouterLink,
		NgClass,
		RouterLinkActive,
		UIModule,
	],
})
export class FooterComponent {
	@Input() public designRoute: boolean;

	constructor(
		private readonly userService: UserService,
		private readonly landingPageService: LandingPageService,
		private readonly textService: TextService,
		private readonly hotkeysService: HotkeysService,
		private readonly http: HttpClient,
	) {
		// setup hokeys for previewing landing page
		this.hotkeysService.bindKey(['command+p', 'ctrl+p'], () => {
			this.showcase();
		});
	}

	public showcase(): void {
		this.userService.get().then((user: UserModel) => {
			this.landingPageService
				.get()
				.then((landingPage: LandingPageModel) => {
					let previewUrl = `/showcase/${user.accountSlug}/${user.brandId}/${landingPage.id}/${this.textService.currentTranslation.id}`;
					window.open(previewUrl, '_blank');
				});
		});
	}

	public exit(): void {
		this.userService.get().then((user: UserModel) => {
			this.landingPageService
				.get()
				.then((landingPage: LandingPageModel) => {
					let exitUrl = `exit?accountSlug=${user.accountSlug}&brandId=${user.brandId}&landingPageSlug=${landingPage.landingPageSlug}`;
					this.http
						.get(exitUrl)
						.toPromise()
						.then((response: any) => {
							let redirectUrl = response.json().data;
							window.location.href = redirectUrl;
						});
				});
		});
	}
}
