<div class="footer">
    <div class="footer__section footer__section--left">
        <ui-button
            id="interaction-exit-footer-landingpage-btn"
            type="default"
            svgIcon="arrow-alt-left"
            ui-theme="minimal"
            (click)="exit()"
            text="Exit"
            uiTooltip="To Bannerflow"
            uiTooltipTrigger="hover"
            uiTooltipPosition="top"
            [uiTooltipDelay]="0">
        </ui-button>
    </div>

    <div class="footer__section footer__section--center">
        <div class="footer__menu">
            <div
                class="footer__menuItem"
                [routerLink]="['./design']"
                [ngClass]="{'footer__menuItem--active': designRoute}"
                bfTooltip="Design view"
                bfTooltipPlacement="top">
                <i class="bf-icon bf-icon-view-design"></i>
                Design
            </div>
            <div
                [routerLink]="['./code']"
                class="footer__menuItem"
                routerLinkActive="footer__menuItem--active"
                bfTooltip="Code view"
                bfTooltipPlacement="top">
                <i class="bf-icon bf-icon-view-code"></i>
                Code
            </div>
        </div>
    </div>

    <div class="footer__section footer__section--right">
        <ui-button
            id="interaction-showcase-footer-landingpage-btn"
            type="default"
            ui-theme="minimal"
            svgIcon="playback-play"
            (click)="showcase()"
            text="Showcase"
            uiTooltip="Showcase version"
            uiTooltipTrigger="hover"
            uiTooltipPosition="top"
            [uiTooltipDelay]="0">
        </ui-button>
    </div>
</div>
