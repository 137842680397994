<div
    class="designView"
    [ngClass]="{ 'designView--sidebar' : appService.sideBarView === 1 }">
    <!--SIDEBAR-->
    <div class="designView__sidebar" *ngIf="appService.sideBarView === 1">
        <translationSidebar />
    </div>

    <!--MAIN VIEW (CANVAS)-->
    <div class="designView__editor">
        <designEditor />
    </div>
</div>
