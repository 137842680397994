import {
	Component,
	Input,
	Output,
	EventEmitter,
	ViewChild,
	ElementRef,
	Renderer2,
} from '@angular/core';
import { NgClass, NgFor, NgIf } from '@angular/common';

export class BFTagInputConfig {
	public placeholder: string;
	public validationFunc?: Function;

	constructor(config: Partial<BFTagInputConfig> = {}) {
		Object.assign(this, config);
	}
}

@Component({
	selector: 'bfTagInput',
	templateUrl: './bfTagInput.component.html',
	styleUrls: ['./bfTagInput.component.scss'],
	standalone: true,
	imports: [NgClass, NgFor, NgIf],
})
export class BFTagInputComponent {
	@Input() config: BFTagInputConfig;

	@Output() tagsChange = new EventEmitter();
	@Input()
	get tags() {
		return this._tagsValue;
	}

	set tags(val: string[]) {
		// map to new array so that changes to the internal model won't affect the provided array
		this._tagsValue = val ? val.map((t) => t) : [];

		// map to new array so that changes to the external model won't affect the internal array
		this.tagsChange.emit(this.tags.map((t) => t));
	}

	@ViewChild('tagInputTextBox', { static: true }) tagInputTextBox: ElementRef;
	@ViewChild('tagInput', { static: true }) tagInputElement: ElementRef;
	public isFocused: boolean;

	private focusListener: Function;
	private blurListener: Function;
	private keyDownListener: Function;

	// internal array for current tags
	private _tagsValue: string[];
	public isErasing: boolean = false;
	public isDisabled: boolean;

	constructor(private Renderer2: Renderer2) {
		this.config = new BFTagInputConfig();
	}

	public ngOnInit(): void {
		this.focusListener = this.Renderer2.listen(
			this.tagInputTextBox.nativeElement,
			'focus',
			() => {
				this.isFocused = true;
			},
		);
		this.blurListener = this.Renderer2.listen(
			this.tagInputTextBox.nativeElement,
			'blur',
			() => {
				this.isFocused = false;

				this.addTag(this.tagInputTextBox.nativeElement.value, true);

				this.tagInputTextBox.nativeElement.value = '';
			},
		);
		this.keyDownListener = this.Renderer2.listen(
			this.tagInputTextBox.nativeElement,
			'keydown',
			(event: KeyboardEvent) => {
				// If tab is pressed and text input has content, add tag
				if (
					event.keyCode === 9 &&
					this.tagInputTextBox.nativeElement.value
				) {
					this.addTag(this.tagInputTextBox.nativeElement.value);

					event.preventDefault();
					event.stopPropagation();
					return false;
				}
			},
		);
	}

	public ngOnDestroy(): void {
		// Remove focus/blur listeners
		if (this.focusListener) {
			this.focusListener();
		}
		if (this.blurListener) {
			this.blurListener();
		}
	}

	public addTag(tag: string, soft: boolean = false): void {
		this.isErasing = false;
		this.tagInputElement.nativeElement.classList.toggle(
			'bfTagInput--flashError',
			false,
		);

		if (!tag) {
			return;
		}

		if (!this.validate(tag)) {
			if (!soft) {
				setTimeout(() => {
					this.tagInputElement.nativeElement.classList.toggle(
						'bfTagInput--flashError',
						true,
					);
				}, 100);
			}

			return;
		}

		this._tagsValue.push(tag);

		// map and trigger change
		this.tags = this._tagsValue;

		this.tagInputTextBox.nativeElement.value = '';
	}

	public removeTag(index: number): void {
		this.isErasing = false;

		this._tagsValue.splice(index, 1);

		// map and trigger change
		this.tags = this._tagsValue;

		// Set focus to text input
		this.tagInputTextBox.nativeElement.focus();
	}

	private validate(tag: string): boolean {
		const tagExists: boolean = !this.tags.find((t) => t === tag);

		if (this.config.validationFunc) {
			return tagExists && this.config.validationFunc(tag);
		}

		return tagExists;
	}

	public erase(inputValue: string): void {
		if (inputValue) {
			return;
		}

		if (this.isErasing) {
			this._tagsValue.pop();
			this.tags = this._tagsValue;
		}

		this.isErasing = !this.isErasing;
	}
}
