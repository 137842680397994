import { ISerializable } from 'shared/interfaces/serializable';
import { IFile } from 'code/file.interface';
import { State } from 'shared/enums/state.enum';
import { FileType } from 'shared/enums/fileType.enum';
import { FolderModel } from './folder.model';

export class FileModel implements ISerializable<FileModel>, IFile {
	public id: string;
	public stringContent: string;
	public name: string;
	public sortName: string;
	public url: string;
	public state: State;
	public type: FileType;
	public contentType: string;
	public translatable: boolean;
	public files?: FileModel[];
	public folders?: FolderModel[];

	constructor(name: string = '') {
		this.setName(name);
	}

	public deserialize(data: any): FileModel {
		this.id = data.id;
		this.stringContent = data.stringContent;
		this.url = data.url;
		this.state = data.state;
		this.type = data.fileType;
		this.contentType = data.contentType;
		this.translatable = data.translatable;

		this.setName(data.name);

		return this;
	}

	public replaceWithImageData(data: any) {
		this.url = data.url;
		this.type = data.type;
		this.contentType = data.contentType;
	}

	public setStringContent(content: string): void {
		this.stringContent = content;
	}

	public updateStringContent(content: string): boolean {
		//Dirty fix to handle both \n and \r\n as same types of new lines when comparing
		let regex = /(\r\n|\r)/gm;
		let changed: boolean =
			content.replace(regex, '\n') !=
			this.stringContent.replace(regex, '\n');

		if (changed && this.state != State.New) {
			this.state = State.Modified;
		}

		this.stringContent = content;

		return changed;
	}

	public setName(name: string) {
		this.name = name;
		this.sortName = name + Math.random();
	}

	public getExtension(): string {
		return /[.]/.exec(this.name) ? /[^.]+$/.exec(this.name)[0] : '';
	}

	public isImage(): boolean {
		return this.type === FileType.Image;

		//var extension = this.getExtension();
		//if (!extension)
		//    return false;

		//var t = extension.toLowerCase().replace(/\./, '');
		//return t === "jpg" || t === "jpeg" || t === "gif" || t === "png" || t === "svg" || t === "bmp" || t === "tiff" || t === "ico";
	}

	public isMarkup(): boolean {
		if (!this.getExtension()) return false;

		var t = this.getExtension().toLowerCase().replace(/\./, '');
		return t === 'html' || t === 'htm' || t === 'xml' || t === 'txt';
	}

	public isScript(): boolean {
		if (!this.getExtension()) return false;

		var t = this.getExtension().toLowerCase().replace(/\./, '');
		return t === 'js' || t === 'vbs';
	}

	public isFont(): boolean {
		if (!this.getExtension()) return false;

		var t = this.getExtension().toLowerCase().replace(/\./, '');
		return (
			t === 'otf' ||
			t === 'ttf' ||
			t === 'woff' ||
			t === 'woff2' ||
			t === 'eot'
		);
	}

	public isStyleSheet(): boolean {
		if (!this.getExtension()) return false;

		var t = this.getExtension().toLowerCase().replace(/\./, '');
		return t === 'css' || t === 'less' || t === 'scss' || t === 'sass';
	}

	public getKind(): string {
		if (this.isImage()) return 'image';

		if (this.isMarkup()) return 'markup';

		if (this.isScript()) return 'script';
	}
}
