<div class="fileTabs">
    <div
        class="fileTabs__tab"
        *ngFor="let tab of tabs"
        [ngClass]="{ 'fileTabs__tab--active': tab === activeTab }"
        (click)="selectTab(tab)">
        <div class="fileTabs__tabInner">
            {{ tab.file.name || 'untitled' }}
            <i
                class="fileTabs__tabDirty"
                *ngIf="tab.file.state === State.Modified || tab.file.state === State.New"></i>
            <i
                class="fileTabs__tabClose bf-icon bf-icon-close-filled"
                (click)="closeTab($event, tab)"></i>
        </div>
    </div>
</div>
