// libs
import { EventEmitter } from '@angular/core';
import { BFInlineEditDirective } from '../../../../../../../libs/material/index';
import { FolderModel } from 'shared/models/folder.model';
import { State } from 'shared/enums/state.enum';
import { IFile } from 'code/file.interface';
import { CodeService } from 'code/services/code.service';
import { DocumentService } from 'code/services/document.service';
import { FileModel } from 'shared/models/file.model';
import {
	LPBConfirmDialogService,
	ModalType,
} from 'shared/services/lpb-confirm-dialog.service';
import { UIConfirmDialogResult } from '@bannerflow/ui';
import { MenuItemModel } from 'shared/models/menuItem.model';

export abstract class FileBrowserItem {
	public item: FileModel;
	public itemName: string;

	public depth: number = 0;
	public stateEnum = State;
	protected parentFolder: FolderModel;
	protected validName: boolean = true;
	protected invalidMessage;

	protected nameChange: EventEmitter<string> = new EventEmitter<string>();
	protected fileSelect: EventEmitter<IFile> = new EventEmitter<IFile>();
	public menuItems: MenuItemModel[];
	private inlineEditDirectiveRef: BFInlineEditDirective;

	constructor(
		protected codeService: CodeService,
		protected lpbDialogService: LPBConfirmDialogService,
		protected documentService: DocumentService,
	) {
		this.setInitialFileMenu();
	}

	protected setInitialFileMenu() {
		this.menuItems = [
			new MenuItemModel('Rename', () => this.rename()),
			new MenuItemModel('Delete', () => this.remove()),
		];
	}

	protected rename() {
		this.inlineEditDirectiveRef.startEdit();
	}

	protected initFileItem(inlineEditDirective: BFInlineEditDirective) {
		this.inlineEditDirectiveRef = inlineEditDirective;

		if (
			this.item.state === State.New &&
			inlineEditDirective &&
			this.item.name === ''
		) {
			this.rename();
		}
	}

	public onItemNameChange(value) {
		this.codeService.renameFile(this.item as FileModel, value);
	}

	protected onItemNameEdit(value) {
		//this.itemName = value;
		//http://stackoverflow.com/questions/23364395/regex-for-azure-blob-containers
		//this.validateName(value);
	}

	protected validateName = (value) => {
		let valid = this.isValidFileName(value);
		let unique = this.isUniqueFileName(value);

		if (!valid) {
			this.invalidMessage = 'Name is not valid';
		} else if (!unique) {
			this.invalidMessage = 'Name must be unique';
		} else {
			this.invalidMessage = '';
		}

		this.validName = valid && unique;

		return this.validName || this.invalidMessage;
	};

	protected isValidFileName(name?: string): boolean {
		return name && name.length > 0 && /^[\w.-]+$/.test(name);
	}

	protected isUniqueFileName(name: string): boolean {
		if (!this.parentFolder) return true;

		//Name can never be index.html
		if (name.toLowerCase() == 'index.html') return false;

		//Validate this is not called the same as another file in parent
		for (let file of this.parentFolder.files) {
			if (file.state !== State.Deleted) {
				if (
					this.item !== file &&
					file.name.toLowerCase() === name.toLowerCase()
				) {
					return false;
				}
			}
		}

		// WE DON'T HAVE FOLDERS ANYMORE
		// Validate this is not called the same as another folder in parent
		// for (let folder of this.parentFolder.folders) {
		//     if (folder.state !== State.Deleted) {
		//         if (this.item !== folder && folder.name.toLowerCase() === name.toLowerCase()) {
		//             return false;
		//         }
		//     }
		// }

		return true;
	}

	protected onAbort() {
		if (this.item.state === State.New && !this.item.name) {
			this.codeService.removeFile(this.item);
		}
		this.validName = true;
	}

	protected async remove() {
		const headerText = this.item.name
			? `Delete '${this.item.name}'`
			: 'Delete file';
		const message = this.item.name
			? `Are you sure you want to delete '${this.item.name}' ? Please note, that you need to save to make the changes take effect.`
			: 'Are you sure you want to delete this file? Please note, that you need to save to make the changes take effect.';

		const configKey = ModalType.deleteFileConfig;
		const dialogConfig = {
			...this.lpbDialogService[configKey],
			headerText,
			text: message,
		};

		const confirmResult: UIConfirmDialogResult =
			await this.lpbDialogService.showDialogWithMessages(
				[dialogConfig.text],
				ModalType.deleteFileConfig,
			);

		if (confirmResult === 'confirm') {
			try {
				this.codeService.removeFile(this.item);
			} catch (err) {
				return;
			}
		}
	}
}
