// libs
import { Injectable, HostListener, Inject } from '@angular/core';
import Mousetrap from 'mousetrap';

@Injectable({ providedIn: 'root' })
export class HotkeysService {
	private mousetrap: any;

	constructor() {
		this.mousetrap = new Mousetrap(document.body);
	}

	/**
	 * Bind a key or key combination to a function.
	 * @param key The key or key combination. Can be multiple. E.g. let keys = ['ctrl+s', 'command+s'].
	 * @param func The function that will be run when the user enters any of the provided key combinations.
	 */
	public bindKey(key: string[], func: Function): void {
		this.mousetrap.bind(key, () => {
			func();

			return false;
		});
	}

	public bindKeyOnElement(key: string[], element: any, func: any): void {
		Mousetrap(element).bind(key, func);
	}

	/**
	 * Triggers bound key combinations manually
	 * @param key The key or key combination. E.g 'ctrl+s'
	 */
	public trigger(key: string): void {
		this.mousetrap.trigger(key);
	}
}
