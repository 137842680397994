export class SelectItemModel {
	public label: string | undefined;
	public value: any;
	public onSelect: Function;
	public isSeperator: boolean | undefined;
	public disabled: boolean = false;
	public flag: string;
	public icon: string;
	public selected: boolean;
	public hidden: boolean = false;
	public description: string | undefined;

	constructor(label?: string, value?: any, isSeperator?: boolean) {
		this.label = label;
		this.value = value;
		this.isSeperator = isSeperator;
	}
}
