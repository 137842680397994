import { BFMaterial } from '../../../libs/material';
import { Component, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { UserService } from 'shared/services/user.service';
import { TextService } from 'shared/services/text.service';
import { LandingPageService } from 'shared/services/landingPage.service';

@Component({
	selector: 'translationMainView',

	templateUrl: 'translationMain.view.component.html',
	standalone: true,
	imports: [RouterOutlet],
})
export class TranslationMainViewComponent {
	constructor(
		private bfMaterial: BFMaterial,
		private viewContainerRef: ViewContainerRef,
		private userService: UserService,
		private activatedRoute: ActivatedRoute,
		private textService: TextService,
		private landingPageService: LandingPageService,
	) {
		this.activatedRoute.params.subscribe((params: any) => {
			this.userService.init(params.accountSlug, params.brandId, true);
			this.landingPageService.init(
				params.landingPageId,
				`load?translationId=${params.translationId}&translatorId=${params.translatorId}&landingPageId=${params.landingPageId}`,
			);
			this.textService.init(params.translationId);
		});

		this.bfMaterial.init(viewContainerRef);
	}
}
