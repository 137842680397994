<div
    id="inspector"
    class="inspector inspector--open inspector--{{invertedLayout ? 'inverted' : ''}} inspector--{{currentCategory}}">
    <!-- LEFT PANEL -->
    <div class="inspector__leftPanel">
        <div
            class="inspector__category"
            (mousedown)="selectCategory(categories.text, $event)">
            <i class="bf-icon bf-icon-fw bf-icon-bold"></i>
        </div>
        <div
            class="inspector__category"
            (mousedown)="selectCategory(categories.paragraph, $event)">
            <i class="bf-icon bf-icon-fw bf-icon-list"></i>
        </div>
        <div
            class="inspector__category"
            (mousedown)="selectCategory(categories.color, $event)">
            <i
                class="inspector__categoryColor"
                [style.backgroundColor]="currentColor"></i>
        </div>
        <div
            class="inspector__category"
            (mousedown)="selectCategory(categories.link, $event)">
            <i class="bf-icon bf-icon-fw bf-icon-link"></i>
        </div>
        <div
            class="inspector__category"
            (mousedown)="selectCategory(categories.removeFormat, $event);">
            <i class="bf-icon bf-icon-fw bf-icon-clear-format"></i>
        </div>
        <!--<div class="inspector__category inspector__categoryHelp" (mousedown)="selectCategory(categories.help, $event)">
            ?
        </div>-->
    </div>

    <!-- RIGHT PANEL -->
    <div class="inspector__rightPanel" *ngIf="currentCategory">
        <div class="inspector__arrow inspector__arrow--{{currentCategory}}"></div>

        <div
            class="inspector__rightPanelClose"
            (mousedown)="selectCategory(null, $event)">
            <ui-svg-icon class="icon-close" icon="close" />
        </div>

        <!-- TEXT CATEGORY -->
        <div
            class="inspector__rightPanelCategory"
            *ngIf="currentCategory === categories.text">
            <h4 class="inspector__rightPanelHeadline">Text</h4>

            <!-- WEIGHT -->
            <div class="inspector__group">
                <div class="inspector__label">Weight</div>
                <div class="inspector__item">
                    <div
                        class="inspector__toggle"
                        (mousedown)="toggleCommand(command.Bold, $event)"
                        [ngClass]="{'inspector__toggle--active': scribe.bold}">
                        <i class="bf-icon bf-icon-fw bf-icon-bold"></i>
                    </div>
                </div>
                <div class="inspector__item">
                    <div
                        class="inspector__toggle"
                        (mousedown)="toggleCommand(command.Italic, $event)"
                        [ngClass]="{'inspector__toggle--active': scribe.italic}">
                        <i class="bf-icon bf-icon-fw bf-icon-italic"></i>
                    </div>
                </div>
            </div>

            <!-- Decoration -->
            <div class="inspector__group">
                <div class="inspector__label">Decoration</div>
                <div class="inspector__item">
                    <div
                        class="inspector__toggle"
                        (mousedown)="toggleCommand(command.Underline, $event)"
                        [ngClass]="{'inspector__toggle--active': scribe.underline}">
                        <i class="bf-icon bf-icon-fw bf-icon-underline"></i>
                    </div>
                </div>
                <div class="inspector__item">
                    <div
                        class="inspector__toggle"
                        (mousedown)="toggleCommand(command.StrikeThrough, $event)"
                        [ngClass]="{'inspector__toggle--active': scribe.strikeThrough}">
                        <i class="bf-icon bf-icon-fw bf-icon-strikethrough"></i>
                    </div>
                </div>
            </div>

            <!-- SUPER/SUB -->
            <div class="inspector__group">
                <div class="inspector__label">Super/Sub</div>
                <div class="inspector__item">
                    <div
                        class="inspector__toggle"
                        (mousedown)="toggleCommand(command.Superscript, $event)"
                        [ngClass]="{'inspector__toggle--active': scribe.superscript}">
                        <i class="bf-icon bf-icon-fw bf-icon-superscript"></i>
                    </div>
                </div>
                <div class="inspector__item">
                    <div
                        class="inspector__toggle"
                        (mousedown)="toggleCommand(command.Subscript, $event)"
                        [ngClass]="{'inspector__toggle--active': scribe.subscript}">
                        <i class="bf-icon bf-icon-fw bf-icon-subscript"></i>
                    </div>
                </div>
            </div>
        </div>

        <!-- PARAGRAPH CATEGORY -->
        <div
            class="inspector__rightPanelCategory"
            *ngIf="currentCategory === categories.paragraph">
            <h4 class="inspector__rightPanelHeadline">Paragraph</h4>

            <!-- ALIGN (CANT BE SAVED AT THE MOMENT) -->
            <div class="inspector__group" *ngIf="scribe.allowBlockElements">
                <div class="inspector__label">Align</div>
                <div class="inspector__item">
                    <div
                        class="inspector__toggle"
                        (mousedown)="toggleCommand(command.AlignLeft, $event)"
                        [ngClass]="{'inspector__toggle--active': scribe.alignLeft}">
                        <i class="bf-icon bf-icon-fw bf-icon-paragraph-left"></i>
                    </div>
                </div>
                <div class="inspector__item">
                    <div
                        class="inspector__toggle"
                        (mousedown)="toggleCommand(command.AlignCenter, $event)"
                        [ngClass]="{'inspector__toggle--active': scribe.alignCenter}">
                        <i class="bf-icon bf-icon-fw bf-icon-paragraph-center"></i>
                    </div>
                </div>
                <div class="inspector__item">
                    <div
                        class="inspector__toggle"
                        (mousedown)="toggleCommand(command.AlignRight, $event)"
                        [ngClass]="{'inspector__toggle--active': scribe.alignRight}">
                        <i class="bf-icon bf-icon-fw bf-icon-paragraph-right"></i>
                    </div>
                </div>
            </div>

            <!-- LIST -->
            <div class="inspector__group" *ngIf="scribe.allowBlockElements">
                <div class="inspector__label">List</div>
                <div class="inspector__item">
                    <div
                        class="inspector__toggle"
                        (mousedown)="toggleCommand(command.List, $event)"
                        [ngClass]="{'inspector__toggle--active': scribe.list}">
                        <i class="bf-icon bf-icon-fw bf-icon-list"></i>
                    </div>
                </div>
                <div class="inspector__item">
                    <div
                        class="inspector__toggle"
                        (mousedown)="toggleCommand(command.OrderedList, $event)"
                        [ngClass]="{'inspector__toggle--active': scribe.orderedList}">
                        <i class="bf-icon bf-icon-fw bf-icon-list-ordered"></i>
                    </div>
                </div>
            </div>
            <div class="inspector__groupInfo" *ngIf="!scribe.allowBlockElements">
                Lists and align can't be applied on this tag. To enable change tag to a
                div tag
            </div>
        </div>

        <!-- COLOR CATEGORY -->
        <div
            class="inspector__rightPanelCategory"
            *ngIf="currentCategory === categories.color">
            <h4 class="inspector__rightPanelHeadline">Color</h4>

            <!-- HEX INPUT -->
            <div class="inspector__group">
                <div class="inspector__label">Hex</div>
                <div class="inspector__groupContent inspector__colorInputText">
                    <ui-input
                        class="inspector__inputText"
                        placeholder="Hex"
                        [(ngModel)]="currentColor"
                        (mousedown)="$event.stopPropagation()"
                        (keyup)="updateTextColor($event)" />

                    <ui-button
                        id="interaction-toggle-color-inspector-landingpage-btn"
                        type="primary"
                        ui-theme="minimal"
                        (click)="toggleColorMode($event)"
                        [text]="showMoreColors ? 'Less' : 'More'">
                    </ui-button>
                </div>
            </div>

            <!-- COLOR PICKER -->
            <div class="inspector__group" *ngIf="showMoreColors">
                <div class="inspector__label">Picker</div>
                <div class="inspector__colorPicker">
                    <colorPicker
                        [config]="colorPickerConfig"
                        [(color)]="currentColor"
                        (colorChange)="updateTextColor()"></colorPicker>
                </div>
            </div>

            <!-- SWATCHES -->
            <div class="inspector__group" *ngIf="!showMoreColors">
                <div class="inspector__label">Swatches</div>
                <div class="inspector__groupContent">
                    <swatches
                        [(color)]="currentColor"
                        (colorChange)="updateTextColor()"></swatches>
                </div>
            </div>
        </div>

        <!-- LINK CATEGORY -->
        <div
            class="inspector__rightPanelCategory"
            *ngIf="currentCategory === categories.link">
            <h4 class="inspector__rightPanelHeadline">Link</h4>
            <div class="inspector__group">
                <ui-input
                    #linkInput
                    class="inspector__inputText"
                    placeholder="Link address"
                    [(value)]="linkUrl" />
            </div>
            <div class="inspector__group inspector__button">
                <ui-button
                    id="interaction-create-link-inspector-landingpage-btn"
                    type="primary"
                    svgIcon="link"
                    (click)="createLink($event)"
                    [text]="!scribe.link ? 'Create link': 'Update link'">
                </ui-button>
            </div>
            <div class="inspector__group inspector__button">
                <a (mousedown)="clearLink($event)" class="inspector__clear">
                    <ui-svg-icon icon="clear" class="inspector__clearIcon" />
                    Clear link
                </a>
            </div>
        </div>

        <!-- CLEAR FORMAT CATEGORY -->
        <div
            class="inspector__rightPanelCategory"
            *ngIf="currentCategory === categories.removeFormat">
            <h4 class="inspector__rightPanelHeadline">Remove format</h4>
            <p class="inspector__paragraph">
                This magical button will remove all formatting and reset paragraph
                settings, remove your link and set the color to default styling. Are you
                sure?
            </p>
            <div class="inspector__group inspector__button">
                <ui-button
                    id="interaction-clear-format-inspector-landingpage-btn"
                    type="default"
                    svgIcon="clear"
                    (click)="clearFormat()"
                    text="Remove formatting">
                </ui-button>
            </div>
        </div>

        <!-- HELP CATEGORY -->
        <div
            class="inspector__rightPanelCategory"
            *ngIf="currentCategory === categories.help">
            <h4 class="inspector__rightPanelHeadline">
                Just another help text about a text object.
            </h4>
            <p class="inspector__paragraph">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec euismod
                erat ac laoreet vulputate. Duis rutrum tortor id tellus ultrices iaculis.
                Suspendisse in tortor erat. Suspendisse ac ante metus. Duis mauris urna,
                suscipit sed interdum a, ullamcorper sit amet tellus. Curabitur rhoncus
                gravida metus, non sodales leo efficitur sit amet. Nam condimentum lacus
                urna, nec finibus urna ullamcorper vitae. Phasellus ultricies tempor
                nulla. Cras tristique nibh quis ipsum elementum, tincidunt blandit dui
                posuere. Morbi at justo sit amet tortor consectetur consequat in lacinia
                lectus. Donec tincidunt lobortis sapien, non pulvinar orci sodales sed.
                Etiam pretium facilisis ullamcorper. Donec laoreet ex vel sem dignissim
                accumsan.
            </p>
        </div>
    </div>
</div>
