import { ISerializable } from 'shared/interfaces/serializable';
import { FileModel } from 'shared/models/file.model';
import { FolderModel } from 'shared/models/folder.model';
import { TranslationModel } from 'shared/models/translation.model';
import { PublishState } from 'shared/enums/publishState.enum';

export class LandingPageModel implements ISerializable<LandingPageModel> {
	public id: string;
	public name: string;
	public indexFile: FileModel;
	public rootFolder: FolderModel;
	public accountSlug: string;
	public brandId: string;
	public originalTranslation: TranslationModel;
	public translations: TranslationModel[] = [];
	public publishState: PublishState;
	public lastPublished: Date;
	public lastUnPublished: Date;
	public userLoggedIn: boolean;
	public landingPageSlug: string;
	public isLocked: boolean;

	public deserialize(data: any): LandingPageModel {
		this.id = data.id;
		this.name = data.name;
		if (data.indexFile) {
			this.indexFile = new FileModel().deserialize(data.indexFile);
		}
		if (data.rootFolder) {
			this.rootFolder = new FolderModel().deserialize(data.rootFolder);
		}
		this.userLoggedIn = data.userLoggedIn;
		this.accountSlug = data.accountSlug;
		this.brandId = data.brandId;
		this.originalTranslation = new TranslationModel().deserialize(
			data.originalTranslation,
		);
		this.publishState = data.publishState;
		this.lastPublished = new Date(data.lastPublished);
		this.lastUnPublished = new Date(data.lastUnPublished);
		this.landingPageSlug = data.landingPageSlug;
		this.isLocked = data.isLocked;

		if (data.translations)
			data.translations.forEach((translation) => {
				this.translations.push(
					new TranslationModel().deserialize(translation),
				);
			});
		return this;
	}
}
