define(function () {
	'use strict';

	return function (scribe) {
		function Command(commandName) {
			this.commandName = commandName;
			this.patch = scribe.commandPatches[this.commandName];
		}

		var rootDoc = scribe.el.ownerDocument;
		var nodeHelpers = scribe.node;

		// find the parent document or document fragment
		if (
			rootDoc.compareDocumentPosition(scribe.el) &
			Node.DOCUMENT_POSITION_DISCONNECTED
		) {
			var currentElement = scribe.el.parentNode;
			while (currentElement && nodeHelpers.isFragment(currentElement)) {
				currentElement = currentElement.parentNode;
			}

			// if we found a document fragment and it has a getSelection method, set it to the root doc
			if (currentElement && currentElement.getSelection) {
				rootDoc = currentElement;
			}
		}

		Command.prototype.execute = function (value) {
			if (this.patch) {
				this.patch.execute(value);
			} else {
				scribe.transactionManager.run(
					function () {
						rootDoc.execCommand(
							this.commandName,
							false,
							value || null,
						);
					}.bind(this),
				);
			}
		};

		Command.prototype.queryState = function () {
			if (this.patch) {
				return this.patch.queryState();
			} else {
				return rootDoc.queryCommandState(this.commandName);
			}
		};

		Command.prototype.queryValue = function () {
			if (this.patch && this.patch.queryValue) {
				return this.patch.queryValue();
			} else {
				return rootDoc.queryCommandValue(this.commandName);
			}
		};

		Command.prototype.queryEnabled = function () {
			if (this.patch) {
				return this.patch.queryEnabled();
			} else {
				return rootDoc.queryCommandEnabled(this.commandName);
			}
		};

		return Command;
	};
});
