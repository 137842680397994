import {
	Injectable,
	Component,
	ComponentFactoryResolver,
	ViewContainerRef,
	Injector,
	ComponentRef,
} from '@angular/core';
import { BFMaterial } from '../bfMaterial';
import * as _ from 'underscore';

@Injectable({ providedIn: 'root' })
export class BFComponentContainer {
	public componentRefs: any[] = [];

	constructor(
		private componentFactoryResolver: ComponentFactoryResolver,
		private bFMaterial: BFMaterial,
	) {}

	public attach<T>(
		component: BFComponentType<T>,
		viewContainerRef?: ViewContainerRef,
	): ComponentRef<T> {
		//If no viewcontainer is specified, use bfmaterials root view container
		if (viewContainerRef === undefined) {
			viewContainerRef =
				this.bFMaterial.rootViewContainerRef || undefined;
		}

		let componentFactory =
			this.componentFactoryResolver.resolveComponentFactory(component);
		let ref = viewContainerRef.createComponent(
			componentFactory,
			viewContainerRef.length,
			viewContainerRef.parentInjector,
		);

		this.componentRefs.push(ref);

		return ref;
	}

	public detach<T>(component: ComponentRef<T>): void {
		let index = this.componentRefs.indexOf(component);
		if (index != -1) {
			this.componentRefs.splice(index, 1);
			component.destroy();
		}
	}

	public clear(): void {
		for (var i = this.componentRefs.length - 1; i >= 0; i--) {
			this.detach(this.componentRefs[i]);
		}
	}

	public getCount(): number {
		return this.componentRefs.length;
	}
}

export interface BFComponentType<T> {
	new (...args: any[]): T;
}
