define([], function () {
	'use strict';
	return function () {
		return function (scribe) {
			var insertHTMLCommandPatch = new scribe.api.CommandPatch(
				'insertHTML',
			);
			var nodeHelpers = scribe.node;

			insertHTMLCommandPatch.execute = function (value) {
				scribe.transactionManager.run(
					function () {
						scribe.api.CommandPatch.prototype.execute.call(
							this,
							value,
						);
						nodeHelpers.removeChromeArtifacts(scribe.el);
					}.bind(this),
				);
			};

			scribe.commandPatches.insertHTML = insertHTMLCommandPatch;
		};
	};
});
