import { ISerializable } from 'shared/interfaces/serializable';
import { State } from 'shared/enums/state.enum';

export class ResourceTranslationModel
	implements ISerializable<ResourceTranslationModel>
{
	public originalResourceId: string;
	public resourceId: string;
	public url: string;
	public state: State;
	public name: string;

	public deserialize(data: any): ResourceTranslationModel {
		this.name = data.name;
		this.originalResourceId = data.originalResourceId;
		this.resourceId = data.resourceId;
		this.url = data.url;
		this.state = data.state;
		return this;
	}
}
