import { Component } from '@angular/core';
import { RulerComponent } from './ruler/ruler.component';
import { ArtboardComponent } from './artboard/artboard.component';

@Component({
	styleUrls: ['designEditor.component.scss'],
	selector: 'designEditor',
	templateUrl: 'designEditor.component.html',
	standalone: true,
	imports: [RulerComponent, ArtboardComponent],
})
export class DesignEditorComponent {}
