import {
	Component,
	OnInit,
	Renderer2,
	Input,
	ViewChild,
	ElementRef,
} from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
	styleUrls: ['ruler.component.scss'],
	selector: 'ruler',
	templateUrl: 'ruler.component.html',
	standalone: true,
	imports: [NgClass],
})
export class RulerComponent implements OnInit {
	@Input('targetElement') private targetElement: Element;
	@Input('vertical') public vertical: boolean;
	@ViewChild('rulerCanvas', { static: true }) private rulerCanvas: ElementRef;

	constructor() {}

	ngOnInit() {
		if (this.rulerCanvas?.nativeElement) {
			this.drawRuler(this.rulerCanvas.nativeElement);
		}
	}

	private drawRuler(canvas: HTMLCanvasElement) {
		let lineColor: string = '#dadada';
		let textColor: string = '#bbb';
		let scale = 2; //Scaling due to DPI (retina)
		let ticks = 10; //Small ticks
		let highlight = 100; //Large ticks
		let zoom = 1;
		let canvasThickness = 20 * scale;
		let canvasLength = 3000 * scale;
		let width = this.vertical ? canvasThickness : canvasLength;
		let height = this.vertical ? canvasLength : canvasThickness;
		let CANVAS_RULER_OFFSET: number = -1500; //CHANGE THIS
		let context = canvas.getContext('2d');

		//Retina hack
		canvas.setAttribute('width', width.toString());
		canvas.setAttribute('height', height.toString());
		canvas.style.width = width / scale + 'px';
		canvas.style.height = height / scale + 'px';

		//Styling
		context.clearRect(0, 0, width, height);
		context.fillStyle = textColor;
		context.textAlign = this.vertical ? 'right' : 'left';
		context.font =
			'normal 20px -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif';

		//Rotate the context if vertical
		if (this.vertical) {
			context.rotate(this.vertical ? -Math.PI / 2 : 0);
			context.translate(-canvasLength, 0);
		}

		//Text offset from main line
		let textOffset: number = 3 * (this.vertical ? -1 : 1) * scale;

		//Loop all lines
		let x: number = ticks * scale;
		while (x < canvasLength) {
			//Highlight this tick
			if (x % (highlight * scale) === 0) {
				//Uncomment to get numbers
				//let text:string = Math.floor((this.vertical ? -x / scale - CANVAS_RULER_OFFSET : x / scale + CANVAS_RULER_OFFSET) / zoom).toString();
				//context.fillText(text, x + textOffset, canvasThickness / 2 - 0);
				this.drawLine(x, canvasThickness, x, 0, lineColor, context);
			}
			//Normal tick
			else {
				this.drawLine(
					x,
					canvasThickness,
					x,
					(2 * canvasThickness) / 3,
					lineColor,
					context,
				);
			}
			x += ticks * scale;
		}
	}
	private drawLine(
		x1: number,
		y1: number,
		x2: number,
		y2: number,
		lineColor: string,
		context,
	) {
		context.lineWidth = 2;
		context.strokeStyle = lineColor;
		context.beginPath();
		context.moveTo(x1 + 0.5, y1 + 0.5);
		context.lineTo(x2 + 0.5, y2 + 0.5);
		context.stroke();
	}
}
