import { Environment } from './environment.type';

export const environment: Environment = {
	NAME: 'sandbox',

	LOGIN_URL: 'https://sandbox-login.bannerflow.com',
	CLIENT_ID: 'ScsUZI90C4QT0YVTnhlXk0SWWv2CWgwg',
	API_URL: 'https://sandbox-api.bannerflow.com/landing-page-builder',
	HOME_URL: 'https://sandbox-home.bannerflow.com',
	ARTBOARD_BASE_PATH: 'landing-page-builder',

	NEW_RELIC_ACCOUNT_ID: '4232543',
	NEW_RELIC_APPLICATION_ID: '538568985',
	NEW_RELIC_LICENSE_KEY: 'NRJS-d0b27a9b958bc4b281c',
	NEW_RELIC_TRUST_KEY: '4122654',
	RELEASE_NAME: '',
	VERSION: '',
};
