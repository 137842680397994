// libs
import { Injectable, EventEmitter } from '@angular/core';

import {
	BFServerResponse,
	BFNotificationService,
} from '../../../libs/material/index';
import { LandingPageModel } from 'shared/models/landingPage.model';
import { HttpClient } from '@angular/common/http';
import { UserService } from 'shared/services/user.service';
import { ApiService } from 'shared/services/api.service';
import { Router } from '@angular/router';
import { FileModel } from 'shared/models/file.model';
import { State } from 'shared/enums/state.enum';
import { TranslationModel } from 'shared/models/translation.model';
import { PublishState } from 'shared/enums/publishState.enum';

// app

@Injectable({ providedIn: 'root' })
export class LandingPageService {
	private _loadPromise: Promise<LandingPageModel>;
	private landingPageId: string;
	private onKeyUp: any;

	// Events
	public landingPageSaved: EventEmitter<any> = new EventEmitter<any>();

	// TODO: Move to translation service
	public translationsChange: EventEmitter<any> = new EventEmitter<any>();

	public dirty: boolean;
	public dirtyPreview: boolean;
	private onExit: any;
	private isShowcase: boolean;

	private translatorController: string;

	constructor(
		private readonly http: HttpClient,
		private readonly userService: UserService,
		private readonly apiService: ApiService,
		private readonly notificationService: BFNotificationService,
		private readonly router: Router,
	) {}

	public init(
		landingPageId: string,
		translatorController: string = null,
		isShowcase = false,
	): void {
		this.landingPageId = landingPageId;
		this.translatorController = translatorController;
		this.isShowcase = isShowcase;

		//When editor is closing regardless reason (notice that a regular eventlistener won't do)
		window.onbeforeunload = (e) => {
			if (this.dirty) {
				return 'You have unsaved changes, are you sure you want to close the editor?';
			}
		};
	}

	public get(): Promise<LandingPageModel> {
		if (!this._loadPromise) {
			this._loadPromise = new Promise<LandingPageModel>(
				(resolve: Function, reject: Function) => {
					if (this.isShowcase) {
						this.apiService
							.get('landingpage', `${this.landingPageId}/info`)
							.then((landingPage: any) => {
								resolve(
									new LandingPageModel().deserialize(
										landingPage,
									),
								);
							})
							.catch((err) => {
								console.error(err);
								//window.location.replace('/notfound');
							});
					} else if (this.translatorController) {
						this.apiService
							.get(
								'externalTranslation',
								this.translatorController,
							)
							.then((landingPage: any) => {
								resolve(
									new LandingPageModel().deserialize(
										landingPage,
									),
								);
							})
							.catch(() => {
								window.location.replace('/notfound');
							});
					} else {
						this.apiService
							.get(
								'landingpage',
								`load?landingPageId=${this.landingPageId}`,
							)
							.then((landingPage: any) => {
								resolve(
									new LandingPageModel().deserialize(
										landingPage,
									),
								);
							})
							.catch((err) => {
								console.error(err);
								window.location.replace('/notfound');
							});
					}
				},
			);
		}

		return this._loadPromise;
	}

	public save(): Promise<LandingPageModel> {
		return new Promise<LandingPageModel>((resolve, reject) => {
			this._loadPromise.then((landingPage: LandingPageModel) => {
				// create a dictionary with all new files. this is done so they can be
				// identified by name even if this is changed during the save request
				let newFilesDictionary = {};
				if (landingPage.rootFolder.files) {
					landingPage.rootFolder.files.forEach((file: FileModel) => {
						if (file.state === State.New) {
							newFilesDictionary[file.name] = file;
						}
					});
				}

				this.apiService
					.post('landingpage', 'save', landingPage)
					.then((updatedLandingPage: any) => {
						let savedLandingPage =
							new LandingPageModel().deserialize(
								updatedLandingPage,
							);

						// use the dictionary to find the new files and update their id
						if (savedLandingPage.rootFolder.files) {
							savedLandingPage.rootFolder.files.forEach(
								(savedFile: FileModel) => {
									let existingFile =
										newFilesDictionary[savedFile.name];
									if (existingFile && !existingFile.id)
										existingFile.id = savedFile.id;
								},
							);
						}
						resolve(savedLandingPage);
						landingPage.originalTranslation.publishState =
							savedLandingPage.originalTranslation.publishState;
						if (savedLandingPage.translations) {
							for (var t of savedLandingPage.translations) {
								const landingPageTranslation =
									landingPage.translations.find(
										(tr) => tr.id === t.id,
									);
								landingPageTranslation.publishState =
									t.publishState;
							}
						}
						this.toggleDirty(false);
						this.landingPageSaved.emit(true);
						//Show notification
						this.notificationService.show(
							'Your landing page has been saved!',
							'success',
							'top',
							undefined,
							'finished',
						);
					})
					.catch((response: any) => {
						console.error(response);
						// Show notification
						this.notificationService.show(
							'Something went wrong when saving your landing page. Please try again.',
							'error',
							'top',
							undefined,
							'alert',
						);

						reject(response);
					});
			});
		});
	}

	public async setOriginalTranslation(
		newOriginalTranslationId: string,
	): Promise<LandingPageModel> {
		return new Promise<LandingPageModel>((resolve) => {
			this._loadPromise.then((landingPage) => {
				return this.apiService
					.patch(
						'landingpage',
						`setoriginaltranslation?landingPageId=${landingPage.id}&newOriginalTranslationId=${newOriginalTranslationId}`,
					)
					.then((patchedLandingPage) => {
						let lp = new LandingPageModel().deserialize(
							patchedLandingPage,
						);
						landingPage.originalTranslation =
							lp.originalTranslation;
						landingPage.translations = lp.translations;
						resolve(landingPage);
					});
			});
		});
	}

	public addTranslations(translations: TranslationModel[]): void {
		this._loadPromise.then((landingPage: LandingPageModel) => {
			landingPage.translations.push(...translations);
			landingPage.publishState =
				landingPage.publishState === PublishState.Published
					? PublishState.PublishedWithPendingUpdates
					: landingPage.publishState;
			this.translationsChange.emit(true);
		});
	}

	// TODO: move
	public getFileContent(
		accountSlug: string,
		brandId: string,
		resourceId: string,
	): Promise<string> {
		return this.apiService.get<string>(
			'landingpage',
			`getresourcecontent?resourceId=${resourceId}`,
		);
	}

	public toggleDirty(dirty: boolean, dirtyPreview: boolean = true): void {
		this._loadPromise.then((landingPage: LandingPageModel) => {
			if (dirty) {
				this.dirty = true;
				this.dirtyPreview = dirtyPreview;
			} else {
				landingPage.indexFile.state = State.None;
				landingPage.rootFolder.files.forEach((file: FileModel) => {
					file.state =
						file.state == State.Deleted
							? State.Deleted
							: State.None;
				});
				this.dirty = false;
				this.dirtyPreview = false;
			}
		});
	}
}
