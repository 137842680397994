<div
    class="fileBrowser__file fileBrowser__file--indent{{depth}}"
    [ngClass]="{ 'fileBrowser__file--selected' : isActive }"
    *ngIf="stateEnum.Deleted !== item.state"
    (click)="onFileSelect($event)">
    <i
        class="bf-icon bf-icon-fw"
        [ngClass]="{ 'bf-icon-file' : !item.isImage(), 'bf-icon-file-image' : item.isImage() }"></i>
    <i
        class="fileBrowser__localizedFileIcon bf-icon bf-icon-globe"
        *ngIf="item.translatable"></i>
    <div
        class="fileBrowser__fileNameContainer"
        (contextmenu)="onRightClick($event, fileMenu)">
        <div
            #bfInlineEditReference="bfInlineEdit"
            *ngIf="!locked"
            [(bfInlineEdit)]="itemName"
            [bfInlineEditValidation]="validateName"
            (bfInlineEditAbort)="onAbort()"
            (bfInlineEditDone)="onItemNameChange($event)"
            (bfInlineEditEdit)="onItemNameEdit($event)"
            [bfInlineEditPreventClickToEdit]="true"
            class="fileBrowser__fileName"></div>
        <span class="fileBrowser__fileNameSpan" *ngIf="locked">{{item.name}}</span>
        <div class="fileBrowser__fileNameError" *ngIf="!validName">
            {{invalidMessage}}
        </div>
    </div>

    <span class="fileBrowser__fileButtons dots" *ngIf="!locked">
        <ui-svg-icon
            class="dots-icon"
            icon="kebab"
            (click)="positionDropdown($event, fileMenu)" />
    </span>

    <i
        class="fileBrowser__fileNew"
        *ngIf="stateEnum.New === item.state || stateEnum.Modified === item.state"></i>
</div>

<ui-dropdown
    #fileMenu
    theme="dark"
    [maxHeight]="250"
    [positions]="[{
        originX: 'center',
        originY: 'bottom',
        overlayX: 'center',
        overlayY: 'top'
    }]">
    <ng-container *ngFor="let menuItem of menuItems">
        <ng-container *ngIf="menuItem.label !== ''">
            <ui-dropdown-item
                [disabled]="(stateEnum.New === item.state) && menuItem.label === 'Download'"
                id="interaction-size"
                (click)="handleDropdownSelect(menuItem)">
                <span class="fileBrowserMenu__label" [innerHTML]="menuItem.label"></span>
            </ui-dropdown-item>
        </ng-container>
        <div *ngIf="menuItem.label === ''" class="dropdown-divider"></div>
    </ng-container>
</ui-dropdown>
