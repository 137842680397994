import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class BFUtilsService {
	constructor() {}

	static throttle = function (
		func: Function,
		wait: number,
		options: any = { leading: true },
	) {
		let context: any, args: any, result: any;
		let timeout: any = null;
		let previous = 0;
		if (!options) options = {};
		let later = function () {
			previous = options.leading === false ? 0 : Date.now();
			timeout = null;
			result = func.apply(context, args);
			if (!timeout) context = args = null;
		};
		return function () {
			let now = Date.now();
			if (!previous && options.leading === false) previous = now;
			let remaining = wait - (now - previous);
			context = this;
			args = arguments;
			if (remaining <= 0 || remaining > wait) {
				if (timeout) {
					clearTimeout(timeout);
					timeout = null;
				}
				previous = now;
				result = func.apply(context, args);
				if (!timeout) context = args = null;
			} else if (!timeout && options.trailing !== false) {
				timeout = setTimeout(later, remaining);
			}
			return result;
		};
	};

	static getScrollBarWidth(): number {
		let inner = jQuery('<p>');
		inner.width('100%');
		inner.height('200px');

		let outer = jQuery('<div>');
		outer.css({
			position: 'absolute',
			top: '0px',
			left: '0px',
			visibility: 'hidden',
			width: '200px',
			height: '150px',
			overflow: 'hidden',
		});

		outer.append(inner);

		jQuery('body').append(outer);
		let w1 = inner[0].offsetWidth;
		outer.css({ overflow: 'scroll' });
		let w2 = inner[0].offsetWidth;
		if (w1 == w2) w2 = outer[0].clientWidth;

		outer.remove();

		return w1 - w2;
	}

	public static toggleBodyOverflow(overflow: boolean): void {
		if (overflow) {
			// Hide overflow on body and prevent scroll-jumping
			jQuery('html').css({
				overflow: 'hidden',
				top: `${-(jQuery('html').scrollTop() || 0)}px`,
			});
			jQuery('html').css({
				paddingRight: `${this.getScrollBarWidth()}px`,
			});
		} else {
			// Reset overflow css on html and body
			jQuery('html').css({ overflow: '', top: '', paddingRight: '' });
		}
	}

	public static isElementInElement(element: any, container: any): boolean {
		for (var element = element; element; element = element.parentNode) {
			if (element === container) {
				return true;
			}
		}
		return false;
	}

	public static elementOrAncestorHasClass(
		element: Element,
		className: string,
	): boolean {
		if (!element) {
			return false;
		}
		let parent: any = element;
		do {
			if (parent === (document as any)) {
				break;
			}
			if (parent.className.indexOf(className) >= 0) {
				return true;
			}
		} while ((parent = parent.parentNode));
		return false;
	}

	public static isElementInView(
		element: any,
		threshold: number = 0,
		mode: string = 'visible',
	): boolean {
		let rect = element.getBoundingClientRect();
		let viewHeight = Math.max(
			document.documentElement!.clientHeight,
			window.innerHeight,
		);

		let above = rect.bottom - threshold < 0;
		let below = rect.top - viewHeight + threshold >= 0;

		return mode === 'above'
			? above
			: mode === 'below'
				? below
				: !above && !below;
	}

	public static copyToClipboard(text: string): void {
		try {
			let input = document.createElement('input');
			input.style.position = 'fixed';
			document.body.appendChild(input);
			input.value = text;
			input.focus();
			input.setSelectionRange(0, 999);
			document.execCommand('copy');
			document.body.removeChild(input);
		} catch (exception) {}
	}
}
