import { TranslationModel } from 'shared/models/translation.model';

export class MenuItemModel {
	public label: string | undefined;
	public status: string | undefined;
	public translation?: TranslationModel;
	public onSelect: Function | undefined;
	public shouldBeActive: Function | undefined;
	public isSeperator: boolean | undefined;
	public disabled: boolean = false;
	public flag: string;
	public icon: string;
	public description: string | undefined;
	public selected: boolean;

	/**
	 * @param label The label of the menu item
	 * @param onSelect Function wich is called when item is clicked
	 * @param isSeperator If set to true, make item as divider and not clickable
	 * @param shouldBeActive Validation function, called when menu item is shown
	 * @param description An additional description in addition to the label
	 * and decides if the item should be active/disabled.
	 */
	constructor(
		label?: string,
		onSelect?: Function,
		isSeperator?: boolean,
		shouldBeActive?: Function,
		description?: string,
	) {
		this.label = label;
		this.onSelect = onSelect;
		this.isSeperator = isSeperator;
		this.shouldBeActive = shouldBeActive;
		this.description = description;
	}
}
