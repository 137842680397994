// libs
import { Component, Renderer2, ElementRef, ViewChild } from '@angular/core';
import {
	BFPositionService,
	BFUtilsService,
} from '../../../../../../../libs/material/index';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import {
	ColorPickerComponent,
	ColorPickerConfig,
} from 'shared/components/colorPicker/colorPicker.component';
import { SwatchesComponent } from 'shared/components/swatches/swatches.component';
import { ColorService } from 'shared/components/colorPicker/color.service';
import { DesignService } from 'design/design.service';
import { ScribeCommand, ScribeWrapper } from 'shared/wrappers/scribe.wrapper';
import { UIModule } from '@bannerflow/ui';

@Component({
	styleUrls: ['inspector.component.scss'],
	templateUrl: 'inspector.component.html',
	standalone: true,
	imports: [
		CommonModule,
		FormsModule,
		ColorPickerComponent,
		SwatchesComponent,
		UIModule,
	],
})
export class InspectorComponent {
	@ViewChild('linkInput') linkInput: ElementRef;

	public scribe: ScribeWrapper;

	public onSelectionChange;
	public onChange;
	public callback: Function;
	public iframe: HTMLIFrameElement;
	public onIframeClick;
	public onClick;
	public menuElement: any;
	public command: any = ScribeCommand;
	public currentCategory: any;
	public colorPickerConfig: ColorPickerConfig;
	public showMoreColors: boolean = false;
	public linkUrl: string;
	public invertedLayout: boolean = false;
	public currentColor: string;

	public categories: any = {
		text: 'Text',
		paragraph: 'Paragraph',
		color: 'Color',
		link: 'Link',
		removeFormat: 'Clear',
		help: 'Help',
	};

	constructor(
		private Renderer2: Renderer2,
		private elementRef: ElementRef,
		private positionService: BFPositionService,
		private colorService: ColorService,
		private designService: DesignService,
	) {
		this.setColorPickerConfig();
	}

	public show(scribe: ScribeWrapper, iframe?: Element): Promise<boolean> {
		this.scribe = scribe;
		this.currentColor = scribe.color;
		this.iframe = iframe as HTMLIFrameElement;
		this.menuElement = this.elementRef.nativeElement.children[0];

		//Set position
		this.positionMenu();

		//Selection change in scribe means new color
		this.onSelectionChange = scribe.selectionChange.subscribe(() => {
			this.currentColor = this.scribe.color;
			this.handleLink();
		});
		this.onChange = scribe.change.subscribe(() => {
			this.positionMenu();
		});

		//Add close listener
		this.onClick = this.Renderer2.listen(
			'document',
			'mousedown',
			(event) => {
				//If the target is within the iframe we have another listener for that
				if (this.iframe && event.target === this.iframe) {
					return;
				}

				//If the target is not with the scribe element or the inspector
				if (
					!BFUtilsService.isElementInElement(
						event.target,
						this.scribe.textInput,
					) &&
					!BFUtilsService.isElementInElement(
						event.target,
						this.menuElement,
					)
				) {
					this.close();
				}
			},
		);

		if (this.iframe) {
			//Add close listener
			this.iframe.contentWindow.document.addEventListener(
				'mousedown',
				(this.onIframeClick = (event) => {
					//If the target is not with the scribe element or the inspector
					if (
						!BFUtilsService.isElementInElement(
							event.target,
							this.scribe.textInput,
						) &&
						!BFUtilsService.isElementInElement(
							event.target,
							this.menuElement,
						)
					) {
						this.close();
					}
				}),
			);
		}

		var promise: Promise<boolean> = new Promise<boolean>((resolve) => {
			this.callback = resolve;
		});

		return promise;
	}

	public toggleCommand(commandName: string, event?: MouseEvent) {
		this.scribe.toggleCommand(commandName);

		if (event) {
			event.stopPropagation();
			event.preventDefault();
		}
	}

	public positionMenu() {
		let elementPosition = this.getElementPosition();
		let placement = 'right';

		let offset: any = {
			top: -30,
			left: 20,
		};

		this.invertedLayout = false;

		//Check if popover fits in window
		if (
			!this.positionService.checkIfPositionFits(
				elementPosition,
				this.menuElement,
				placement,
				offset,
			)
		) {
			if (placement === 'right') placement = 'left';
			else if (placement === 'center-right') placement = 'center-left';
			else if (placement === 'top-right') placement = 'top-left';
			else if (placement === 'bottom-right') placement = 'bottom-left';
			else if (placement === 'left') placement = 'right';
			else if (placement === 'top') placement = 'bottom';
			else if (placement === 'bottom') placement = 'top';

			this.invertedLayout = true;
		}

		let position = this.positionService.positionElements(
			elementPosition,
			this.menuElement,
			placement,
			true,
			offset,
		);

		let documentHeight = Math.max(
			document.body.scrollHeight,
			document.body.offsetHeight,
		);

		//The inverted layout hack should be handled by the positioning service
		position.left -= this.invertedLayout ? offset.left : 0;

		//Make sure it's fitted below topbar
		position.top = Math.max(position.top, 60);

		//Make sure it's fitted above footer
		position.top = Math.min(
			position.top,
			documentHeight - this.menuElement.offsetHeight - 60,
		);

		this.menuElement.style.left = position.left + 'px';
		this.menuElement.style.top = position.top + 'px';
	}

	public selectCategory(category, event) {
		this.currentCategory = category;

		setTimeout(() => {
			this.handleLink();
		}, 100);

		if (event) {
			event.stopPropagation();
			event.preventDefault();
		}
	}

	public updateTextColor(event?: any) {
		if (!this.colorService.validateColor(this.currentColor)) {
			return;
		}

		this.scribe.toggleCommand(this.command.Color, this.currentColor);

		if (event?.target) {
			event.target.focus();
		}
	}

	public createLink(event) {
		this.scribe.toggleCommand(this.command.CreateLink, this.linkUrl);

		if (event) {
			event.stopPropagation();
			event.preventDefault();
		}
	}

	public clearLink(event) {
		this.scribe.toggleCommand(this.command.Unlink);
		this.linkUrl = '';

		if (event) {
			event.stopPropagation();
			event.preventDefault();
		}
	}

	private handleLink(): void {
		//If link input is visible
		if (this.linkInput) {
			if (this.scribe.getSelectedLink()) {
				this.linkUrl =
					this.scribe.getSelectedLink().attributes['href'].value;
			} else {
				this.linkUrl = '';
			}
		}
	}

	private getElementPosition() {
		let position = this.positionService.offset(this.scribe.textInput);

		if (this.iframe) {
			let iframePosition = this.positionService.offset(this.iframe);

			position.left += iframePosition.left;
			position.top += iframePosition.top;
		}
		return position;
	}

	private setColorPickerConfig(): void {
		let config = new ColorPickerConfig();

		config.showAlphaSlider = false;
		config.showColorTextInput = false;
		config.showSelectedColor = false;

		this.colorPickerConfig = config;
	}

	public toggleColorMode(event?) {
		this.showMoreColors = !this.showMoreColors;

		if (event) {
			event.stopPropagation();
			event.preventDefault();
		}
	}

	public clearFormat() {
		this.scribe.clearFormat();
	}

	public close(): void {
		this.designService.selectText(null);
		//this.scribe.element.blur();
		this.callback(true);
	}

	ngOnDestroy() {
		this.scribe.disable();

		if (this.onClick) this.onClick();
		if (this.onIframeClick)
			this.iframe.contentWindow.document.removeEventListener(
				'mousedown',
				this.onIframeClick,
			);
		if (this.onSelectionChange) this.onSelectionChange.unsubscribe();
		if (this.onChange) this.onChange.unsubscribe();
	}
}
