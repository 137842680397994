export class CodeEditorUtils {
	public static GetLastSelectionPoint(editor: any): any {
		var selections = document.querySelectorAll(
			'.CodeMirror-selected',
		) as any;
		var scrollElement = document.querySelector('.CodeMirror-scroll') as any;
		var lastPoint = { x: 0, y: 0 };
		var offset = {
			x: scrollElement.scrollLeft,
			y: scrollElement.scrollTop,
		};

		lastPoint.x = editor.cursorCoords(false, 'local').left - offset.x;
		lastPoint.y = editor.cursorCoords(false, 'local').top - offset.y;

		return lastPoint;
	}
}
