import {
	BFBytesPipe,
	BFDialogComponent,
	BFDialogResponse,
	BFNotificationService,
} from '../../../../libs/material';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	ViewChild,
} from '@angular/core';
import { CommonModule, NgFor, NgIf } from '@angular/common';
import { UserService } from 'shared/services/user.service';
import { LandingPageService } from 'shared/services/landingPage.service';
import { UserModel } from 'shared/models/user.model';
import { FileModel } from 'shared/models/file.model';
import { FileType } from 'shared/enums/fileType.enum';
import { State } from 'shared/enums/state.enum';
import { LandingPageModel } from 'shared/models/landingPage.model';
import { UIModule } from '@bannerflow/ui';
import { environment } from './../../../../environments/environment';
import { FileService } from 'code/services';
import { AuthService } from '@auth0/auth0-angular';
import { EllipsisMiddlePipe } from 'shared/pipes/ellipsisMiddle.pipe';

@Component({
	styleUrls: ['./uploadDialog.component.scss'],
	selector: 'uploadDialog',
	templateUrl: './uploadDialog.component.html',
	standalone: true,
	imports: [
		BFDialogComponent,
		NgIf,
		NgFor,
		BFBytesPipe,
		UIModule,
		CommonModule,
		EllipsisMiddlePipe,
		BFBytesPipe,
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadDialogComponent {
	public selectedFiles: any[] = [];
	public loading: boolean;
	public title: string;
	public multiple: boolean;
	public allowedFiles: string;
	public zipChosen: boolean;
	private callback: Function;
	private uploadUrl: string;

	@ViewChild('fileInput', { static: true })
	fileInputElement: ElementRef<HTMLInputElement>;

	constructor(
		private userService: UserService,
		private landingPageService: LandingPageService,
		private authService: AuthService,
		private readonly notificationService: BFNotificationService,
		private fileService: FileService,
		private cdRef: ChangeDetectorRef,
	) {
		this.userService.get().then((user: UserModel) => {
			this.uploadUrl = `${environment.API_URL}/api/landingpage/${user.accountSlug}/${user.brandId}/`;
		});
	}

	public initiate(
		multiple: boolean,
		allowedFiles: string,
		title: string,
	): Promise<BFDialogResponse<FileModel[]>> {
		this.title = title;
		this.multiple = multiple;
		this.allowedFiles = allowedFiles;

		var promise = new Promise<BFDialogResponse<FileModel[]>>((resolve) => {
			this.callback = resolve;
		});

		return promise;
	}

	public cancel(): void {
		var response = new BFDialogResponse<FileModel[]>();
		response.cancel = true;

		this.callback(response);
	}

	public removeFile(file: any): void {
		this.selectedFiles.splice(this.selectedFiles.indexOf(file), 1);

		this.cdRef.detectChanges();
	}

	public selectFiles(event: any): void {
		for (let key in event.target.files) {
			if (event.target.files.hasOwnProperty(key)) {
				this.selectedFiles.push(event.target.files[key]);
			}
		}

		if (
			(this.selectedFiles.length === 1 &&
				this.selectedFiles[0].type === 'application/zip') ||
			this.selectedFiles[0].type === 'application/x-zip-compressed'
		) {
			this.zipChosen = true;
		} else {
			this.zipChosen = false;
		}

		this.cdRef.detectChanges();
	}

	public upload(): void {
		this.loading = true;
		const formData = new FormData();

		this.selectedFiles.forEach((file: any) => {
			formData.append('file', file);
		});

		this.fileService.uploadFile(formData, this.uploadUrl).subscribe(
			(response) => {
				const files: FileModel[] = [];
				const invalidFiles: string[] = [];
				const dialogResponse = { data: files };

				response.data.forEach((item) => {
					const newFile = new FileModel().deserialize(item);

					if (newFile.type === FileType.Unknown) {
						invalidFiles.push(newFile.name);
						return;
					}

					newFile.state = State.New;
					files.push(newFile);
				});

				if (invalidFiles.length > 0) {
					this.notificationService.show(
						'Unsupported file type: ' + invalidFiles.join(', '),
						'error',
						'top',
						8000,
					);
				}

				dialogResponse.data = files;
				this.callback(dialogResponse);
			},
			(error) => {
				this.notificationService.show(
					'Could not upload file: ' + error,
					'error',
					'top',
					6000,
				);
				this.cancel();
			},
		);
	}

	public migrate(): void {
		this.loading = true;

		this.landingPageService.get().then((landingPage: LandingPageModel) => {
			const formData = new FormData();
			this.selectedFiles.forEach((file: any) =>
				formData.append('file', file),
			);
			this.fileService
				.uploadFile(
					formData,
					`${this.uploadUrl}importb1?landingPageId=${landingPage.id}`,
				)
				.subscribe(
					() => window.location.reload(),
					(error) => console.error('Could not upload file', error),
				);
		});
	}
}
