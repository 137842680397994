<div
    class="bfDatePicker"
    [ngClass]="{ 'bfDatePicker--isSpan': options.isSpan, 'bfDatePicker--selecting': selecting }">
    <div class="bfDatePicker__calendar">
        <!--<div class="bfDatePicker__calendarTop">
            <span class="bfDatePicker__calendarTopYearTitle">{{ currentDate.format('YYYY') }}</span>
            <button type="button" (click)="openYearPicker()" *ngIf="!yearPicker">
                <i class="ion-arrow-right-c"></i>
                Select Year
            </button>
            <i class="close ion-android-close"></i>
        </div>-->
        <div class="bfDatePicker__calendarContainer">
            <!--DATE PICKER-->
            <div *ngIf="!yearPicker && !showTimePicker">
                <div class="bfDatePicker__calendarContainerMonthSection">
                    <i class="bf-icon bf-icon-arrow-left-b" (click)="prevMonth()"></i>
                    <span class="bfDatePicker__calendarContainerMonthSectionTitle">
                        {{ currentDate.format('MMM') }}
                        <span (click)="openYearPicker()">
                            {{ currentDate.format('YYYY') }}
                        </span>
                    </span>
                    <i class="bf-icon bf-icon-arrow-right-b" (click)="nextMonth()"></i>
                </div>
                <div class="bfDatePicker__calendarContainerDayNames">
                    <span>mo</span>
                    <span>tu</span>
                    <span>we</span>
                    <span>th</span>
                    <span>fr</span>
                    <span>sa</span>
                    <span>su</span>
                </div>
                <div class="bfDatePicker__calendarDaysContainer">
                    <div
                        class="bfDatePicker__calendarDay"
                        *ngFor="let d of days; let i = index"
                        (mousemove)="leaveTrack($event, d.momentObj)"
                        (click)="handleDateClick($event, d.momentObj)"
                        [ngClass]="{ 'bfDatePicker__calendarDay--disabled': !d.enabled, 'bfDatePicker__calendarDay--today': d.today, 'bfDatePicker__calendarDay--selectedFirst': d.selected.first, 'bfDatePicker__calendarDay--selectedLast': d.selected.last, 'bfDatePicker__calendarDay--selectedBetween': d.selected.between }">
                        <span class="bfDatePicker__calendarDayInner">{{ d.day }}</span>
                    </div>
                </div>
            </div>

            <!--YEAR PICKER-->
            <div *ngIf="yearPicker">
                <div class="bfDatePicker__calendarYearsContainer">
                    <span
                        class="bfDatePicker__calendarYear"
                        *ngFor="let y of years; let i = index"
                        (click)="selectYear($event, y)">
                        {{ y }}
                    </span>
                </div>
            </div>

            <!--TIME PICKER-->
            <div *ngIf="showTimePicker" class="bfDatePicker__calendarTimeContainer">
                <span
                    *ngFor="let hour of hours; let i=index;"
                    class="bfDatePicker__calendarTime"
                    (click)="selectTime($event, i)"
                    >{{i < 10 ? '0'+i : i}}:00</span
                >
            </div>
        </div>
    </div>
</div>
