import {
	Directive,
	ElementRef,
	Input,
	Renderer2,
	OnDestroy,
	OnChanges,
	OnInit,
	HostListener,
	Output,
	EventEmitter,
} from '@angular/core';
import { BFTooltipContainer } from '../../containers/bfTooltip.container';
const MIN_WIDTH: number = 50;

@Directive({
	selector: '[bfInlineEdit]',
	exportAs: 'bfInlineEdit', // the name of the variable to access the directive
	standalone: true,
})
export class BFInlineEditDirective implements OnDestroy, OnInit, OnChanges {
	// Two-way binding
	@Output() public bfInlineEditChange: EventEmitter<string> =
		new EventEmitter();

	@Output('bfInlineEditEdit') public editEventEmitter: EventEmitter<string> =
		new EventEmitter<string>();
	@Output('bfInlineEditAbort')
	public abortEventEmitter: EventEmitter<string> = new EventEmitter<string>();
	@Output('bfInlineEditDone') public doneEventEmitter: EventEmitter<string> =
		new EventEmitter<string>();
	@Output('bfInlineEditSelect')
	public selectEventEmitter: EventEmitter<string> =
		new EventEmitter<string>();

	@Input('bfInlineEditValidation') public validationFunc: Function;
	@Input('bfInlineEditPreventClickToEdit')
	public preventClickToEdit: boolean = false;
	@Input('bfInlineEditPlaceholder') public placeholder: string = 'untitled';

	@Input('bfInlineEdit') public get bfInlineEdit(): string {
		return this.text;
	}
	public set bfInlineEdit(val: string) {
		// Only trigger event if text have changed
		if (this.text !== val) {
			this.text = val;
			this.bfInlineEditChange.emit(val);
		}
	}

	public element: any;
	public isValid: boolean = true;

	private text: string;
	private input: any;

	// Listeners
	private onClick: Function;
	private onFocus: Function;
	private onBlur: Function;
	private onKeyUp: Function;
	private onKeyDown: Function;

	constructor(
		public elementRef: ElementRef,
		private Renderer2: Renderer2,
		private tooltipContainer: BFTooltipContainer,
	) {
		this.element = this.elementRef.nativeElement;
	}

	public ngOnInit(): void {
		this.element = this.elementRef.nativeElement;

		// Set class for styling use
		this.element.classList.add('bfInlineEdit__input');

		if (this.preventClickToEdit) {
			this.element.classList.add('bfInlineEdit__input--preventClick');
		}

		// set default text
		this.updateText();

		this.onClick = this.Renderer2.listen(
			this.element,
			'click',
			(event: MouseEvent) => {
				// Clicking on element should only trigger edit mode if allowed
				if (!this.preventClickToEdit) {
					this.startEdit();
					event.preventDefault();
				}

				// When editing prevent bubbling clicks etc
				if (this.input) {
					event.preventDefault();
					event.stopImmediatePropagation();
					event.stopPropagation();
				}
			},
		);
	}

	public ngOnChanges(simpleChanges: any): void {
		// TODO: Set width of textfield dynamically: https://stackoverflow.com/questions/3392493/adjust-width-of-input-field-to-its-input

		const changes = simpleChanges.bfInlineEdit;

		// Don't update text if user is editing it
		if (this.input) {
			return;
		}

		if (changes && changes.currentValue != changes.previousValue) {
			this.stopEdit();
			this.updateText();
		}
	}

	public save(): void {
		// If empty value entered
		if (!this.input.value) {
			this.abort();
			return;
			// If validation function exists and it fails
		} else if (!this.isValid) {
			return;
		} else {
			this.onBlur(); // remove event listener
			this.bfInlineEdit = this.input.value || '';
			this.stopEdit();

			this.doneEventEmitter.emit(this.bfInlineEdit);
		}
	}

	public abort(): void {
		this.onBlur(); // remove event listener

		this.stopEdit();
		this.abortEventEmitter.emit();
	}

	public startEdit(): void {
		this.element.classList.toggle('bfInlineEdit__input--inValid', false);

		if (!this.element.classList.contains('bfInlineEdit__input--active')) {
			this.element.classList.add('bfInlineEdit__input--active');
			this.createInput();

			this.element.classList.toggle('bfTooltip--tooltipOpen', true);
		}

		// Hide all eventual tooltips
		this.tooltipContainer.clear();
	}

	public stopEdit(): void {
		if (this.element) {
			this.element.classList.toggle('bfInlineEdit__input--active', false);
			this.element.classList.toggle('bfTooltip--tooltipOpen', false);
		}
		this.destroyInput();
		// Hide all eventual tooltips
		this.tooltipContainer.clear();
	}

	/**
	 * Update text inside DOM element
	 */
	private updateText(): void {
		if (this.elementRef && this.elementRef.nativeElement) {
			this.elementRef.nativeElement.innerHTML =
				this.text || this.placeholder;
		}
	}

	// TODO: make it a content-editable instead and prevent pasting formatting
	private createInput(): void {
		const width: number = this.element.offsetWidth;
		this.element.classList.add('bfInlineEdit__input--active');
		this.element.innerHTML = '';

		this.input = document.createElement('input');
		this.input.value = this.text || '';
		// this.input.style.width = '100%';
		this.input.style.width = width + 20 + 'px';
		this.input.style.minWidth = MIN_WIDTH;
		this.input.style.display = 'block';
		this.input.setAttribute('spellcheck', 'false');

		this.element.appendChild(this.input);

		this.input.focus();
		this.input.select();

		this.onBlur = this.Renderer2.listen(
			this.input,
			'blur',
			(event: FocusEvent) => {
				if (this.isValid) {
					this.save();
				} else {
					this.abort();
				}
			},
		);

		this.onKeyUp = this.Renderer2.listen(
			this.input,
			'keyup',
			(event: KeyboardEvent) => {
				this.editEventEmitter.emit(this.input.value);
				this.validate();
			},
		);

		this.onKeyDown = this.Renderer2.listen(
			this.input,
			'keydown',
			(event: KeyboardEvent) => {
				switch (event.keyCode) {
					// Enter
					case 13:
						this.save();
						break;
					// Escape
					case 27:
						this.abort();
						break;
					default:
				}
				event.stopPropagation();
			},
		);
	}

	private validate(): void {
		if (this.validationFunc) {
			this.isValid = this.validationFunc(this.input.value) === true;
			this.element.classList.toggle(
				'bfInlineEdit__input--inValid',
				!this.isValid,
			);

			if (!this.isValid) {
				this.element.setAttribute(
					'data-error-message',
					this.validationFunc(this.input.value),
				);
			}
		}
	}

	private destroyInput(): void {
		if (this.element && this.input) {
			this.element.removeChild(this.input);
			delete this.input;
			this.updateText();
		}
	}

	// Remove listeners
	public ngOnDestroy(): void {
		if (this.onClick) {
			this.onClick();
		}
		if (this.onBlur) {
			this.onBlur();
		}
		if (this.onKeyUp) {
			this.onKeyUp();
		}
		if (this.onKeyDown) {
			this.onKeyDown();
		}
	}
}
