<div class="translatorDashboard">
    <div class="translatorDashboard__top">
        <div class="translatorDashboard__topLogo"></div>
    </div>
    <div class="translatorDashboard__page">
        <div class="translatorDashboard__pageContent">
            <div class="translatorDashboard__pageHeader">
                <div class="translatorDashboard__pageHeaderTitle">Your translations</div>
                <div
                    class="translatorDashboard__pageHeaderSubtitle"
                    *ngIf="(translationsInProgress && translationsInProgress.length) || (translationsPending && translationsPending.length)">
                    Here are your latest translations waiting for some words.
                </div>
                <div
                    class="translatorDashboard__pageHeaderSubtitle"
                    *ngIf="(!translationsInProgress || !translationsInProgress.length) && (!translationsPending || !translationsPending.length)">
                    No translations waiting for your action.
                </div>
            </div>

            <div class="translatorDashboard__pageContentTable">
                <!--IN PROGRESS-->
                <div
                    class="seperator"
                    *ngIf="translationsInProgress && translationsInProgress.length">
                    <div class="seperator__text">Translations in progress</div>
                </div>
                <table
                    class="bfBlockTable"
                    style="margin-bottom: 70px;"
                    *ngIf="translationsInProgress && translationsInProgress.length">
                    <thead>
                        <tr>
                            <th style="min-width:125px">Landing page</th>
                            <th style="width: 50%;">Translate to</th>
                            <th>Deadline</th>
                            <th>Message</th>
                            <th>&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container
                            *ngFor="let translation of translationsInProgress let i=index">
                            <tr
                                class="translatorDashboard__pageContentTableRow"
                                [ngClass]="{'translatorDashboard__pageContentTableRow--open': translation.showMessage}">
                                <td>
                                    <i class="bf-icon bf-icon-landingpage"></i>
                                    {{ translation.landingPageName }}
                                </td>
                                <td>
                                    <ui-flag
                                        size="tiny"
                                        [culture]="translation.cultureCode"></ui-flag>
                                    <span bfTooltip="{{ translation.translationName }}">
                                        {{ translation.translationName | ellipsisMiddle:
                                        50 }}
                                    </span>
                                </td>
                                <td
                                    class="translatorDashboard__pageContentTableRowCell--faded">
                                    {{translation.deadline ? (translation.deadline | date:
                                    'short') : 'None'}}
                                </td>
                                <td
                                    (click)="translation.showMessage = !translation.showMessage && !!translation.messageToTranslator"
                                    [ngClass]="{'translatorDashboard__pageContentTableRowCell--faded': !translation.messageToTranslator}">
                                    <span
                                        *ngIf="translation.messageToTranslator"
                                        class="translatorDashboard__pageContentTableRowLink"
                                        >{{translation.showMessage ? 'Close' :
                                        'View'}}</span
                                    >
                                    <span *ngIf="!translation.messageToTranslator"
                                        >None</span
                                    >
                                </td>
                                <td style="width: 40px;">
                                    <ui-button
                                        id="interaction-continue-translation-landingpage-btn"
                                        type="default"
                                        (click)="startTranslation(translation)"
                                        text="Continue">
                                    </ui-button>
                                </td>
                            </tr>
                            <tr
                                class="translatorDashboard__pageContentTableMessageRow"
                                *ngIf="translation.showMessage">
                                <td colspan="5">
                                    <div>{{translation.messageToTranslator}}</div>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>

                <!--PENDING-->
                <div
                    class="seperator"
                    *ngIf="translationsPending && translationsPending.length">
                    <div class="seperator__text">Translations pending</div>
                </div>
                <table
                    class="bfBlockTable"
                    *ngIf="translationsPending && translationsPending.length">
                    <thead>
                        <tr>
                            <th style="min-width:125px">Landing page</th>
                            <th style="width: 50%;">Translate to</th>
                            <th>Deadline</th>
                            <th>Message</th>
                            <th>&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container
                            *ngFor="let translation of translationsPending let i=index">
                            <tr
                                class="translatorDashboard__pageContentTableRow"
                                [ngClass]="{'translatorDashboard__pageContentTableRow--open': translation.showMessage}">
                                <td>
                                    <i class="bf-icon bf-icon-landingpage"></i>
                                    {{ translation.landingPageName }}
                                </td>
                                <td>
                                    <ui-flag
                                        size="tiny"
                                        [culture]="translation.cultureCode"></ui-flag>
                                    <span bfTooltip="{{ translation.translationName }}">
                                        {{ translation.translationName | ellipsisMiddle:
                                        50 }}
                                    </span>
                                </td>
                                <td
                                    class="translatorDashboard__pageContentTableRowCell--faded">
                                    {{translation.deadline ? (translation.deadline | date:
                                    'short') : 'None'}}
                                </td>
                                <td
                                    (click)="translation.showMessage = !translation.showMessage && !!translation.messageToTranslator"
                                    [ngClass]="{'translatorDashboard__pageContentTableRowCell--faded': !translation.messageToTranslator}">
                                    <span
                                        *ngIf="translation.messageToTranslator"
                                        class="translatorDashboard__pageContentTableRowLink"
                                        >{{translation.showMessage ? 'Close' :
                                        'View'}}</span
                                    >
                                    <span *ngIf="!translation.messageToTranslator"
                                        >None</span
                                    >
                                </td>
                                <td style="width: 60px;">
                                    <ui-button
                                        id="interaction-start-translation-landingpage-btn"
                                        type="primary"
                                        (click)="startTranslation(translation)"
                                        text="Start">
                                    </ui-button>
                                </td>
                            </tr>
                            <tr
                                class="translatorDashboard__pageContentTableMessageRow"
                                *ngIf="translation.showMessage">
                                <td colspan="5">{{translation.messageToTranslator}}</td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>

                <!--COMPLETED-->
                <div
                    class="seperator"
                    *ngIf="translationsDone && translationsDone.length">
                    <div class="seperator__text">Translations Completed (last 10)</div>
                </div>
                <table
                    class="bfBlockTable bfBlockTable--faded"
                    *ngIf="translationsDone && translationsDone.length">
                    <thead>
                        <tr>
                            <th style="min-width:125px">Landing page</th>
                            <th style="width: 50%;">Translate to</th>
                            <th>Deadline</th>
                            <th>Message</th>
                            <th>&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container
                            *ngFor="let translation of translationsDone let i=index">
                            <tr
                                class="translatorDashboard__pageContentTableRow"
                                [ngClass]="{'translatorDashboard__pageContentTableRow--open': translation.showMessage}">
                                <td>
                                    <i class="bf-icon bf-icon-landingpage"></i>
                                    {{ translation.landingPageName }}
                                </td>
                                <td>
                                    <ui-flag
                                        size="tiny"
                                        [culture]="translation.cultureCode"></ui-flag>
                                    <span bfTooltip="{{ translation.translationName }}"
                                        >{{ translation.translationName | ellipsisMiddle:
                                        50}}</span
                                    >
                                </td>
                                <td
                                    class="translatorDashboard__pageContentTableRowCell--faded">
                                    {{translation.deadline ? (translation.deadline | date:
                                    'short') : 'None'}}
                                </td>
                                <td
                                    (click)="translation.showMessage = !translation.showMessage && !!translation.messageToTranslator"
                                    [ngClass]="{'translatorDashboard__pageContentTableRowCell--faded': !translation.messageToTranslator}">
                                    <span
                                        *ngIf="translation.messageToTranslator"
                                        class="translatorDashboard__pageContentTableRowLink">
                                        {{ translation.showMessage ? 'Close' : 'View' }}
                                    </span>
                                    <span *ngIf="!translation.messageToTranslator"
                                        >None</span
                                    >
                                </td>
                                <td
                                    style="width: 60px;"
                                    [ngSwitch]="translation.translationState === 3">
                                    <i
                                        class="bf-icon bf-icon-checkmark"
                                        *ngSwitchCase="true"></i>
                                    <span *ngSwitchCase="false">Deadline passed</span>
                                </td>
                            </tr>

                            <tr
                                class="translatorDashboard__pageContentTableMessageRow"
                                *ngIf="translation.showMessage">
                                <td colspan="5">{{translation.messageToTranslator}}</td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
