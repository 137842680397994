import { ISerializable } from 'shared/interfaces/serializable';

export class TextModel implements ISerializable<TextModel> {
	public key: string;
	public value: string;
	public name: string;
	public originalResourceIds: string[];
	public isBlockElement: boolean = false;
	public viewPortTop: number;
	public from: 'sidebar' | 'canvas';

	constructor() {}

	public deserialize(data: any): TextModel {
		this.key = data.key;
		this.value = data.value;
		this.name = data.name;
		this.originalResourceIds = data.originalResourceIds;

		return this;
	}
}
