import {
	Component,
	Input,
	Output,
	Inject,
	OnInit,
	EventEmitter,
	ElementRef,
	ViewChild,
	NgZone,
	forwardRef,
} from '@angular/core';
import {
	BFDatePickerOptions,
	BFDatePickerComponent,
} from '../datePicker/bfDatePicker.component';
import { BFPositionService, BFUtilsService } from '../../services/index';
import moment from 'moment';
import { NgClass, NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';

export interface BFDatePickerButtonOptions extends BFDatePickerOptions {
	placeholder: string;
	allowEmpty: boolean;
	closeOnPick: boolean;
}

export type Duration = Date[];

@Component({
	selector: 'bfDatePickerButton',
	templateUrl: 'bfDatePickerButton.component.html',
	styleUrls: ['./bfDatePickerButton.component.scss'],
	standalone: true,
	imports: [NgClass, NgIf, BFDatePickerComponent, FormsModule],
})
export class BFDatePickerButton implements OnInit {
	@Input() public duration: Duration = [undefined, undefined];
	@Output() public durationChange = new EventEmitter<Duration>();
	@Input() public options: BFDatePickerButtonOptions;
	@ViewChild('button', { static: true }) public button: ElementRef;
	@ViewChild('datePicker', { static: true }) public datePicker: ElementRef;

	public pristine = true;
	public selecting = false;
	public durationText: string;

	constructor(private ngZone: NgZone) {
		this.hideIfClickedOutside = this.hideIfClickedOutside.bind(this);
	}

	public ngOnInit() {
		if (this.options && this.options.initialDate) {
			this.pristine = false;
			if (!this.duration) {
				if (this.options.isSpan) {
					this.duration = [
						this.options.initialDate,
						moment(this.options.initialDate).add(7, 'day').toDate(),
					];
				} else {
					this.duration = [this.options.initialDate, undefined];
				}
			}
			this.durationText = this.getDurationText();
		}
	}

	showDatePicker() {
		if (this.selecting) {
			this.selecting = false;
			return;
		}

		this.selecting = true;
		setTimeout(() => {
			document.addEventListener('mousedown', this.hideIfClickedOutside);
		});
	}

	hideIfClickedOutside(event: MouseEvent) {
		if (
			(this.button.nativeElement as HTMLDivElement).contains(
				event.target as Node,
			)
		) {
			return;
		}
		if (
			!(this.datePicker.nativeElement as HTMLDivElement).contains(
				event.target as Node,
			)
		) {
			this.ngZone.run(this.hideDatePicker.bind(this));
		}
	}

	emitDurationChangeEvent() {
		this.pristine = false;
		this.durationText = this.getDurationText();
		this.durationChange.emit(this.duration);
		if (this.options.closeOnPick) {
			this.hideDatePicker();
		}
	}

	getDurationText(): string {
		if (this.options.isSpan) {
			return (
				this.formatDate(this.duration[0]) +
				' - ' +
				this.formatDate(this.duration[1])
			);
		} else {
			return this.formatDate(this.duration[0]);
		}
	}

	formatDate(date?: Date) {
		if (date === undefined) {
			return 'N/A';
		} else {
			return moment(date).format(
				this.options.timePicker ? 'MMM D, YYYY HH:mm' : 'MMM D, YYYY',
			);
		}
	}

	hideDatePicker() {
		this.selecting = false;
		document.removeEventListener('mousedown', this.hideIfClickedOutside);
	}

	clear(event: MouseEvent) {
		event.stopPropagation();
		this.pristine = true;
		this.durationText = '';
		this.duration = [undefined, undefined];
		this.durationChange.emit(this.duration);
	}
}
