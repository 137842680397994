import { TranslatorModel } from './translator.model';

export class LocalizationModel {
	public localizationId: string;
	public name: string;
	public cultureCode: string;
	public cultureName: string;
	public fallbackUrl: string;
	public translators: TranslatorModel[] = [];

	public deserialize(data: any): LocalizationModel {
		this.name = data.name;
		this.localizationId = data.localizationId;
		this.cultureCode = data.cultureCode;
		this.cultureName = data.cultureName;
		this.fallbackUrl = data.fallbackUrl;

		if (data.translators)
			data.translators.forEach((translator) => {
				this.translators.push(
					new TranslatorModel().deserialize(translator),
				);
			});

		return this;
	}
}
