import { ISerializable } from 'shared/interfaces/serializable';
import { IFile } from 'code/file.interface';
import { FileModel } from 'shared/models/file.model';
import { State } from 'shared/enums/state.enum';

export class FolderModel implements ISerializable<FolderModel>, IFile {
	public name: string;
	public state: State;
	public files?: FileModel[];
	public folders?: FolderModel[];

	constructor(name: string = '') {
		this.name = name;
		this.files = new Array<FileModel>();
		this.folders = new Array<FolderModel>();
	}

	public deserialize(data: any): FolderModel {
		this.name = data.name;
		if (data.files)
			data.files.forEach((file) => {
				this.files.push(new FileModel().deserialize(file));
			});
		if (data.folders)
			data.folders.forEach((folder) => {
				this.folders.push(new FolderModel().deserialize(folder));
			});
		this.state = data.state;

		return this;
	}
}
