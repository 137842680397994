define(['immutable'], function (Immutable) {
	var blockElementNames = Immutable.Set.of(
		'ADDRESS',
		'ARTICLE',
		'ASIDE',
		'AUDIO',
		'BLOCKQUOTE',
		'CANVAS',
		'DD',
		'DIV',
		'FIELDSET',
		'FIGCAPTION',
		'FIGURE',
		'FOOTER',
		'FORM',
		'H1',
		'H2',
		'H3',
		'H4',
		'H5',
		'H6',
		'HEADER',
		'HGROUP',
		'HR',
		'LI',
		'NOSCRIPT',
		'OL',
		'OUTPUT',
		'P',
		'PRE',
		'SECTION',
		'TABLE',
		'TD',
		'TH',
		'TFOOT',
		'UL',
		'VIDEO',
	);

	return blockElementNames;
});
