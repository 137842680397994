<ng-container *ngIf="view != 'versions'">
    <div class="header">
        <div class="header__section header__section--left">
            <div class="header__menu">
                <div class="header__menuItem header__menuItem--primary"></div>
                <div
                    class="header__menuItem"
                    *ngIf="view === 'design'"
                    bfTooltip="Translatable content"
                    bfTooltipPlacement="bottom"
                    [ngClass]="{ 'header__menuItem--active' : appService.sideBarView == 1 }"
                    (click)="appService.toggleTranslationSideBar()">
                    <i class="bf-icon bf-icon-translation"></i>
                </div>
                <div
                    class="header__menuItem"
                    *ngIf="view === 'code'"
                    bfTooltip="File list"
                    bfTooltipPlacement="bottom"
                    [ngClass]="{ 'header__menuItem--active' : appService.sideBarView == 2 }"
                    (click)="appService.toggleFilesSideBar()">
                    <i class="bf-icon bf-icon-file-list"></i>
                </div>
                <div *ngIf="landingPage" class="header__title">{{landingPage.name}}</div>
            </div>
        </div>

        <div class="header__section header__section--center" *ngIf="view === 'design'">
            <div
                class="header__sizePicker"
                *ngIf="designService.artboardSize"
                bfTooltip="Change the size of your artboard"
                bfTooltipPlacement="bottom"
                [uiDropdownTarget]="sizeMenu">
                <span>{{designService.artboardSize.name}}</span>&nbsp;
                <ui-svg-icon class="dropdown-triangle" icon="dropdown-tiny-triangle" />
            </div>

            <div
                class="header__versionPicker"
                *ngIf="textService.currentTranslation"
                bfTooltip="Change to another version or manage all versions."
                bfTooltipPlacement="bottom"
                [uiDropdownTarget]="translationsMenu">
                <ui-flag
                    size="tiny"
                    [culture]="textService.currentTranslation.culture"></ui-flag>
                {{textService.currentTranslation.name}}
                <ui-svg-icon class="dropdown-triangle" icon="dropdown-tiny-triangle" />
            </div>
        </div>

        <div class="header__section header__section--right" ui-theme="minimal">
            <environment-picker />
            <div class="header__state" *ngIf="landingPage">
                <div
                    class="header__stateText"
                    [bfTooltip]="TranslationModel.getStateDescription(textService.currentTranslation)"
                    bfTooltipPlacement="bottom">
                    Status:
                    <span
                        >{{ TranslationModel.getStateText(textService.currentTranslation)
                        }}</span
                    >
                </div>
            </div>
            <ui-button
                #saveButton
                id="interaction-save-landingpage-btn"
                type="primary"
                svgIcon="files"
                text="Save"
                (click)="save()"
                [disabled]="!landingPageService.dirty"
                [loading]="isSaving"
                [uiTooltip]="'Use ' + (UtilsService.isMac() ? 'cmd' : 'ctrl') + '+s to save'"
                uiTooltipPosition="bottom"
                [uiTooltipDelay]="0" />
            <ui-button
                #publishButton
                *ngIf="textService && textService.currentTranslation && (textService.currentTranslation.publishState <= 1 || textService.currentTranslation.publishState === 3)"
                id="interaction-publish-update-landingpage-btn"
                type="primary"
                svgIcon="cloud"
                (click)="publish(textService.currentTranslation)"
                [text]="textService && textService?.currentTranslation?.publishState === 3 ? 'Update' : 'Publish'" />
            <ui-button
                #unpublishButton
                *ngIf="textService && textService.currentTranslation && textService?.currentTranslation?.publishState > 1"
                id="interaction-unpublish-landingpage-btn"
                type="default"
                svgIcon="disabled"
                text="Unpublish"
                (click)="unpublish(textService.currentTranslation)">
            </ui-button>
        </div>
    </div>
</ng-container>

<ui-dropdown
    #sizeMenu
    [maxHeight]="250"
    [positions]="[{
        originX: 'center',
        originY: 'bottom',
        overlayX: 'center',
        overlayY: 'top'
    }]">
    <ng-container *ngFor="let size of sizes">
        <ng-container *ngIf="size.label !== ''">
            <ui-dropdown-item id="interaction-size" (click)="handleDropdownSelect(size)">
                <span class="headerMenu__label" [innerHTML]="size.label"></span>
            </ui-dropdown-item>
        </ng-container>
        <div *ngIf="size.label === ''" class="dropdown-divider"></div>
    </ng-container>
</ui-dropdown>

<ui-dropdown
    #translationsMenu
    [positions]="[{
        originX: 'center',
        originY: 'bottom',
        overlayX: 'center',
        overlayY: 'top'
    }]"
    width="165">
    <ui-dropdown-item
        id="interaction-translation"
        (click)="handleDropdownSelect(manageVersionsMenuItem)">
        <ui-svg-icon class="dots-icon" icon="kebab" />
        <span
            class="headerMenu__label"
            [innerHTML]="manageVersionsMenuItem?.label"></span>
    </ui-dropdown-item>
    <div class="dropdown-divider"></div>
    <ui-dropdown-item
        *ngFor="let translationMenuItem of translationMenuItems"
        id="interaction-translation"
        (click)="handleDropdownSelect(translationMenuItem)">
        <ui-flag size="tiny" [culture]="translationMenuItem.flag"></ui-flag>
        <span class="headerMenu__label" [innerHTML]="translationMenuItem.label"></span>
        <span
            *ngIf="translationMenuItem.description"
            class="headerMenu__description"
            [innerHTML]="translationMenuItem.description"></span>
    </ui-dropdown-item>
</ui-dropdown>
