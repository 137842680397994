<div class="publishOverlayStatus" *ngIf="loading">
    <div class="loader">
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
    </div>
    {{ publishingText }}
    <div class="publishOverlayStatus__error">{{ publishingErrorText }}</div>
</div>
<div class="publishOverlay" *ngIf="!loading">
    <div class="closeOverlay" (click)="cancel()">
        <ui-svg-icon class="ui-icon" icon="close" />
        <div class="closeText">
            <span>close</span>
            <span>(esc)</span>
        </div>
    </div>
    <div class="publishOverlay__wrapper">
        <ng-container *ngIf="(type$ | async) as type">
            <div class="publishOverlay__header" *ngIf="type === 'publish'">
                <div class="publishOverlay__headerTitle">Publish landing page</div>
                <div class="publishOverlay__headerText">
                    You will publish your landing page in {{ (numberOfSelectedVersions$ |
                    async) }} text versions.
                </div>
                <div class="publishOverlay__headerButtons">
                    <ui-button
                        id="interaction-cancel-publish-landingpage-btn"
                        type="default"
                        (click)="cancel()"
                        text="Cancel">
                    </ui-button>
                    <ui-button
                        id="interaction-publish-landingpage-btn"
                        type="primary"
                        (click)="publish()"
                        text="Publish">
                    </ui-button>
                </div>
            </div>
            <div class="publishOverlay__header" *ngIf="type === 'unpublish'">
                <div class="publishOverlay__headerTitle">Unpublish landing page</div>
                <div class="publishOverlay__headerText">
                    You will unpublish your landing page in {{ (numberOfSelectedVersions$
                    | async)}} text versions.
                </div>
                <div class="publishOverlay__headerButtons">
                    <ui-button
                        id="interaction-cancel-unpublish-landingpage-btn"
                        type="default"
                        (click)="cancel()"
                        text="Cancel">
                    </ui-button>
                    <ui-button
                        id="interaction-unpublish-landingpage-btn"
                        type="primary"
                        (click)="unpublish()"
                        text="Unpublish">
                    </ui-button>
                </div>
            </div>
            <div class="publishOverlay__content">
                <div class="seperator">
                    <div class="seperator__text">Versions</div>
                </div>
                <table class="bfBlockTable">
                    <thead>
                        <tr>
                            <th>
                                <ui-checkbox
                                    [selected]="allSelected"
                                    (click)="toggleSelect()"></ui-checkbox>
                            </th>
                            <th>Language</th>
                            <th>Publish URL</th>
                        </tr>
                    </thead>
                    <tbody
                        *ngIf="(affectedTranslations$ | async) as affectedTranslations">
                        <tr *ngFor="let translation of affectedTranslations">
                            <td>
                                <ui-checkbox
                                    [(selected)]="translation.selected"></ui-checkbox>
                            </td>
                            <td>
                                <ui-flag
                                    size="tiny"
                                    [culture]="translation.culture"></ui-flag>
                                {{ translation.name | ellipsisMiddle: 20 }}
                            </td>
                            <td class="bfBlockTable__subText">
                                {{ (getUrl(translation) | async | ellipsisMiddle:70) }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </ng-container>
    </div>
</div>
