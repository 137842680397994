import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'bfMapProperty',
	standalone: true,
})
export class BFMapPropertyPipe implements PipeTransform {
	/**
	 * Basically a "Mapper" for the getPropertyByPath method.
	 * Will return sub properties for each item in the array by using that method.
	 * @param array Array with items to search
	 * @param path Path to the property that should be extracted
	 * @param defaultValue If nothing found, what should be returned for each item
	 */
	public static mapProperty(array: any[], path: string, defaultValue?: any) {
		// No array
		if (!array || !array.length) {
			return [];
		}

		// No path
		if (!path) {
			return array;
		}

		const data = array.map((item: any) => {
			return BFMapPropertyPipe.getPropertyByPath(
				item,
				path,
				defaultValue,
			);
		});

		return data;
	}

	/**
	 * Get a propert by a path. If obj = { owner: { name: 'human' }}.
	 * Passing path 'owner.name' will return 'human'.
	 * Should work with array indexes as well.
	 * @param obj Object to search
	 * @param path Path to the property that should be extracted
	 * @param defaultValue If nothing found, what should be returned.
	 */
	public static getPropertyByPath(
		obj: any,
		path: string,
		defaultValue?: any,
	): any {
		// No path
		if (path === undefined) {
			return undefined;
		} else if (path === '') {
			return obj;
		}
		const fullPath: string[] = path
			.replace(/\[/g, '.')
			.replace(/]/g, '')
			.split('.')
			.filter(Boolean);

		return fullPath.every(everyFunc) ? obj : defaultValue;

		function everyFunc(step: any): any {
			return !(step && obj && (obj = obj[step]) === undefined);
		}
	}

	/**
	 * Basically a "Mapper" for the getPropertyByPath method.
	 * Will return sub properties for each item in the array by using that method.
	 * @param array Array with items to search
	 * @param path Path to the property that should be extracted
	 * @param defaultValue If nothing found, what should be returned for each item
	 */
	public transform(array: any[], path: string, defaultValue?: any): any[] {
		return BFMapPropertyPipe.mapProperty(array, path, defaultValue);
	}
}
