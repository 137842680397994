<div class="translation">
    <div class="translation__top">
        <div class="header">
            <div class="header__section header__section--left">
                <div class="backButton" [routerLink]="backLink">
                    <i class="bf-icon bf-icon-arrow-left-a"></i>&nbsp;&nbsp; Back to
                    translation
                </div>
            </div>
            <div class="header__section header__section--center">
                <div class="currentTranslation" *ngIf="textService.currentTranslation">
                    <span>Translating&nbsp;</span>
                    <ui-flag
                        size="tiny"
                        [culture]="textService.currentTranslation.culture"></ui-flag
                    >&nbsp; {{ textService.currentTranslation.name | ellipsisMiddle: 20 }}
                </div>
            </div>
            <div class="header__section header__section--right">
                <ui-button
                    *ngIf="!(landingPageService.dirty || isSaving)"
                    id="interaction-complete-translation-landingpage-btn"
                    type="primary"
                    svgIcon="checkbox-checkmark-default"
                    (click)="complete()"
                    text="Complete translation">
                </ui-button>
                <ui-button
                    *ngIf="landingPageService.dirty || isSaving"
                    id="interaction-save-translation-landingpage-btn"
                    type="primary"
                    [loading]="isSaving"
                    svgIcon="files"
                    (click)="save()"
                    text="Save">
                </ui-button>
            </div>
        </div>
    </div>
    <div class="translation__page">
        <router-outlet></router-outlet>
    </div>
</div>
