import { DesignService } from 'design/design.service';
import { MenuItemModel } from 'shared/models/menuItem.model';
import { TextService } from 'shared/services/text.service';

export class PickSizeComponent {
	public sizes = new Array<MenuItemModel>();

	constructor(
		public designService: DesignService,
		public textService: TextService,
	) {
		this.buildSizeMenu();
	}

	public buildSizeMenu(): void {
		this.sizes = [];

		for (let size of this.designService.artboardSizes) {
			this.sizes.push(
				new MenuItemModel(size.name, () => {
					this.designService.setSize(size);

					// make sure the iframe is reloaded so startup scripts run again
					this.textService.setCurrentTranslation(
						this.textService.currentTranslation,
					);
				}),
			);

			if (size.name === '1920px') {
				this.sizes.push(new MenuItemModel('', null, true));
			}
		}
	}
}
