define(function () {
	'use strict';

	return function (scribe) {
		function CommandPatch(commandName) {
			this.commandName = commandName;
		}

		var rootDoc = scribe.el.ownerDocument;
		var nodeHelpers = scribe.node;

		// find the parent document or document fragment
		if (
			rootDoc.compareDocumentPosition(scribe.el) &
			Node.DOCUMENT_POSITION_DISCONNECTED
		) {
			var currentElement = scribe.el.parentNode;
			while (currentElement && nodeHelpers.isFragment(currentElement)) {
				currentElement = currentElement.parentNode;
			}

			// if we found a document fragment and it has a getSelection method, set it to the root doc
			if (currentElement && currentElement.getSelection) {
				rootDoc = currentElement;
			}
		}

		// HERE
		CommandPatch.prototype.execute = function (value) {
			scribe.transactionManager.run(
				function () {
					rootDoc.execCommand(this.commandName, false, value || null);
				}.bind(this),
			);
		};

		CommandPatch.prototype.queryState = function () {
			return rootDoc.queryCommandState(this.commandName);
		};

		CommandPatch.prototype.queryEnabled = function () {
			return rootDoc.queryCommandEnabled(this.commandName);
		};

		return CommandPatch;
	};
});
