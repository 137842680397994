<!--LANGUAGE-->
<td
    *ngIf="!translation?.isEditing && translation?.translation?.name && translation?.translation?.culture">
    <div class="row-language">
        <ui-flag size="tiny" [culture]="translation.translation.culture" />
        <span [uiTooltip]="translation.translation.name" [uiTooltipHideArrow]="true">
            {{ translation.translation.name | ellipsisMiddle: 20 }}
        </span>
    </div>
</td>

<!--TRANSLATOR (for new translations)-->
<td *ngIf="showSendForTranslation && translation?.isNew && !translation?.isEditing">
    <!--note that this is a direct selector for the transltor as opposed to the selector in "edit mode".
        that selector binds to a temporary value so it can be canceled-->
    <ui-select
        *ngIf="showSendForTranslation"
        [width]="'150px'"
        [value]="selectedTranslator"
        [(selected)]="selectedTranslator"
        placeholder="select translator"
        (selectedChange)="selectedChanged($event)">
        <ui-option
            *ngFor="let translationOption of translation.translatorsAndNone.selectItems"
            [value]="translationOption">
            {{ translationOption.label }}
        </ui-option>
    </ui-select>
</td>

<!--STATUS-->
<td *ngIf="!translation?.isNew && !translation?.isEditing">
    <div class="translationRow__status">
        <span *ngIf="isOriginal">Original</span>
        <ng-container
            *ngIf="translation?.translation?.translationState === 3 ||
                !translation?.translation?.deadline ||
                translation?.translation?.deadline > today; else deadlinePassed"
            [ngSwitch]="translation.translation.translationState">
            <ui-svg-icon
                *ngSwitchCase="1"
                icon="mail"
                class="translationRow__icon"
                [uiTooltip]="'Translation has been sent out for translation'"
                [uiTooltipHideArrow]="true" />
            <!-- the next icon should be changed to an open envelope, talk to Polina -->
            <ui-svg-icon
                *ngSwitchCase="2"
                icon="sync-l"
                class="translationRow__icon"
                [uiTooltip]="'Translation is in progress'"
                [uiTooltipHideArrow]="true" />
            <ui-svg-icon
                *ngSwitchCase="3"
                icon="mail"
                class="translationRow__icon translationRow__icon--success"
                [uiTooltip]="'Translation is completed'"
                [uiTooltipHideArrow]="true" />
        </ng-container>
        <ng-template #deadlinePassed>
            <ui-svg-icon
                icon="time"
                class="translationRow__icon"
                [uiTooltip]="'Deadline passed'"
                [uiTooltipHideArrow]="true" />
        </ng-template>
    </div>
</td>

<!--PUBLISH URL-->
<td *ngIf="!translation?.isEditing">
    <!--if published, make link to publication clickable-->
    <a
        href="{{translation.cName}}{{translation.translation.publishSlug}}/index.html"
        *ngIf="translation.translation.publishState === 2"
        target="_blank"
        [uiTooltip]="translation.cName + translation.translation.publishSlug + '/index.html'"
        [uiTooltipMaxWidth]="'1000px'">
        {{ translation.cName + translation.translation.publishSlug + '/index.html' |
        ellipsisMiddle: 50 }}
    </a>

    <!--if not published, just show URL as text-->
    <span
        *ngIf="translation.translation.publishState != 2"
        [uiTooltip]="translation.cName + translation.translation.publishSlug + '/index.html'"
        [uiTooltipMaxWidth]="'1000px'">
        {{ translation.cName + translation.translation.publishSlug + '/index.html' |
        ellipsisMiddle: 50 }}
    </span>
</td>

<td class="translationRow__action" *ngIf="!translation?.isEditing">
    <div class="translationRow__actionLeft">
        <span
            (click)="openEdit($event)"
            [uiTooltip]="'Edit'"
            [uiTooltipHideArrow]="true"
            class="translationRow__actionIconContainer">
            <ui-svg-icon icon="edit" class="translationRow__actionIcon" />
        </span>
        <span
            *ngIf="!translation?.isNew && !isOriginal"
            [uiTooltip]="'Actions'"
            [uiTooltipHideArrow]="true"
            [uiDropdownTarget]="ManageVersionsMenu"
            class="translationRow__actionIconContainer">
            <ui-svg-icon icon="kebab" class="translationRow__actionIcon" />
        </span>
    </div>
</td>

<!--EDIT MODE-->
<td class="translationRow__edit" *ngIf="translation?.isEditing" colspan="4">
    <div class="translationRow__editContent">
        <!--LOADER-->
        <ui-loader *ngIf="loading" />

        <!--FLAG AND NAME-->
        <div class="form-language">
            <ui-flag size="tiny" [culture]="translation.translation.culture" />
            <span [uiTooltip]="translation.translation.name" [uiTooltipHideArrow]="true">
                {{ translation.translation.name | ellipsisMiddle: 20 }}
            </span>
        </div>

        <!-- ACTIONS -->
        <div
            class="translationRow__editActions"
            *ngIf="!translation?.isNew && !isOriginal">
            <span
                [uiTooltip]="'Actions'"
                [uiTooltipHideArrow]="true"
                [uiDropdownTarget]="ManageVersionsMenu"
                class="translationRow__actionIconContainer">
                <ui-svg-icon icon="kebab" class="translationRow__actionIcon" />
            </span>
        </div>

        <div class="translationRow__editForm">
            <!--TRANSLATION NAME-->
            <div class="translationRow__editFormLeft">
                <label class="translationRow__label">Translation name</label>
                <ui-input
                    [(ngModel)]="name"
                    (ngModelChange)="checkDirty()"
                    placeholder="Example name"
                    [maxlength]="250" />
            </div>

            <!--FALLBACK URL-->
            <div class="translationRow__editFormRight">
                <label class="translationRow__label">
                    Fallback URL&nbsp;
                    <ui-svg-icon
                        class="hint"
                        icon="question-mark"
                        [uiTooltip]="'This URL will be used when you unpublish this version'"
                        [uiTooltipWidth]="180" />
                </label>
                <ui-input
                    [(ngModel)]="fallbackUrl"
                    (ngModelChange)="checkDirty()"
                    placeholder="http://example.com" />
            </div>
        </div>

        <!--PUBLIC URL - show only if not published-->
        <div
            class="translationRow__editPublishUrl"
            *ngIf="translation.translation.publishState != 2">
            <label class="translationRow__label">
                Public URL&nbsp;
                <ui-svg-icon
                    class="hint"
                    icon="question-mark"
                    [uiTooltip]="'This is the Live URL to be used for this version'"
                    [uiTooltipWidth]="180" />
            </label>
            <div class="translationRow__editPublishUrlUrl">
                <span class="translationRow__editPublishUrlUrl--notEditable"
                    >{{ translation.cName }}</span
                ><span
                    class="translationRow__editPublishUrlUrl--editable"
                    (bfInlineEditDone)="validate(); checkDirty();"
                    [(bfInlineEdit)]="publishSlug"
                    [ngStyle]="{ color: translation.publishSlugIsTaken ? '#e65050' : 'inherit' }"
                    [uiTooltip]="'Click to edit'"
                    [uiTooltipHideArrow]="true"></span
                ><span class="translationRow__editPublishUrlUrl--notEditable"
                    >/index.html</span
                >
                <span class="tinyLoader" *ngIf="translation.isLoading"></span>
            </div>
            <div
                class="translationRow__editPublishUrlError"
                *ngIf="translation.publishSlugIsTaken">
                This URL is already used by another landingpage or version, please change
                the url.
            </div>
        </div>

        <!--PUBLIC URL - show if published-->
        <div
            class="translationRow__editPublishUrl"
            *ngIf="translation.translation.publishState === 2">
            <label class="translationRow__label">
                Public URL&nbsp;
                <ui-svg-icon
                    class="hint"
                    icon="question-mark"
                    [uiTooltip]="'This is the Live URL to be used for this version'"
                    [uiTooltipWidth]="180" />
            </label>
            <div class="translationRow__editPublishUrlUrl">
                {{ translation.cName }}{{ translation.translation.publishSlug
                }}/index.html
            </div>
        </div>

        <p
            *ngIf="translation.translation.publishState === 2"
            class="translationRow__publishUrlNoticeText">
            Notice, if you want to edit the public URL you need to unpublish this version
            first.
        </p>

        <!--TRANSLATION-->
        <div *ngIf="!isOriginal">
            <hr />

            <div class="translationRow__translatorSettings">
                <div *ngIf="showSendForTranslation">
                    <label
                        class="translationRow__label"
                        *ngIf="translation?.isNew || (!isOriginal && translation.translation.translator == null)">
                        Send for translation&nbsp;
                        <ui-svg-icon
                            class="hint"
                            icon="question-mark"
                            [uiTooltip]="'Send the version for translation (optional)'"
                            [uiTooltipWidth]="130" />
                    </label>
                    <div class="translationRow__translatorForm">
                        <div
                            *ngIf="(translation.isNew || (!translator || translation.translation.translator != translator))"
                            class="translationRow__translatorFormLeft">
                            <ui-select
                                *ngIf="!showNewTranslatorView"
                                [width]="'217px'"
                                [value]="selectedTranslator"
                                [(selected)]="selectedTranslator"
                                placeholder="select translator"
                                (selectedChange)="selectedEdit($event)">
                                <ui-option
                                    *ngFor="let translationOption of translation.translatorsAndNone.selectItems"
                                    [value]="translationOption">
                                    {{ translationOption.label }}
                                </ui-option>
                            </ui-select>
                            <ui-select
                                *ngIf="showNewTranslatorView"
                                [width]="'217px'"
                                [value]="selectedTranslator"
                                [(selected)]="selectedTranslator"
                                placeholder="select translator"
                                (selectedChange)="selectedEdit()">
                                <ui-option
                                    *ngFor="let translationOption of translation.translators.selectItems"
                                    [value]="translationOption">
                                    {{ translationOption.label }}
                                </ui-option>
                            </ui-select>
                        </div>

                        <div
                            *ngIf="(translation?.isNew && translator) || (!translation?.isNew && translator && translation.translation.translator != translator)"
                            class="translationRow__translatorFormRight">
                            <a
                                class="translationRow__showMore"
                                (click)="toggleMoreOptions()">
                                {{showMoreOptions ? 'Less' : 'More'}} options
                            </a>
                        </div>
                    </div>
                </div>
                <div *ngIf="!showSendForTranslation">
                    Please make one or more texts on your Landing Page translatable to
                    enable the send for translation feature
                </div>
            </div>

            <!--DEADLINE-->
            <div
                *ngIf="(translation?.isNew && translator) || (!translation?.isNew && translator && translation.translation.translator != translator) || showNewTranslatorView"
                class="translationRow__deadlineOptions">
                <label class="translationRow__deadlineOptionsLabel">
                    <ui-checkbox
                        [selected]="!!deadlineSpan[0]"
                        (click)="toggleDeadline()" />
                    Deadline
                </label>

                <ng-container *ngIf="deadlineSpan[0]">
                    <bfDatePickerButton
                        class="translationRow__datePicker"
                        [(duration)]="deadlineSpan"
                        [options]="deadlineDatePickerOptions"></bfDatePickerButton>

                    <label class="translationRow__closeOnDeadline">
                        <ui-checkbox
                            [selected]="closeOnDeadline"
                            (click)="closeOnDeadline=!closeOnDeadline" />
                        Close on deadline
                    </label>
                </ng-container>
            </div>

            <!--MORE OPTIONS-->
            <div *ngIf="showMoreOptions" class="translationRow__moreOptions">
                <div class="translationRow__moreOptionsMessageToTranslator">
                    <label class="translationRow__label"> Message to translator </label>
                    <ui-textarea
                        [(ngModel)]="messageToTranslator"
                        placeholder="Type your message" />
                </div>
                <div>
                    <label class="translationRow__label"> Notify when complete </label>
                    <bfTagInput
                        [(tags)]="notifyOnDone"
                        [config]="{ placeholder: 'Add email address...' }"></bfTagInput>
                </div>
            </div>
        </div>

        <div
            class="translationRow__translatorOptions"
            *ngIf="!translation?.isNew && !isOriginal && translation.translation.translator != null && !showNewTranslatorView">
            <div class="translationRow__translator">
                <div class="translationRow__translatorIconContainer">
                    <ui-svg-icon
                        icon="mail"
                        [ngClass]="{ 'translationRow__icon--success': translation.translation.translationState === 3 }"
                        class="translationRow__icon" />
                </div>
                <div>
                    <div *ngIf="translation.translation.translationState !== 3">
                        Sent for translation to
                        {{translation.translation.translator.name}}
                        <span class="translationRow__translatorEmail">
                            ({{translation.translation.translator.email}})
                        </span>
                        <div
                            *ngIf="translation.translation.deadline"
                            class="translationRow__translatorDeadline">
                            <strong>Deadline</strong>&nbsp;{{
                            translation.translation.deadline | date:'yMMMdjms' }}
                        </div>
                    </div>
                    <div *ngIf="translation.translation.translationState === 3">
                        This translation is completed by
                        {{translation.translation.translator.name}}
                        <span class="translationRow__translatorEmail">
                            ({{translation.translation.translator.email}})
                        </span>
                    </div>

                    <!--if translation is not completed-->
                    <div class="translationRow__translatorActionRow">
                        <span
                            *ngIf="translation.translation.translationState !== 3; else newTranslation">
                            <a (click)="showConfirmCancelTranslation()"
                                >Cancel translation</a
                            >
                            <!--if translation is not closed by deadline-->
                            <ng-container
                                *ngIf="!translation.translation.isClosedByDeadline">
                                &nbsp;|&nbsp;
                                <a (click)="showConfirmSendTranslationRequest()"
                                    >Resend to translator</a
                                >
                            </ng-container>
                        </span>
                        <ng-template #newTranslation>
                            <a (click)="sendTranslationToNewTranslator()">
                                New translation
                            </a>
                        </ng-template>
                    </div>
                    <div
                        *ngIf="translation.translation.translationState === 2"
                        class="translationRow__translatorActionRow translationRow__translatorActionRow--translationLink">
                        <a
                            class="translationRow__gotoTranslationLink"
                            (click)="goToTranslation()">
                            Go to translation page<i class="bf-icon bf-icon-open"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <!--BUTTONS-->
        <hr />

        <div class="translationRow__editButtons">
            <!--CLOSE-->
            <ui-button
                id="interaction-cancel-translation-row-landingpage-btn"
                type="default"
                text="Cancel"
                (click)="closeEdit()" />

            <!--SAVE (existing)-->
            <ui-button
                *ngIf="!translation?.isNew"
                id="interaction-save-translation-row-landingpage-btn"
                type="primary"
                svgIcon="files"
                text="Save"
                [disabled]="!translation.isValid || translation.isLoading || !publishSlug || !isDirty"
                (click)="save()" />

            <!--CONFIRM (new)-->
            <ui-button
                *ngIf="translation?.isNew"
                id="interaction-confirm-translation-row-landingpage-btn"
                type="primary"
                text="Confirm"
                [disabled]="!translation.isValid || translation.isLoading || !publishSlug"
                (click)="confirm()" />
        </div>
    </div>
</td>

<ui-dropdown
    #ManageVersionsMenu
    [positions]="[{
        originX: 'center',
        originY: 'bottom',
        overlayX: 'center',
        overlayY: 'top'
    }]">
    <ng-container *ngFor="let manageVersionOption of manageVersionOptions">
        <ng-container *ngIf="manageVersionOption.label !== ''">
            <ui-dropdown-item
                id="interaction-size"
                (click)="handleDropdownSelect(manageVersionOption)">
                <span
                    class="ManageVersionsMenu__label"
                    [innerHTML]="manageVersionOption.label"></span>
            </ui-dropdown-item>
        </ng-container>
    </ng-container>
</ui-dropdown>
