import {
	Directive,
	ElementRef,
	Input,
	Renderer2,
	OnDestroy,
	OnInit,
	ComponentRef,
	AfterViewInit,
} from '@angular/core';
import { BFTooltipComponent } from './bfTooltip.component';
import { BFTooltipContainer } from '../../containers/bfTooltip.container';
import { BFMaterial } from '../../bfMaterial';

@Directive({
	selector: '[bfTooltip]',
	exportAs: 'bfTooltip',
	standalone: true,
})
export class BFTooltipDirective implements OnDestroy, OnInit {
	@Input('bfTooltip') public text: string;
	@Input('bfTooltipType') public type: string = 'hover';
	@Input('bfTooltipTheme') public theme: string = 'default';
	@Input('bfTooltipArrow') public arrow: string = 'bottom'; //NOT IN USE, REMOVE
	@Input('bfTooltipShowArrow') public showArrow: boolean = true;
	@Input('bfTooltipPlacement') public placement: string = 'top';
	@Input('bfTooltipDelay') public delay: number = 250;
	@Input('bfTooltipAnimation') public animation: string = '';
	@Input('bfTooltipMaxWidth') public maxWidth: string = '';
	@Input('bfTooltipMargin') public margin: string = '';
	@Input('bfTooltipAlign') public align: string = '';

	private touchStartListener: Function;
	private mouseOverListener: Function;
	private mouseOutListener: Function;
	private mouseDownListener: Function;
	private onBlurListener: Function;
	private tooltipComponentRef: ComponentRef<BFTooltipComponent>;
	private showing: boolean;

	constructor(
		private elementRef: ElementRef,
		private tooltipContainer: BFTooltipContainer,
		private Renderer2: Renderer2,
		private bannerFlowMaterial: BFMaterial,
	) {}

	private destroyComponent(): void {
		this.tooltipContainer.detach(this.tooltipComponentRef);

		if (this.mouseDownListener) {
			this.mouseDownListener();
		}
	}

	private createComponent() {
		if (this.type === 'hover') {
			this.mouseDownListener = this.Renderer2.listen(
				'document',
				'mousedown',
				() => {
					if (this.showing) {
						this.destroyComponent();
						this.showing = false;
					}
				},
			);
		} else if (this.type === 'click') {
			this.mouseDownListener = this.Renderer2.listen(
				'document',
				'mousedown',
				() => {
					if (this.showing) {
						this.destroyComponent();
						this.showing = false;
					}
				},
			);

			this.onBlurListener = this.Renderer2.listen(
				this.elementRef.nativeElement,
				'blur',
				() => {
					if (this.showing) {
						this.destroyComponent();
						this.showing = false;
					}
				},
			);
		}

		this.tooltipComponentRef =
			this.tooltipContainer.attach<BFTooltipComponent>(
				BFTooltipComponent,
				this.bannerFlowMaterial.rootViewContainerRef,
			);
		this.tooltipComponentRef.instance.initiate(
			this.elementRef,
			this.text,
			this.placement,
			this.delay,
			this.theme,
			this.animation,
			this.type,
			this.showArrow,
			this.maxWidth,
			this.margin,
			this.align,
		);
	}

	public deactivate(): void {
		this.destroyComponent();
	}

	public activate(): void {
		this.createComponent();
	}

	public ngOnInit() {
		if (this.type === 'hover') {
			this.touchStartListener = this.Renderer2.listen(
				this.elementRef.nativeElement,
				'touchstart',
				(event: MouseEvent) => {
					event.preventDefault();

					this.elementRef.nativeElement.click();
				},
			);
			this.mouseOverListener = this.Renderer2.listen(
				this.elementRef.nativeElement,
				'mouseenter',
				(event: MouseEvent) => {
					if (
						this.showing ||
						$(this.elementRef.nativeElement).hasClass(
							'bfTooltip--tooltipOpen',
						) ||
						$(this.elementRef.nativeElement)
							.children()
							.first()
							.hasClass('bfTooltip--tooltipOpen')
					) {
						return;
					}

					this.createComponent();
					this.showing = true;
				},
			);

			this.mouseOutListener = this.Renderer2.listen(
				this.elementRef.nativeElement,
				'mouseleave',
				(event: MouseEvent) => {
					this.destroyComponent();
					this.showing = false;
				},
			);
		}
		if (this.type === 'click') {
			this.touchStartListener = this.Renderer2.listen(
				this.elementRef.nativeElement,
				'touchstart',
				(event: MouseEvent) => {
					event.preventDefault();

					this.elementRef.nativeElement.click();
				},
			);

			let eventType = 'click';

			if (this.elementRef.nativeElement.tagName === 'INPUT') {
				eventType = 'focus';
			}

			this.mouseOverListener = this.Renderer2.listen(
				this.elementRef.nativeElement,
				eventType,
				(event: MouseEvent) => {
					if (
						this.showing ||
						$(this.elementRef.nativeElement).hasClass(
							'bfTooltip--tooltipOpen',
						) ||
						$(this.elementRef.nativeElement)
							.children()
							.first()
							.hasClass('bfTooltip--tooltipOpen')
					) {
						return;
					}

					this.createComponent();
					this.showing = true;
				},
			);
		} else if (this.type === 'static') {
			setTimeout(() => {
				this.createComponent();
			});
		}
	}

	public ngOnDestroy() {
		if (this.touchStartListener) {
			this.touchStartListener();
		}

		if (this.mouseOutListener) {
			this.mouseOutListener();
		}

		if (this.mouseOverListener) {
			this.mouseOverListener();
		}

		if (this.mouseDownListener) {
			this.mouseDownListener();
		}

		if (this.onBlurListener) {
			this.onBlurListener();
		}

		this.destroyComponent();
	}
}
