import { Component, Input, ViewChild, AfterViewInit } from '@angular/core';
import {
	BFInlineEditDirective,
	BFOrderByPipe,
} from '../../../../../../../libs/material/index';
import { CommonModule } from '@angular/common';
import { FileBrowserItem } from 'code/views/code/components/fileBrowser/fileBrowserItem/fileBrowserItem';
import { CodeService } from 'code/services/code.service';
import { DocumentService } from 'code/services/document.service';
import { LPBConfirmDialogService } from 'shared/services/lpb-confirm-dialog.service';
import { FileBrowserFileComponent } from '../fileBrowserFile/fileBrowserFile.component';
import { FolderModel } from 'shared/models/folder.model';

@Component({
	selector: 'fileBrowserFolder',
	styleUrls: ['fileBrowserFolder.component.scss'],
	templateUrl: 'fileBrowserFolder.component.html',
	inputs: ['item', 'depth', 'parentFolder'],
	outputs: ['fileSelect', 'nameChange'],
	standalone: true,
	imports: [CommonModule, FileBrowserFileComponent, BFOrderByPipe],
})
export class FileBrowserFolderComponent
	extends FileBrowserItem
	implements AfterViewInit
{
	@Input() public selectHandler: any;
	@Input() public collapsed = false;

	@ViewChild('bfInlineEditReference', { static: true })
	public inlineEditDirective: BFInlineEditDirective;

	constructor(
		private readonly _codeService: CodeService,
		private readonly _lpbDialogService: LPBConfirmDialogService,
		private readonly _documentService: DocumentService,
	) {
		super(_codeService, _lpbDialogService, _documentService);
	}

	ngAfterViewInit() {
		// this.config.MenuItemModel.unshift(new MenuItem('New folder', (item, event) => { this.addFolder() }))
		// this.config.MenuItemModel.unshift(new MenuItem('New file', (item, event) => { this.addFile() }))

		//Only do this if the item is not the rootFolder or index.html
		if (this.inlineEditDirective) {
			this.initFileItem(this.inlineEditDirective);
		}
	}

	public addFolder($event?: any) {
		// this.collapsed = false;
		// let newFolder = new FolderModel();
		// newFolder.name = "";
		// newFolder.state = State.New;
		// (this.item as FolderModel).folders.push(newFolder);
		// if ($event) {
		//     $event.stopPropagation();
		//     $event.preventDefault();
		// }
	}

	public addFile($event?: any) {
		this.collapsed = false;
		this.codeService.addFile(this.item as FolderModel, null);

		if ($event) {
			$event.stopPropagation();
			$event.preventDefault();
		}
	}

	public toggleCollapsed() {
		this.collapsed = !this.collapsed;
	}
}
