// libs
import {
	Component,
	OnInit,
	OnDestroy,
	ViewChild,
	ViewChildren,
	ElementRef,
	QueryList,
} from '@angular/core';
import { TranslationTextInputComponent } from 'design/views/design/components/translationSidebar/translationTextInput/translationTextInput.component';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { BFTooltipDirective } from '../../../../../../libs/material';
import { TranslationImageInputComponent } from 'design/views/design/components/translationSidebar/translationImageInput/translationImageInput.component';
import { EllipsisMiddlePipe } from 'shared/pipes/ellipsisMiddle.pipe';
import { LandingPageModel } from 'shared/models/landingPage.model';
import {
	FromArtboardShowEvent,
	UploadDialogService,
} from 'shared/components/uploadDialog/uploadDialog.service';
import { DesignService } from 'design/design.service';
import { TextService } from 'shared/services/text.service';
import { LandingPageService } from 'shared/services/landingPage.service';
import { CodeService } from 'code/services/code.service';
import { TextModel } from 'shared/models/text.model';
import { ResourceTranslationModel } from 'shared/models/resourceTranslation.model';
import { State } from 'shared/enums/state.enum';
import { FileModel } from 'shared/models/file.model';
import { TranslationModel } from 'shared/models/translation.model';
import { UIModule } from '@bannerflow/ui';
// import { DesignViewComponent } from '../../design.view.component';

@Component({
	styleUrls: ['translationSidebar.component.scss'],
	selector: 'translationSidebar',
	templateUrl: 'translationSidebar.component.html',
	standalone: true,
	imports: [
		NgIf,
		BFTooltipDirective,
		NgClass,
		NgFor,
		TranslationTextInputComponent,
		TranslationImageInputComponent,
		EllipsisMiddlePipe,
		UIModule,
	],
})
export class TranslationSidebarComponent implements OnInit, OnDestroy {
	public texts: TranslateTextModel[];
	public images: TranslateResourceModel[];
	public textsCollapsed = false;
	public imagesCollapsed = false;
	public landingPage: LandingPageModel;
	private onTranslationChange: any;
	private onFileNameChanged;
	private scrollMargin = 100;

	@ViewChild('translationSidebar') private translationSidebar: ElementRef;

	@ViewChildren('translationTextInput')
	private translationTextInputs: QueryList<TranslationTextInputComponent>;

	@ViewChildren('translationImageInput')
	private translationImageInputs: QueryList<TranslationImageInputComponent>;

	constructor(
		// private readonly designView: DesignViewComponent,
		private readonly uploadDialogService: UploadDialogService,
		private readonly designService: DesignService,
		public textService: TextService,
		private readonly landingPageService: LandingPageService,
		private readonly codeService: CodeService,
	) {}

	private setTexts(): void {
		this.texts = [];

		(this.landingPage.originalTranslation.texts || []).forEach(
			(text: TextModel) => {
				const currentIsOriginal =
					this.landingPage.originalTranslation ===
					this.textService.currentTranslation;
				let translation = currentIsOriginal
					? text
					: this.textService.getTextByKey(text.key);
				this.texts.push(new TranslateTextModel(text, translation));
			},
		);
	}

	private setImages(): void {
		this.images = [];

		if (!this.landingPage.originalTranslation.resourceTranslations) return;

		this.landingPage.originalTranslation.resourceTranslations.forEach(
			(originalResource: ResourceTranslationModel) => {
				if (originalResource.state !== State.Deleted) {
					let resource = new TranslateResourceModel(originalResource);

					//Not original translation -> Check for translated resource
					if (
						this.landingPage.originalTranslation !==
						this.textService.currentTranslation
					) {
						resource.resource =
							this.textService.getTranslatedResource(
								originalResource.originalResourceId,
							);
					}

					this.images.push(resource);
				}

				/*if(this.landingPage.originalTranslation === this.textService.currentTranslation){
                this.image.push(new TranslateTextModel(text, text))
            }else{
                this.texts.push(new TranslateTextModel(text, this.textService.getTextByKey(text.key) ));
            }*/
			},
		);
		/*this.images = [
            {
                name : 'Test image',
                url: 'http://lorempixel.com/400/200/animals'
            },
            {
                name : 'Test image2',
                url: 'http://lorempixel.com/100/300/animals'
            },
            {
                name : 'Test image3',
                url: 'http://lorempixel.com/300/300/animals'
            }
        ];*/
	}

	public toggleTextsCollapse() {
		this.textsCollapsed = !this.textsCollapsed;
	}

	public toggleImagesCollapse() {
		this.imagesCollapsed = !this.imagesCollapsed;
	}

	private scrollWithAnimationTo(
		element: HTMLElement,
		to: number,
		duration: number,
	) {
		const start = element.scrollTop;
		const change = to - start;
		const increment = 20;
		let currentTime = 0;

		function animateScroll() {
			currentTime += increment;
			const scrollTop = this.easeInOutQuad(
				currentTime,
				start,
				change,
				duration,
			);
			element.scrollTop = scrollTop;
			if (currentTime < duration) {
				setTimeout(animateScroll.bind(this), increment);
			}
		}
		animateScroll.call(this);
	}

	private easeInOutQuad(
		currentTime: number,
		start: number,
		change: number,
		duration: number,
	) {
		currentTime /= duration / 2;
		if (currentTime < 1)
			return (change / 2) * currentTime * currentTime + start;
		currentTime--;
		return (-change / 2) * (currentTime * (currentTime - 2) - 1) + start;
	}

	private getSideBarViewPortTopPlusHeight() {
		const translationSidebar = this.translationSidebar
			.nativeElement as HTMLDivElement;
		const translationSidebarClientRect =
			translationSidebar.getBoundingClientRect();
		const siderBarViewPortTop = translationSidebarClientRect.top;

		return siderBarViewPortTop + translationSidebarClientRect.height;
	}

	private getDiffViewPortTop(
		viewPortTop: number,
		sideBarViewPortTopPlusHeight: number,
		inputViewPortTop: number,
		inputHeight: number,
	) {
		let diff: number;
		if (viewPortTop < this.scrollMargin) {
			diff = this.scrollMargin;
		} else if (
			viewPortTop + inputHeight >
			sideBarViewPortTopPlusHeight - this.scrollMargin
		) {
			diff = sideBarViewPortTopPlusHeight - this.scrollMargin;
		} else {
			diff = viewPortTop;
		}
		return inputViewPortTop - diff;
	}

	ngOnInit() {
		this.designService.selectedTextChange.subscribe((text?: TextModel) => {
			if (text && text.from === 'canvas') {
				const translationSidebar = this.translationSidebar
					.nativeElement as HTMLDivElement;
				const sideBarViewPortTopPlusHeight =
					this.getSideBarViewPortTopPlusHeight();
				const translationTextInput = this.translationTextInputs.find(
					(t) => t.translation.text === text,
				);

				if (translationTextInput) {
					const translationTextInputViewPortTop =
						translationTextInput.getBoundingClientRect().top;
					const diffViewPortTop = this.getDiffViewPortTop(
						text.viewPortTop,
						sideBarViewPortTopPlusHeight,
						translationTextInput.getBoundingClientRect().top,
						translationTextInput.height,
					);
					this.scrollWithAnimationTo(
						translationSidebar,
						translationSidebar.scrollTop + diffViewPortTop,
						200,
					);
				} else {
					throw new Error('Could not find translationTextInput.');
				}
			}
		});
		this.uploadDialogService.fromArtboardShow.subscribe(
			(fromArtboardShowEvent: FromArtboardShowEvent) => {
				const translationSidebar = this.translationSidebar
					.nativeElement as HTMLDivElement;
				const sideBarViewPortTopPlusHeight =
					this.getSideBarViewPortTopPlusHeight();
				const translationImageInput = this.translationImageInputs.find(
					(t) =>
						t.translation.originalResource.originalResourceId ===
						fromArtboardShowEvent.id,
				);

				if (translationImageInput) {
					const viewPortTop =
						fromArtboardShowEvent.element.getBoundingClientRect()
							.top + 60;
					const diffViewPortTop = this.getDiffViewPortTop(
						viewPortTop,
						sideBarViewPortTopPlusHeight,
						translationImageInput.getBoundingClientRect().top,
						translationImageInput.height,
					);

					this.scrollWithAnimationTo(
						translationSidebar,
						translationSidebar.scrollTop + diffViewPortTop,
						200,
					);
				} else {
					throw new Error('Could not find translationImageInput.');
				}
			},
		);
		//this.designView.onInitialized.subscribe(this.initTexts.bind(this));
		this.initTexts();
		// listen to file name changes so the name resource translation's name
		// reflects what the user has set in the code view
		this.onFileNameChanged = this.codeService.onFileNameChanged.subscribe(
			(file: FileModel) => {
				const rts =
					this.landingPage.originalTranslation.resourceTranslations;
				if (!rts) return;

				rts.forEach((originalResource: ResourceTranslationModel) => {
					if (originalResource.originalResourceId === file.id)
						originalResource.name = file.name;
				});
			},
		);
	}

	initTexts() {
		this.landingPageService.get().then((landingPage: LandingPageModel) => {
			this.landingPage = landingPage;
			this.setTexts();
			this.setImages();

			this.onTranslationChange =
				this.textService.currentTranslationChange.subscribe(
					(translationModel: TranslationModel) => {
						this.setTexts();
						this.setImages();
					},
				);
		});
	}

	ngOnDestroy() {
		if (this.onTranslationChange) this.onTranslationChange.unsubscribe();
	}
}

export class TranslateTextModel {
	constructor(
		public originalText: TextModel,
		public text: TextModel,
	) {}
}
export class TranslateResourceModel {
	constructor(
		public originalResource?: ResourceTranslationModel,
		public resource?: ResourceTranslationModel,
	) {}
}
