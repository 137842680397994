<div
    class="codeView"
    [ngClass]="{ 'codeView--sidebar' : appService.sideBarView === 1 || appService.sideBarView === 2 }">
    <div *ngIf="landingPage">
        <div class="codeView__sidebar" *ngIf="appService.sideBarView === 2">
            <fileBrowser
                [rootFolder]="landingPage.rootFolder"
                [indexFile]="landingPage.indexFile"></fileBrowser>
        </div>
        <div class="codeView__editor">
            <div class="codeView__editorFileTabs">
                <fileTabs></fileTabs>
            </div>
            <div class="codeView__editorCodeEditor" *ngIf="activeDocument">
                <codeEditor
                    #codeEditor
                    *ngIf="activeDocument.file.type === fileTypeEnum.Text"
                    [document]="activeDocument"></codeEditor>
                <div
                    class="codeView__editorImagePreview"
                    *ngIf="activeDocument.file.type === fileTypeEnum.Image">
                    <div
                        class="codeView__imagePreviewInner"
                        [ngStyle]="{ backgroundImage: 'url(' + selectedFileUrl + ')'}"></div>
                </div>
                <div
                    class="codeView__editorNoPreview"
                    *ngIf="activeDocument.file.type === fileTypeEnum.Unknown">
                    <div class="codeView__editorNoPreviewInner">
                        <i
                            class="bf-icon bf-icon-5x bf-icon-type-image"
                            style="padding-bottom: 1.5rem;"></i
                        ><br />
                        <span>NO PREVIEW AVAILABLE</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
