import { TranslationState } from 'shared/enums/translationState.enum';

export class ExternalTranslationModel {
	public landingPageId: string;
	public accountSlug: string;
	public brandId: string;
	public landingPageName: string;
	public translationId: string;
	public translationName: string;
	public cultureCode: string;
	public translationState: TranslationState;
	public closeOnDeadline: boolean;
	public deadline: Date;
	public messageToTranslator: string;
	public showMessage: boolean;

	public deserialize(data: any): ExternalTranslationModel {
		this.landingPageId = data.landingPageId;
		this.accountSlug = data.accountSlug;
		this.brandId = data.brandId;
		this.landingPageName = data.landingPageName;
		this.translationId = data.translationId;
		this.translationName = data.translationName;
		this.cultureCode = data.cultureCode;
		this.translationState = data.translationState;
		this.closeOnDeadline = data.closeOnDeadline;
		this.deadline = data.deadline ? new Date(data.deadline) : null;
		this.messageToTranslator = data.messageToTranslator;

		return this;
	}
}
