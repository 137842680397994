// libs
import {
	Component,
	OnInit,
	OnDestroy,
	Input,
	ViewChild,
	ElementRef,
	Renderer2,
	NgZone,
	ComponentRef,
} from '@angular/core';
import { BFDialogResponse } from '../../../../../../../libs/material/index';
import { TranslateResourceModel } from 'design/views/design/components/translationSidebar/translationSidebar.component';
import { NgClass, NgStyle } from '@angular/common';
import { UserService } from 'shared/services/user.service';
import { TextService } from 'shared/services/text.service';
import { UploadDialogService } from 'shared/components/uploadDialog/uploadDialog.service';
import { ResourceTranslationModel } from 'shared/models/resourceTranslation.model';
import { FileModel } from 'shared/models/file.model';
import { environment } from './../../../../../../../environments/environment';

@Component({
	styleUrls: ['translationImageInput.component.scss'],
	selector: 'translationImageInput',
	templateUrl: 'translationImageInput.component.html',
	standalone: true,
	imports: [NgClass, NgStyle],
})
export class TranslationImageInputComponent implements OnInit, OnDestroy {
	@Input() public translation: TranslateResourceModel;
	@Input('index') public index: number;
	@ViewChild('preview', { static: true })
	private previewElementRef: ElementRef;
	public active: boolean = true;
	public url: string;
	private uploadUrl: string;
	private isOriginal: boolean;
	private onResourceChange;

	constructor(
		private userService: UserService,
		private textService: TextService,
		private uploadDialogService: UploadDialogService,
	) {
		userService.get().then((user) => {
			this.uploadUrl = `${environment.API_URL}/api/editor/${user.accountSlug}/${user.brandId}/uploadfile`;
		});
	}

	ngOnInit() {
		// Check if this is the original image
		this.isOriginal = !this.translation.resource;

		this.refreshUrl();

		// Image changed externally
		this.onResourceChange = this.textService.resourceChange.subscribe(
			(resource: ResourceTranslationModel) => {
				if (
					resource.originalResourceId ===
					this.translation.originalResource.originalResourceId
				) {
					this.translation.resource = resource;
					this.refreshUrl();
				}
			},
		);
	}

	ngOnDestroy() {
		if (this.onResourceChange) this.onResourceChange.unsubscribe();
	}

	uploadFiles() {
		this.uploadDialogService
			.show(false, 'image/*', 'Replace image in this version', 'sidebar')
			.then((response: BFDialogResponse<FileModel[]>) => {
				if (!response.cancel) {
					this.translation.resource =
						this.textService.addFileToCurrentTranslation(
							response.data[0],
							this.translation.originalResource
								.originalResourceId ||
								this.translation.originalResource.resourceId,
						);
				}
			});
	}

	public get height() {
		return (
			this.previewElementRef.nativeElement as HTMLIFrameElement
		).getBoundingClientRect().height;
	}

	public getBoundingClientRect() {
		return (
			this.previewElementRef.nativeElement as HTMLIFrameElement
		).getBoundingClientRect();
	}

	private refreshUrl() {
		this.url = this.translation.resource
			? this.translation.resource.url
			: this.translation.originalResource.url;
	}
}
