// libs
import {
	Component,
	Input,
	Output,
	EventEmitter,
	ViewChild,
} from '@angular/core';
import {
	BFDialogResponse,
	BFTooltipContainer,
	BFTooltipDirective,
} from '../../../../../../libs/material/index';
import { FileBrowserFileComponent } from 'code/views/code/components/fileBrowser/fileBrowserFile/fileBrowserFile.component';
import { FileBrowserFolderComponent } from 'code/views/code/components/fileBrowser/fileBrowserFolder/fileBrowserFolder.component';
import { FolderModel } from 'shared/models/folder.model';
import { FileModel } from 'shared/models/file.model';
import { LandingPageModel } from 'shared/models/landingPage.model';
import { CodeService } from 'code/services/code.service';
import { UploadDialogService } from 'shared/components/uploadDialog/uploadDialog.service';
import { LandingPageService } from 'shared/services/landingPage.service';
import { FileService } from 'code/services';
import { take } from 'rxjs';

@Component({
	styleUrls: ['fileBrowser.component.scss'],
	selector: 'fileBrowser',
	templateUrl: 'fileBrowser.component.html',
	standalone: true,
	imports: [
		BFTooltipDirective,
		FileBrowserFileComponent,
		FileBrowserFolderComponent,
	],
})
export class FileBrowserComponent {
	@Input() public rootFolder: FolderModel;
	@Input() public indexFile: FileModel;
	@Output() public fileSelect: EventEmitter<FileModel> =
		new EventEmitter<FileModel>();
	@ViewChild('rootFolderComponent', { static: true })
	private rootFolderComponent: FileBrowserFolderComponent;
	public landingPage: LandingPageModel;

	constructor(
		private codeService: CodeService,
		private uploadDialogService: UploadDialogService,
		private landingPageService: LandingPageService,
		private fileService: FileService,
		private tooltipContainer: BFTooltipContainer,
	) {
		this.landingPageService.get().then((landingPage: LandingPageModel) => {
			this.landingPage = landingPage;
		});
	}

	public downloadZip(): void {
		if (!this.landingPage) {
			console.error('Landing page data not available');
			return;
		}

		this.fileService
			.downloadZip(
				this.landingPage.id,
				this.landingPage.accountSlug,
				this.landingPage.brandId,
			)
			.pipe(take(1))
			.subscribe(
				(response: Blob) => {
					// Trigger download
					const blob = response;
					const a = document.createElement('a');
					a.href = URL.createObjectURL(blob);
					a.download = 'files.zip'; // Set the default download name
					a.click();
				},
				(error) => {
					console.error('Download failed', error);
				},
			);
	}

	public uploadFiles(): void {
		//Hide all tooltips
		this.tooltipContainer.clear();

		this.uploadDialogService
			.show()
			.then((response: BFDialogResponse<FileModel[]>) => {
				if (!response.cancel) {
					this.codeService.addFiles(this.rootFolder, response.data);
				}
			});
	}

	public addFile() {
		this.rootFolderComponent.addFile();
	}

	public addFolder() {
		this.rootFolderComponent.addFolder();
	}
}
