import { LocalizationModel } from 'shared/models/localization.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserService } from 'shared/services/user.service';
import { ApiService } from 'shared/services/api.service';
import { TranslatorModel } from 'shared/models/translator.model';

@Injectable({ providedIn: 'root' })
export class LocalizationService {
	private accountSlug: string;
	private brandId: string;
	private loadPromise: Promise<LocalizationModel[]>;

	constructor(
		private http: HttpClient,
		private userService: UserService,
		private apiService: ApiService,
	) {}

	public get(): Promise<LocalizationModel[]> {
		if (!this.loadPromise) {
			this.loadPromise = new Promise<LocalizationModel[]>((resolve) => {
				this.apiService
					.get<
						LocalizationModel[]
					>('translation', 'localizations/all')
					.then((response) => {
						let localizations: LocalizationModel[] = [];

						response.forEach((l) => {
							localizations.push(
								new LocalizationModel().deserialize(l),
							);
						});

						localizations.sort(
							(a: LocalizationModel, b: LocalizationModel) => {
								return a.name > b.name ? 1 : -1;
							},
						);
						resolve(localizations);
					});
			});
		}

		return this.loadPromise;
	}
}
